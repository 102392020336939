.#{$prefix}-m-header {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;

  &--minimal {
    padding: rem(33) 0 rem(24);
    background-color: $color-white;
    &__logo {
      display: block;
      width: rem(199);
      height: rem(61);
  
      img {
        width: rem(199);
        height: rem(61);
        object-fit: contain;
      }
    }
    &.centered {
      a {
        text-align: center;
      }
    }
  }

  &--push {
    width: 100%;
    padding-bottom: rem(74);

    &--minimal {
      padding-bottom: rem(118);
    }
  }

  @include media('>=desktop') {

    &--minimal {
      &.centered {
        a {
          text-align: left;
        }
      }
    }
    &--push {
      padding-bottom: rem(189);
    }
  }
}