/*
  getProportional([size_in_asset], [page-size-to-apply], [min-size], [optional: asset-max-width])
  Description:
    Returns a proportional size for the given [page-size-to-apply] based on the provided size and the max asset width

  $assetMaxWidth:
    Defined in _variables.scss file
    Contains the max width of the design

  Usage:
    font-size: getProportional(48, 768, 30);
*/
/*
  getProportional_vw([size_in_asset], [optional: asset-max-width])
  Description:
    Returns a proportional size based on the provided size and the max asset width in vw units

  $assetMaxWidth:
    Defined in _variables.scss file
    Contains the max width of the design
  Usage:
      font-size: getProportional_vw(48);
*/
/*!
   * Bootstrap  v5.2.3 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --stemline-arcblue: #0d6efd;
  --stemline-arcindigo: #6610f2;
  --stemline-arcpurple: #6f42c1;
  --stemline-arcpink: #d63384;
  --stemline-arcred: #dc3545;
  --stemline-arcorange: #fd7e14;
  --stemline-arcyellow: #ffc107;
  --stemline-arcgreen: #198754;
  --stemline-arcteal: #20c997;
  --stemline-arccyan: #0dcaf0;
  --stemline-arcblack: #000;
  --stemline-arcwhite: #fff;
  --stemline-arcgray: #6c757d;
  --stemline-arcgray-dark: #343a40;
  --stemline-arcgray-100: #f8f9fa;
  --stemline-arcgray-200: #e9ecef;
  --stemline-arcgray-300: #dee2e6;
  --stemline-arcgray-400: #ced4da;
  --stemline-arcgray-500: #adb5bd;
  --stemline-arcgray-600: #6c757d;
  --stemline-arcgray-700: #495057;
  --stemline-arcgray-800: #343a40;
  --stemline-arcgray-900: #212529;
  --stemline-arcprimary: #0d6efd;
  --stemline-arcsecondary: #6c757d;
  --stemline-arcsuccess: #198754;
  --stemline-arcinfo: #0dcaf0;
  --stemline-arcwarning: #ffc107;
  --stemline-arcdanger: #dc3545;
  --stemline-arclight: #f8f9fa;
  --stemline-arcdark: #212529;
  --stemline-arcprimary-rgb: 13, 110, 253;
  --stemline-arcsecondary-rgb: 108, 117, 125;
  --stemline-arcsuccess-rgb: 25, 135, 84;
  --stemline-arcinfo-rgb: 13, 202, 240;
  --stemline-arcwarning-rgb: 255, 193, 7;
  --stemline-arcdanger-rgb: 220, 53, 69;
  --stemline-arclight-rgb: 248, 249, 250;
  --stemline-arcdark-rgb: 33, 37, 41;
  --stemline-arcwhite-rgb: 255, 255, 255;
  --stemline-arcblack-rgb: 0, 0, 0;
  --stemline-arcbody-color-rgb: 33, 37, 41;
  --stemline-arcbody-bg-rgb: 255, 255, 255;
  --stemline-arcfont-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --stemline-arcfont-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --stemline-arcgradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --stemline-arcbody-font-family: var(--stemline-arcfont-sans-serif);
  --stemline-arcbody-font-size: 1rem;
  --stemline-arcbody-font-weight: 400;
  --stemline-arcbody-line-height: 1.5;
  --stemline-arcbody-color: #212529;
  --stemline-arcbody-bg: #fff;
  --stemline-arcborder-width: 1px;
  --stemline-arcborder-style: solid;
  --stemline-arcborder-color: #dee2e6;
  --stemline-arcborder-color-translucent: rgba(0, 0, 0, 0.175);
  --stemline-arcborder-radius: 0.375rem;
  --stemline-arcborder-radius-sm: 0.25rem;
  --stemline-arcborder-radius-lg: 0.5rem;
  --stemline-arcborder-radius-xl: 1rem;
  --stemline-arcborder-radius-2xl: 2rem;
  --stemline-arcborder-radius-pill: 50rem;
  --stemline-arclink-color: #0d6efd;
  --stemline-arclink-hover-color: #0a58ca;
  --stemline-arccode-color: #d63384;
  --stemline-archighlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--stemline-arcbody-font-family);
  font-size: var(--stemline-arcbody-font-size);
  font-weight: var(--stemline-arcbody-font-weight);
  line-height: var(--stemline-arcbody-line-height);
  color: var(--stemline-arcbody-color);
  text-align: var(--stemline-arcbody-text-align);
  background-color: var(--stemline-arcbody-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--stemline-archighlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--stemline-arclink-color);
  text-decoration: underline;
}

a:hover {
  color: var(--stemline-arclink-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--stemline-arcfont-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--stemline-arccode-color);
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--stemline-arcbody-bg);
  background-color: var(--stemline-arcbody-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--stemline-arcborder-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --stemline-arcgutter-x: 1.5rem;
  --stemline-arcgutter-y: 0;
  width: 100%;
  padding-right: calc(var(--stemline-arcgutter-x) * .5);
  padding-left: calc(var(--stemline-arcgutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --stemline-arcgutter-x: 1.5rem;
  --stemline-arcgutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--stemline-arcgutter-y));
  margin-right: calc(-.5 * var(--stemline-arcgutter-x));
  margin-left: calc(-.5 * var(--stemline-arcgutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--stemline-arcgutter-x) * .5);
  padding-left: calc(var(--stemline-arcgutter-x) * .5);
  margin-top: var(--stemline-arcgutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --stemline-arcgutter-x: 0;
}

.g-0,
.gy-0 {
  --stemline-arcgutter-y: 0;
}

.g-1,
.gx-1 {
  --stemline-arcgutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --stemline-arcgutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --stemline-arcgutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --stemline-arcgutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --stemline-arcgutter-x: 1rem;
}

.g-3,
.gy-3 {
  --stemline-arcgutter-y: 1rem;
}

.g-4,
.gx-4 {
  --stemline-arcgutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --stemline-arcgutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --stemline-arcgutter-x: 3rem;
}

.g-5,
.gy-5 {
  --stemline-arcgutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --stemline-arcgutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --stemline-arcgutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --stemline-arcgutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --stemline-arcgutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --stemline-arcgutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --stemline-arcgutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --stemline-arcgutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --stemline-arcgutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --stemline-arcgutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --stemline-arcgutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --stemline-arcgutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --stemline-arcgutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --stemline-arcgutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --stemline-arcgutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --stemline-arcgutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --stemline-arcgutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --stemline-arcgutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --stemline-arcgutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --stemline-arcgutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --stemline-arcgutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --stemline-arcgutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --stemline-arcgutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --stemline-arcgutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --stemline-arcgutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --stemline-arcgutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --stemline-arcgutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --stemline-arcgutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --stemline-arcgutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --stemline-arcgutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --stemline-arcgutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --stemline-arcgutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --stemline-arcgutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --stemline-arcgutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --stemline-arcgutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --stemline-arcgutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --stemline-arcgutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --stemline-arcgutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --stemline-arcgutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --stemline-arcgutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --stemline-arcgutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --stemline-arcgutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --stemline-arcgutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --stemline-arcgutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --stemline-arcgutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --stemline-arcgutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --stemline-arcgutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --stemline-arcgutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --stemline-arcgutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --stemline-arcgutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --stemline-arcgutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --stemline-arcgutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --stemline-arcgutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --stemline-arcgutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --stemline-arcgutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --stemline-arcgutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --stemline-arcgutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --stemline-arcgutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --stemline-arcgutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --stemline-arcgutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --stemline-arcgutter-y: 3rem;
  }
}

.table {
  --stemline-arctable-color: var(--stemline-arcbody-color);
  --stemline-arctable-bg: transparent;
  --stemline-arctable-border-color: var(--stemline-arcborder-color);
  --stemline-arctable-accent-bg: transparent;
  --stemline-arctable-striped-color: var(--stemline-arcbody-color);
  --stemline-arctable-striped-bg: rgba(0, 0, 0, 0.05);
  --stemline-arctable-active-color: var(--stemline-arcbody-color);
  --stemline-arctable-active-bg: rgba(0, 0, 0, 0.1);
  --stemline-arctable-hover-color: var(--stemline-arcbody-color);
  --stemline-arctable-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--stemline-arctable-color);
  vertical-align: top;
  border-color: var(--stemline-arctable-border-color);
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--stemline-arctable-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--stemline-arctable-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --stemline-arctable-accent-bg: var(--stemline-arctable-striped-bg);
  color: var(--stemline-arctable-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --stemline-arctable-accent-bg: var(--stemline-arctable-striped-bg);
  color: var(--stemline-arctable-striped-color);
}

.table-active {
  --stemline-arctable-accent-bg: var(--stemline-arctable-active-bg);
  color: var(--stemline-arctable-active-color);
}

.table-hover > tbody > tr:hover > * {
  --stemline-arctable-accent-bg: var(--stemline-arctable-hover-bg);
  color: var(--stemline-arctable-hover-color);
}

.table-primary {
  --stemline-arctable-color: #000;
  --stemline-arctable-bg: #cfe2ff;
  --stemline-arctable-border-color: #bacbe6;
  --stemline-arctable-striped-bg: #c5d7f2;
  --stemline-arctable-striped-color: #000;
  --stemline-arctable-active-bg: #bacbe6;
  --stemline-arctable-active-color: #000;
  --stemline-arctable-hover-bg: #bfd1ec;
  --stemline-arctable-hover-color: #000;
  color: var(--stemline-arctable-color);
  border-color: var(--stemline-arctable-border-color);
}

.table-secondary {
  --stemline-arctable-color: #000;
  --stemline-arctable-bg: #e2e3e5;
  --stemline-arctable-border-color: #cbccce;
  --stemline-arctable-striped-bg: #d7d8da;
  --stemline-arctable-striped-color: #000;
  --stemline-arctable-active-bg: #cbccce;
  --stemline-arctable-active-color: #000;
  --stemline-arctable-hover-bg: #d1d2d4;
  --stemline-arctable-hover-color: #000;
  color: var(--stemline-arctable-color);
  border-color: var(--stemline-arctable-border-color);
}

.table-success {
  --stemline-arctable-color: #000;
  --stemline-arctable-bg: #d1e7dd;
  --stemline-arctable-border-color: #bcd0c7;
  --stemline-arctable-striped-bg: #c7dbd2;
  --stemline-arctable-striped-color: #000;
  --stemline-arctable-active-bg: #bcd0c7;
  --stemline-arctable-active-color: #000;
  --stemline-arctable-hover-bg: #c1d6cc;
  --stemline-arctable-hover-color: #000;
  color: var(--stemline-arctable-color);
  border-color: var(--stemline-arctable-border-color);
}

.table-info {
  --stemline-arctable-color: #000;
  --stemline-arctable-bg: #cff4fc;
  --stemline-arctable-border-color: #badce3;
  --stemline-arctable-striped-bg: #c5e8ef;
  --stemline-arctable-striped-color: #000;
  --stemline-arctable-active-bg: #badce3;
  --stemline-arctable-active-color: #000;
  --stemline-arctable-hover-bg: #bfe2e9;
  --stemline-arctable-hover-color: #000;
  color: var(--stemline-arctable-color);
  border-color: var(--stemline-arctable-border-color);
}

.table-warning {
  --stemline-arctable-color: #000;
  --stemline-arctable-bg: #fff3cd;
  --stemline-arctable-border-color: #e6dbb9;
  --stemline-arctable-striped-bg: #f2e7c3;
  --stemline-arctable-striped-color: #000;
  --stemline-arctable-active-bg: #e6dbb9;
  --stemline-arctable-active-color: #000;
  --stemline-arctable-hover-bg: #ece1be;
  --stemline-arctable-hover-color: #000;
  color: var(--stemline-arctable-color);
  border-color: var(--stemline-arctable-border-color);
}

.table-danger {
  --stemline-arctable-color: #000;
  --stemline-arctable-bg: #f8d7da;
  --stemline-arctable-border-color: #dfc2c4;
  --stemline-arctable-striped-bg: #eccccf;
  --stemline-arctable-striped-color: #000;
  --stemline-arctable-active-bg: #dfc2c4;
  --stemline-arctable-active-color: #000;
  --stemline-arctable-hover-bg: #e5c7ca;
  --stemline-arctable-hover-color: #000;
  color: var(--stemline-arctable-color);
  border-color: var(--stemline-arctable-border-color);
}

.table-light {
  --stemline-arctable-color: #000;
  --stemline-arctable-bg: #f8f9fa;
  --stemline-arctable-border-color: #dfe0e1;
  --stemline-arctable-striped-bg: #ecedee;
  --stemline-arctable-striped-color: #000;
  --stemline-arctable-active-bg: #dfe0e1;
  --stemline-arctable-active-color: #000;
  --stemline-arctable-hover-bg: #e5e6e7;
  --stemline-arctable-hover-color: #000;
  color: var(--stemline-arctable-color);
  border-color: var(--stemline-arctable-border-color);
}

.table-dark {
  --stemline-arctable-color: #fff;
  --stemline-arctable-bg: #212529;
  --stemline-arctable-border-color: #373b3e;
  --stemline-arctable-striped-bg: #2c3034;
  --stemline-arctable-striped-color: #fff;
  --stemline-arctable-active-bg: #373b3e;
  --stemline-arctable-active-color: #fff;
  --stemline-arctable-hover-bg: #323539;
  --stemline-arctable-hover-color: #fff;
  color: var(--stemline-arctable-color);
  border-color: var(--stemline-arctable-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --stemline-arcbtn-padding-x: 0.75rem;
  --stemline-arcbtn-padding-y: 0.375rem;
  --stemline-arcbtn-font-family: ;
  --stemline-arcbtn-font-size: 1rem;
  --stemline-arcbtn-font-weight: 400;
  --stemline-arcbtn-line-height: 1.5;
  --stemline-arcbtn-color: #212529;
  --stemline-arcbtn-bg: transparent;
  --stemline-arcbtn-border-width: 1px;
  --stemline-arcbtn-border-color: transparent;
  --stemline-arcbtn-border-radius: 0.375rem;
  --stemline-arcbtn-hover-border-color: transparent;
  --stemline-arcbtn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --stemline-arcbtn-disabled-opacity: 0.65;
  --stemline-arcbtn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--stemline-arcbtn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--stemline-arcbtn-padding-y) var(--stemline-arcbtn-padding-x);
  font-family: var(--stemline-arcbtn-font-family);
  font-size: var(--stemline-arcbtn-font-size);
  font-weight: var(--stemline-arcbtn-font-weight);
  line-height: var(--stemline-arcbtn-line-height);
  color: var(--stemline-arcbtn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--stemline-arcbtn-border-width) solid var(--stemline-arcbtn-border-color);
  border-radius: var(--stemline-arcbtn-border-radius);
  background-color: var(--stemline-arcbtn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--stemline-arcbtn-hover-color);
  background-color: var(--stemline-arcbtn-hover-bg);
  border-color: var(--stemline-arcbtn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--stemline-arcbtn-color);
  background-color: var(--stemline-arcbtn-bg);
  border-color: var(--stemline-arcbtn-border-color);
}

.btn:focus-visible {
  color: var(--stemline-arcbtn-hover-color);
  background-color: var(--stemline-arcbtn-hover-bg);
  border-color: var(--stemline-arcbtn-hover-border-color);
  outline: 0;
  box-shadow: var(--stemline-arcbtn-focus-box-shadow);
}

.btn-check:focus-visible + .btn {
  border-color: var(--stemline-arcbtn-hover-border-color);
  outline: 0;
  box-shadow: var(--stemline-arcbtn-focus-box-shadow);
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--stemline-arcbtn-active-color);
  background-color: var(--stemline-arcbtn-active-bg);
  border-color: var(--stemline-arcbtn-active-border-color);
}

.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--stemline-arcbtn-focus-box-shadow);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  color: var(--stemline-arcbtn-disabled-color);
  pointer-events: none;
  background-color: var(--stemline-arcbtn-disabled-bg);
  border-color: var(--stemline-arcbtn-disabled-border-color);
  opacity: var(--stemline-arcbtn-disabled-opacity);
}

.btn-primary {
  --stemline-arcbtn-color: #fff;
  --stemline-arcbtn-bg: #0d6efd;
  --stemline-arcbtn-border-color: #0d6efd;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #0b5ed7;
  --stemline-arcbtn-hover-border-color: #0a58ca;
  --stemline-arcbtn-focus-shadow-rgb: 49, 132, 253;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #0a58ca;
  --stemline-arcbtn-active-border-color: #0a53be;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #fff;
  --stemline-arcbtn-disabled-bg: #0d6efd;
  --stemline-arcbtn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --stemline-arcbtn-color: #fff;
  --stemline-arcbtn-bg: #6c757d;
  --stemline-arcbtn-border-color: #6c757d;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #5c636a;
  --stemline-arcbtn-hover-border-color: #565e64;
  --stemline-arcbtn-focus-shadow-rgb: 130, 138, 145;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #565e64;
  --stemline-arcbtn-active-border-color: #51585e;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #fff;
  --stemline-arcbtn-disabled-bg: #6c757d;
  --stemline-arcbtn-disabled-border-color: #6c757d;
}

.btn-success {
  --stemline-arcbtn-color: #fff;
  --stemline-arcbtn-bg: #198754;
  --stemline-arcbtn-border-color: #198754;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #157347;
  --stemline-arcbtn-hover-border-color: #146c43;
  --stemline-arcbtn-focus-shadow-rgb: 60, 153, 110;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #146c43;
  --stemline-arcbtn-active-border-color: #13653f;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #fff;
  --stemline-arcbtn-disabled-bg: #198754;
  --stemline-arcbtn-disabled-border-color: #198754;
}

.btn-info {
  --stemline-arcbtn-color: #000;
  --stemline-arcbtn-bg: #0dcaf0;
  --stemline-arcbtn-border-color: #0dcaf0;
  --stemline-arcbtn-hover-color: #000;
  --stemline-arcbtn-hover-bg: #31d2f2;
  --stemline-arcbtn-hover-border-color: #25cff2;
  --stemline-arcbtn-focus-shadow-rgb: 11, 172, 204;
  --stemline-arcbtn-active-color: #000;
  --stemline-arcbtn-active-bg: #3dd5f3;
  --stemline-arcbtn-active-border-color: #25cff2;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #000;
  --stemline-arcbtn-disabled-bg: #0dcaf0;
  --stemline-arcbtn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --stemline-arcbtn-color: #000;
  --stemline-arcbtn-bg: #ffc107;
  --stemline-arcbtn-border-color: #ffc107;
  --stemline-arcbtn-hover-color: #000;
  --stemline-arcbtn-hover-bg: #ffca2c;
  --stemline-arcbtn-hover-border-color: #ffc720;
  --stemline-arcbtn-focus-shadow-rgb: 217, 164, 6;
  --stemline-arcbtn-active-color: #000;
  --stemline-arcbtn-active-bg: #ffcd39;
  --stemline-arcbtn-active-border-color: #ffc720;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #000;
  --stemline-arcbtn-disabled-bg: #ffc107;
  --stemline-arcbtn-disabled-border-color: #ffc107;
}

.btn-danger {
  --stemline-arcbtn-color: #fff;
  --stemline-arcbtn-bg: #dc3545;
  --stemline-arcbtn-border-color: #dc3545;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #bb2d3b;
  --stemline-arcbtn-hover-border-color: #b02a37;
  --stemline-arcbtn-focus-shadow-rgb: 225, 83, 97;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #b02a37;
  --stemline-arcbtn-active-border-color: #a52834;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #fff;
  --stemline-arcbtn-disabled-bg: #dc3545;
  --stemline-arcbtn-disabled-border-color: #dc3545;
}

.btn-light {
  --stemline-arcbtn-color: #000;
  --stemline-arcbtn-bg: #f8f9fa;
  --stemline-arcbtn-border-color: #f8f9fa;
  --stemline-arcbtn-hover-color: #000;
  --stemline-arcbtn-hover-bg: #d3d4d5;
  --stemline-arcbtn-hover-border-color: #c6c7c8;
  --stemline-arcbtn-focus-shadow-rgb: 211, 212, 213;
  --stemline-arcbtn-active-color: #000;
  --stemline-arcbtn-active-bg: #c6c7c8;
  --stemline-arcbtn-active-border-color: #babbbc;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #000;
  --stemline-arcbtn-disabled-bg: #f8f9fa;
  --stemline-arcbtn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --stemline-arcbtn-color: #fff;
  --stemline-arcbtn-bg: #212529;
  --stemline-arcbtn-border-color: #212529;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #424649;
  --stemline-arcbtn-hover-border-color: #373b3e;
  --stemline-arcbtn-focus-shadow-rgb: 66, 70, 73;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #4d5154;
  --stemline-arcbtn-active-border-color: #373b3e;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #fff;
  --stemline-arcbtn-disabled-bg: #212529;
  --stemline-arcbtn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --stemline-arcbtn-color: #0d6efd;
  --stemline-arcbtn-border-color: #0d6efd;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #0d6efd;
  --stemline-arcbtn-hover-border-color: #0d6efd;
  --stemline-arcbtn-focus-shadow-rgb: 13, 110, 253;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #0d6efd;
  --stemline-arcbtn-active-border-color: #0d6efd;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #0d6efd;
  --stemline-arcbtn-disabled-bg: transparent;
  --stemline-arcbtn-disabled-border-color: #0d6efd;
  --stemline-arcgradient: none;
}

.btn-outline-secondary {
  --stemline-arcbtn-color: #6c757d;
  --stemline-arcbtn-border-color: #6c757d;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #6c757d;
  --stemline-arcbtn-hover-border-color: #6c757d;
  --stemline-arcbtn-focus-shadow-rgb: 108, 117, 125;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #6c757d;
  --stemline-arcbtn-active-border-color: #6c757d;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #6c757d;
  --stemline-arcbtn-disabled-bg: transparent;
  --stemline-arcbtn-disabled-border-color: #6c757d;
  --stemline-arcgradient: none;
}

.btn-outline-success {
  --stemline-arcbtn-color: #198754;
  --stemline-arcbtn-border-color: #198754;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #198754;
  --stemline-arcbtn-hover-border-color: #198754;
  --stemline-arcbtn-focus-shadow-rgb: 25, 135, 84;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #198754;
  --stemline-arcbtn-active-border-color: #198754;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #198754;
  --stemline-arcbtn-disabled-bg: transparent;
  --stemline-arcbtn-disabled-border-color: #198754;
  --stemline-arcgradient: none;
}

.btn-outline-info {
  --stemline-arcbtn-color: #0dcaf0;
  --stemline-arcbtn-border-color: #0dcaf0;
  --stemline-arcbtn-hover-color: #000;
  --stemline-arcbtn-hover-bg: #0dcaf0;
  --stemline-arcbtn-hover-border-color: #0dcaf0;
  --stemline-arcbtn-focus-shadow-rgb: 13, 202, 240;
  --stemline-arcbtn-active-color: #000;
  --stemline-arcbtn-active-bg: #0dcaf0;
  --stemline-arcbtn-active-border-color: #0dcaf0;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #0dcaf0;
  --stemline-arcbtn-disabled-bg: transparent;
  --stemline-arcbtn-disabled-border-color: #0dcaf0;
  --stemline-arcgradient: none;
}

.btn-outline-warning {
  --stemline-arcbtn-color: #ffc107;
  --stemline-arcbtn-border-color: #ffc107;
  --stemline-arcbtn-hover-color: #000;
  --stemline-arcbtn-hover-bg: #ffc107;
  --stemline-arcbtn-hover-border-color: #ffc107;
  --stemline-arcbtn-focus-shadow-rgb: 255, 193, 7;
  --stemline-arcbtn-active-color: #000;
  --stemline-arcbtn-active-bg: #ffc107;
  --stemline-arcbtn-active-border-color: #ffc107;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #ffc107;
  --stemline-arcbtn-disabled-bg: transparent;
  --stemline-arcbtn-disabled-border-color: #ffc107;
  --stemline-arcgradient: none;
}

.btn-outline-danger {
  --stemline-arcbtn-color: #dc3545;
  --stemline-arcbtn-border-color: #dc3545;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #dc3545;
  --stemline-arcbtn-hover-border-color: #dc3545;
  --stemline-arcbtn-focus-shadow-rgb: 220, 53, 69;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #dc3545;
  --stemline-arcbtn-active-border-color: #dc3545;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #dc3545;
  --stemline-arcbtn-disabled-bg: transparent;
  --stemline-arcbtn-disabled-border-color: #dc3545;
  --stemline-arcgradient: none;
}

.btn-outline-light {
  --stemline-arcbtn-color: #f8f9fa;
  --stemline-arcbtn-border-color: #f8f9fa;
  --stemline-arcbtn-hover-color: #000;
  --stemline-arcbtn-hover-bg: #f8f9fa;
  --stemline-arcbtn-hover-border-color: #f8f9fa;
  --stemline-arcbtn-focus-shadow-rgb: 248, 249, 250;
  --stemline-arcbtn-active-color: #000;
  --stemline-arcbtn-active-bg: #f8f9fa;
  --stemline-arcbtn-active-border-color: #f8f9fa;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #f8f9fa;
  --stemline-arcbtn-disabled-bg: transparent;
  --stemline-arcbtn-disabled-border-color: #f8f9fa;
  --stemline-arcgradient: none;
}

.btn-outline-dark {
  --stemline-arcbtn-color: #212529;
  --stemline-arcbtn-border-color: #212529;
  --stemline-arcbtn-hover-color: #fff;
  --stemline-arcbtn-hover-bg: #212529;
  --stemline-arcbtn-hover-border-color: #212529;
  --stemline-arcbtn-focus-shadow-rgb: 33, 37, 41;
  --stemline-arcbtn-active-color: #fff;
  --stemline-arcbtn-active-bg: #212529;
  --stemline-arcbtn-active-border-color: #212529;
  --stemline-arcbtn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --stemline-arcbtn-disabled-color: #212529;
  --stemline-arcbtn-disabled-bg: transparent;
  --stemline-arcbtn-disabled-border-color: #212529;
  --stemline-arcgradient: none;
}

.btn-link {
  --stemline-arcbtn-font-weight: 400;
  --stemline-arcbtn-color: var(--stemline-arclink-color);
  --stemline-arcbtn-bg: transparent;
  --stemline-arcbtn-border-color: transparent;
  --stemline-arcbtn-hover-color: var(--stemline-arclink-hover-color);
  --stemline-arcbtn-hover-border-color: transparent;
  --stemline-arcbtn-active-color: var(--stemline-arclink-hover-color);
  --stemline-arcbtn-active-border-color: transparent;
  --stemline-arcbtn-disabled-color: #6c757d;
  --stemline-arcbtn-disabled-border-color: transparent;
  --stemline-arcbtn-box-shadow: none;
  --stemline-arcbtn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--stemline-arcbtn-color);
}

.btn-link:hover {
  color: var(--stemline-arcbtn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --stemline-arcbtn-padding-y: 0.5rem;
  --stemline-arcbtn-padding-x: 1rem;
  --stemline-arcbtn-font-size: 1.25rem;
  --stemline-arcbtn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --stemline-arcbtn-padding-y: 0.25rem;
  --stemline-arcbtn-padding-x: 0.5rem;
  --stemline-arcbtn-font-size: 0.875rem;
  --stemline-arcbtn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --stemline-arcdropdown-zindex: 1000;
  --stemline-arcdropdown-min-width: 10rem;
  --stemline-arcdropdown-padding-x: 0;
  --stemline-arcdropdown-padding-y: 0.5rem;
  --stemline-arcdropdown-spacer: 0.125rem;
  --stemline-arcdropdown-font-size: 1rem;
  --stemline-arcdropdown-color: #212529;
  --stemline-arcdropdown-bg: #fff;
  --stemline-arcdropdown-border-color: var(--stemline-arcborder-color-translucent);
  --stemline-arcdropdown-border-radius: 0.375rem;
  --stemline-arcdropdown-border-width: 1px;
  --stemline-arcdropdown-inner-border-radius: calc(0.375rem - 1px);
  --stemline-arcdropdown-divider-bg: var(--stemline-arcborder-color-translucent);
  --stemline-arcdropdown-divider-margin-y: 0.5rem;
  --stemline-arcdropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --stemline-arcdropdown-link-color: #212529;
  --stemline-arcdropdown-link-hover-color: #1e2125;
  --stemline-arcdropdown-link-hover-bg: #e9ecef;
  --stemline-arcdropdown-link-active-color: #fff;
  --stemline-arcdropdown-link-active-bg: #0d6efd;
  --stemline-arcdropdown-link-disabled-color: #adb5bd;
  --stemline-arcdropdown-item-padding-x: 1rem;
  --stemline-arcdropdown-item-padding-y: 0.25rem;
  --stemline-arcdropdown-header-color: #6c757d;
  --stemline-arcdropdown-header-padding-x: 1rem;
  --stemline-arcdropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--stemline-arcdropdown-zindex);
  display: none;
  min-width: var(--stemline-arcdropdown-min-width);
  padding: var(--stemline-arcdropdown-padding-y) var(--stemline-arcdropdown-padding-x);
  margin: 0;
  font-size: var(--stemline-arcdropdown-font-size);
  color: var(--stemline-arcdropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--stemline-arcdropdown-bg);
  background-clip: padding-box;
  border: var(--stemline-arcdropdown-border-width) solid var(--stemline-arcdropdown-border-color);
  border-radius: var(--stemline-arcdropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--stemline-arcdropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--stemline-arcdropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--stemline-arcdropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--stemline-arcdropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--stemline-arcdropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--stemline-arcdropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--stemline-arcdropdown-item-padding-y) var(--stemline-arcdropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--stemline-arcdropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--stemline-arcdropdown-link-hover-color);
  background-color: var(--stemline-arcdropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--stemline-arcdropdown-link-active-color);
  text-decoration: none;
  background-color: var(--stemline-arcdropdown-link-active-bg);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--stemline-arcdropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--stemline-arcdropdown-header-padding-y) var(--stemline-arcdropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--stemline-arcdropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--stemline-arcdropdown-item-padding-y) var(--stemline-arcdropdown-item-padding-x);
  color: var(--stemline-arcdropdown-link-color);
}

.dropdown-menu-dark {
  --stemline-arcdropdown-color: #dee2e6;
  --stemline-arcdropdown-bg: #343a40;
  --stemline-arcdropdown-border-color: var(--stemline-arcborder-color-translucent);
  --stemline-arcdropdown-box-shadow: ;
  --stemline-arcdropdown-link-color: #dee2e6;
  --stemline-arcdropdown-link-hover-color: #fff;
  --stemline-arcdropdown-divider-bg: var(--stemline-arcborder-color-translucent);
  --stemline-arcdropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --stemline-arcdropdown-link-active-color: #fff;
  --stemline-arcdropdown-link-active-bg: #0d6efd;
  --stemline-arcdropdown-link-disabled-color: #adb5bd;
  --stemline-arcdropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --stemline-arcnav-link-padding-x: 1rem;
  --stemline-arcnav-link-padding-y: 0.5rem;
  --stemline-arcnav-link-font-weight: ;
  --stemline-arcnav-link-color: var(--stemline-arclink-color);
  --stemline-arcnav-link-hover-color: var(--stemline-arclink-hover-color);
  --stemline-arcnav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--stemline-arcnav-link-padding-y) var(--stemline-arcnav-link-padding-x);
  font-size: var(--stemline-arcnav-link-font-size);
  font-weight: var(--stemline-arcnav-link-font-weight);
  color: var(--stemline-arcnav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--stemline-arcnav-link-hover-color);
}

.nav-link.disabled {
  color: var(--stemline-arcnav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --stemline-arcnav-tabs-border-width: 1px;
  --stemline-arcnav-tabs-border-color: #dee2e6;
  --stemline-arcnav-tabs-border-radius: 0.375rem;
  --stemline-arcnav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --stemline-arcnav-tabs-link-active-color: #495057;
  --stemline-arcnav-tabs-link-active-bg: #fff;
  --stemline-arcnav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--stemline-arcnav-tabs-border-width) solid var(--stemline-arcnav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--stemline-arcnav-tabs-border-width));
  background: none;
  border: var(--stemline-arcnav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--stemline-arcnav-tabs-border-radius);
  border-top-right-radius: var(--stemline-arcnav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--stemline-arcnav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--stemline-arcnav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--stemline-arcnav-tabs-link-active-color);
  background-color: var(--stemline-arcnav-tabs-link-active-bg);
  border-color: var(--stemline-arcnav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--stemline-arcnav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --stemline-arcnav-pills-border-radius: 0.375rem;
  --stemline-arcnav-pills-link-active-color: #fff;
  --stemline-arcnav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--stemline-arcnav-pills-border-radius);
}

.nav-pills .nav-link:disabled {
  color: var(--stemline-arcnav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--stemline-arcnav-pills-link-active-color);
  background-color: var(--stemline-arcnav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --stemline-arcnavbar-padding-x: 0;
  --stemline-arcnavbar-padding-y: 0.5rem;
  --stemline-arcnavbar-color: rgba(0, 0, 0, 0.55);
  --stemline-arcnavbar-hover-color: rgba(0, 0, 0, 0.7);
  --stemline-arcnavbar-disabled-color: rgba(0, 0, 0, 0.3);
  --stemline-arcnavbar-active-color: rgba(0, 0, 0, 0.9);
  --stemline-arcnavbar-brand-padding-y: 0.3125rem;
  --stemline-arcnavbar-brand-margin-end: 1rem;
  --stemline-arcnavbar-brand-font-size: 1.25rem;
  --stemline-arcnavbar-brand-color: rgba(0, 0, 0, 0.9);
  --stemline-arcnavbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --stemline-arcnavbar-nav-link-padding-x: 0.5rem;
  --stemline-arcnavbar-toggler-padding-y: 0.25rem;
  --stemline-arcnavbar-toggler-padding-x: 0.75rem;
  --stemline-arcnavbar-toggler-font-size: 1.25rem;
  --stemline-arcnavbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --stemline-arcnavbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --stemline-arcnavbar-toggler-border-radius: 0.375rem;
  --stemline-arcnavbar-toggler-focus-width: 0.25rem;
  --stemline-arcnavbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--stemline-arcnavbar-padding-y) var(--stemline-arcnavbar-padding-x);
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--stemline-arcnavbar-brand-padding-y);
  padding-bottom: var(--stemline-arcnavbar-brand-padding-y);
  margin-right: var(--stemline-arcnavbar-brand-margin-end);
  font-size: var(--stemline-arcnavbar-brand-font-size);
  color: var(--stemline-arcnavbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--stemline-arcnavbar-brand-hover-color);
}

.navbar-nav {
  --stemline-arcnav-link-padding-x: 0;
  --stemline-arcnav-link-padding-y: 0.5rem;
  --stemline-arcnav-link-font-weight: ;
  --stemline-arcnav-link-color: var(--stemline-arcnavbar-color);
  --stemline-arcnav-link-hover-color: var(--stemline-arcnavbar-hover-color);
  --stemline-arcnav-link-disabled-color: var(--stemline-arcnavbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--stemline-arcnavbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--stemline-arcnavbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--stemline-arcnavbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--stemline-arcnavbar-toggler-padding-y) var(--stemline-arcnavbar-toggler-padding-x);
  font-size: var(--stemline-arcnavbar-toggler-font-size);
  line-height: 1;
  color: var(--stemline-arcnavbar-color);
  background-color: transparent;
  border: var(--stemline-arcborder-width) solid var(--stemline-arcnavbar-toggler-border-color);
  border-radius: var(--stemline-arcnavbar-toggler-border-radius);
  transition: var(--stemline-arcnavbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--stemline-arcnavbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--stemline-arcnavbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--stemline-arcscroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--stemline-arcnavbar-nav-link-padding-x);
    padding-left: var(--stemline-arcnavbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--stemline-arcnavbar-nav-link-padding-x);
    padding-left: var(--stemline-arcnavbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--stemline-arcnavbar-nav-link-padding-x);
    padding-left: var(--stemline-arcnavbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--stemline-arcnavbar-nav-link-padding-x);
    padding-left: var(--stemline-arcnavbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--stemline-arcnavbar-nav-link-padding-x);
    padding-left: var(--stemline-arcnavbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--stemline-arcnavbar-nav-link-padding-x);
  padding-left: var(--stemline-arcnavbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --stemline-arcnavbar-color: rgba(255, 255, 255, 0.55);
  --stemline-arcnavbar-hover-color: rgba(255, 255, 255, 0.75);
  --stemline-arcnavbar-disabled-color: rgba(255, 255, 255, 0.25);
  --stemline-arcnavbar-active-color: #fff;
  --stemline-arcnavbar-brand-color: #fff;
  --stemline-arcnavbar-brand-hover-color: #fff;
  --stemline-arcnavbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --stemline-arcnavbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --stemline-arccard-spacer-y: 1rem;
  --stemline-arccard-spacer-x: 1rem;
  --stemline-arccard-title-spacer-y: 0.5rem;
  --stemline-arccard-border-width: 1px;
  --stemline-arccard-border-color: var(--stemline-arcborder-color-translucent);
  --stemline-arccard-border-radius: 0.375rem;
  --stemline-arccard-box-shadow: ;
  --stemline-arccard-inner-border-radius: calc(0.375rem - 1px);
  --stemline-arccard-cap-padding-y: 0.5rem;
  --stemline-arccard-cap-padding-x: 1rem;
  --stemline-arccard-cap-bg: rgba(0, 0, 0, 0.03);
  --stemline-arccard-cap-color: ;
  --stemline-arccard-height: ;
  --stemline-arccard-color: ;
  --stemline-arccard-bg: #fff;
  --stemline-arccard-img-overlay-padding: 1rem;
  --stemline-arccard-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--stemline-arccard-height);
  word-wrap: break-word;
  background-color: var(--stemline-arccard-bg);
  background-clip: border-box;
  border: var(--stemline-arccard-border-width) solid var(--stemline-arccard-border-color);
  border-radius: var(--stemline-arccard-border-radius);
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--stemline-arccard-inner-border-radius);
  border-top-right-radius: var(--stemline-arccard-inner-border-radius);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--stemline-arccard-inner-border-radius);
  border-bottom-left-radius: var(--stemline-arccard-inner-border-radius);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--stemline-arccard-spacer-y) var(--stemline-arccard-spacer-x);
  color: var(--stemline-arccard-color);
}

.card-title {
  margin-bottom: var(--stemline-arccard-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--stemline-arccard-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--stemline-arccard-spacer-x);
}

.card-header {
  padding: var(--stemline-arccard-cap-padding-y) var(--stemline-arccard-cap-padding-x);
  margin-bottom: 0;
  color: var(--stemline-arccard-cap-color);
  background-color: var(--stemline-arccard-cap-bg);
  border-bottom: var(--stemline-arccard-border-width) solid var(--stemline-arccard-border-color);
}

.card-header:first-child {
  border-radius: var(--stemline-arccard-inner-border-radius) var(--stemline-arccard-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--stemline-arccard-cap-padding-y) var(--stemline-arccard-cap-padding-x);
  color: var(--stemline-arccard-cap-color);
  background-color: var(--stemline-arccard-cap-bg);
  border-top: var(--stemline-arccard-border-width) solid var(--stemline-arccard-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--stemline-arccard-inner-border-radius) var(--stemline-arccard-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--stemline-arccard-cap-padding-x));
  margin-bottom: calc(-1 * var(--stemline-arccard-cap-padding-y));
  margin-left: calc(-.5 * var(--stemline-arccard-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--stemline-arccard-bg);
  border-bottom-color: var(--stemline-arccard-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--stemline-arccard-cap-padding-x));
  margin-left: calc(-.5 * var(--stemline-arccard-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--stemline-arccard-img-overlay-padding);
  border-radius: var(--stemline-arccard-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--stemline-arccard-inner-border-radius);
  border-top-right-radius: var(--stemline-arccard-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--stemline-arccard-inner-border-radius);
  border-bottom-left-radius: var(--stemline-arccard-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--stemline-arccard-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --stemline-arcaccordion-color: #212529;
  --stemline-arcaccordion-bg: #fff;
  --stemline-arcaccordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --stemline-arcaccordion-border-color: var(--stemline-arcborder-color);
  --stemline-arcaccordion-border-width: 1px;
  --stemline-arcaccordion-border-radius: 0.375rem;
  --stemline-arcaccordion-inner-border-radius: calc(0.375rem - 1px);
  --stemline-arcaccordion-btn-padding-x: 1.25rem;
  --stemline-arcaccordion-btn-padding-y: 1rem;
  --stemline-arcaccordion-btn-color: #212529;
  --stemline-arcaccordion-btn-bg: var(--stemline-arcaccordion-bg);
  --stemline-arcaccordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --stemline-arcaccordion-btn-icon-width: 1.25rem;
  --stemline-arcaccordion-btn-icon-transform: rotate(-180deg);
  --stemline-arcaccordion-btn-icon-transition: transform 0.2s ease-in-out;
  --stemline-arcaccordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --stemline-arcaccordion-btn-focus-border-color: #86b7fe;
  --stemline-arcaccordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --stemline-arcaccordion-body-padding-x: 1.25rem;
  --stemline-arcaccordion-body-padding-y: 1rem;
  --stemline-arcaccordion-active-color: #0c63e4;
  --stemline-arcaccordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--stemline-arcaccordion-btn-padding-y) var(--stemline-arcaccordion-btn-padding-x);
  font-size: 1rem;
  color: var(--stemline-arcaccordion-btn-color);
  text-align: left;
  background-color: var(--stemline-arcaccordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--stemline-arcaccordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--stemline-arcaccordion-active-color);
  background-color: var(--stemline-arcaccordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--stemline-arcaccordion-border-width)) 0 var(--stemline-arcaccordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--stemline-arcaccordion-btn-active-icon);
  transform: var(--stemline-arcaccordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--stemline-arcaccordion-btn-icon-width);
  height: var(--stemline-arcaccordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--stemline-arcaccordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--stemline-arcaccordion-btn-icon-width);
  transition: var(--stemline-arcaccordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--stemline-arcaccordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--stemline-arcaccordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--stemline-arcaccordion-color);
  background-color: var(--stemline-arcaccordion-bg);
  border: var(--stemline-arcaccordion-border-width) solid var(--stemline-arcaccordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--stemline-arcaccordion-border-radius);
  border-top-right-radius: var(--stemline-arcaccordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--stemline-arcaccordion-inner-border-radius);
  border-top-right-radius: var(--stemline-arcaccordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--stemline-arcaccordion-border-radius);
  border-bottom-left-radius: var(--stemline-arcaccordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--stemline-arcaccordion-inner-border-radius);
  border-bottom-left-radius: var(--stemline-arcaccordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--stemline-arcaccordion-border-radius);
  border-bottom-left-radius: var(--stemline-arcaccordion-border-radius);
}

.accordion-body {
  padding: var(--stemline-arcaccordion-body-padding-y) var(--stemline-arcaccordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --stemline-arcbreadcrumb-padding-x: 0;
  --stemline-arcbreadcrumb-padding-y: 0;
  --stemline-arcbreadcrumb-margin-bottom: 1rem;
  --stemline-arcbreadcrumb-bg: ;
  --stemline-arcbreadcrumb-border-radius: ;
  --stemline-arcbreadcrumb-divider-color: #6c757d;
  --stemline-arcbreadcrumb-item-padding-x: 0.5rem;
  --stemline-arcbreadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--stemline-arcbreadcrumb-padding-y) var(--stemline-arcbreadcrumb-padding-x);
  margin-bottom: var(--stemline-arcbreadcrumb-margin-bottom);
  font-size: var(--stemline-arcbreadcrumb-font-size);
  list-style: none;
  background-color: var(--stemline-arcbreadcrumb-bg);
  border-radius: var(--stemline-arcbreadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--stemline-arcbreadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--stemline-arcbreadcrumb-item-padding-x);
  color: var(--stemline-arcbreadcrumb-divider-color);
  content: var(--stemline-arcbreadcrumb-divider, "/") /* rtl: var(--stemline-arcbreadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: var(--stemline-arcbreadcrumb-item-active-color);
}

.pagination {
  --stemline-arcpagination-padding-x: 0.75rem;
  --stemline-arcpagination-padding-y: 0.375rem;
  --stemline-arcpagination-font-size: 1rem;
  --stemline-arcpagination-color: var(--stemline-arclink-color);
  --stemline-arcpagination-bg: #fff;
  --stemline-arcpagination-border-width: 1px;
  --stemline-arcpagination-border-color: #dee2e6;
  --stemline-arcpagination-border-radius: 0.375rem;
  --stemline-arcpagination-hover-color: var(--stemline-arclink-hover-color);
  --stemline-arcpagination-hover-bg: #e9ecef;
  --stemline-arcpagination-hover-border-color: #dee2e6;
  --stemline-arcpagination-focus-color: var(--stemline-arclink-hover-color);
  --stemline-arcpagination-focus-bg: #e9ecef;
  --stemline-arcpagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --stemline-arcpagination-active-color: #fff;
  --stemline-arcpagination-active-bg: #0d6efd;
  --stemline-arcpagination-active-border-color: #0d6efd;
  --stemline-arcpagination-disabled-color: #6c757d;
  --stemline-arcpagination-disabled-bg: #fff;
  --stemline-arcpagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--stemline-arcpagination-padding-y) var(--stemline-arcpagination-padding-x);
  font-size: var(--stemline-arcpagination-font-size);
  color: var(--stemline-arcpagination-color);
  text-decoration: none;
  background-color: var(--stemline-arcpagination-bg);
  border: var(--stemline-arcpagination-border-width) solid var(--stemline-arcpagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--stemline-arcpagination-hover-color);
  background-color: var(--stemline-arcpagination-hover-bg);
  border-color: var(--stemline-arcpagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--stemline-arcpagination-focus-color);
  background-color: var(--stemline-arcpagination-focus-bg);
  outline: 0;
  box-shadow: var(--stemline-arcpagination-focus-box-shadow);
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--stemline-arcpagination-active-color);
  background-color: var(--stemline-arcpagination-active-bg);
  border-color: var(--stemline-arcpagination-active-border-color);
}

.page-link.disabled,
.disabled > .page-link {
  color: var(--stemline-arcpagination-disabled-color);
  pointer-events: none;
  background-color: var(--stemline-arcpagination-disabled-bg);
  border-color: var(--stemline-arcpagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--stemline-arcpagination-border-radius);
  border-bottom-left-radius: var(--stemline-arcpagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--stemline-arcpagination-border-radius);
  border-bottom-right-radius: var(--stemline-arcpagination-border-radius);
}

.pagination-lg {
  --stemline-arcpagination-padding-x: 1.5rem;
  --stemline-arcpagination-padding-y: 0.75rem;
  --stemline-arcpagination-font-size: 1.25rem;
  --stemline-arcpagination-border-radius: 0.5rem;
}

.pagination-sm {
  --stemline-arcpagination-padding-x: 0.5rem;
  --stemline-arcpagination-padding-y: 0.25rem;
  --stemline-arcpagination-font-size: 0.875rem;
  --stemline-arcpagination-border-radius: 0.25rem;
}

.badge {
  --stemline-arcbadge-padding-x: 0.65em;
  --stemline-arcbadge-padding-y: 0.35em;
  --stemline-arcbadge-font-size: 0.75em;
  --stemline-arcbadge-font-weight: 700;
  --stemline-arcbadge-color: #fff;
  --stemline-arcbadge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--stemline-arcbadge-padding-y) var(--stemline-arcbadge-padding-x);
  font-size: var(--stemline-arcbadge-font-size);
  font-weight: var(--stemline-arcbadge-font-weight);
  line-height: 1;
  color: var(--stemline-arcbadge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--stemline-arcbadge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --stemline-arcalert-bg: transparent;
  --stemline-arcalert-padding-x: 1rem;
  --stemline-arcalert-padding-y: 1rem;
  --stemline-arcalert-margin-bottom: 1rem;
  --stemline-arcalert-color: inherit;
  --stemline-arcalert-border-color: transparent;
  --stemline-arcalert-border: 1px solid var(--stemline-arcalert-border-color);
  --stemline-arcalert-border-radius: 0.375rem;
  position: relative;
  padding: var(--stemline-arcalert-padding-y) var(--stemline-arcalert-padding-x);
  margin-bottom: var(--stemline-arcalert-margin-bottom);
  color: var(--stemline-arcalert-color);
  background-color: var(--stemline-arcalert-bg);
  border: var(--stemline-arcalert-border);
  border-radius: var(--stemline-arcalert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --stemline-arcalert-color: #084298;
  --stemline-arcalert-bg: #cfe2ff;
  --stemline-arcalert-border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  --stemline-arcalert-color: #41464b;
  --stemline-arcalert-bg: #e2e3e5;
  --stemline-arcalert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --stemline-arcalert-color: #0f5132;
  --stemline-arcalert-bg: #d1e7dd;
  --stemline-arcalert-border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --stemline-arcalert-color: #055160;
  --stemline-arcalert-bg: #cff4fc;
  --stemline-arcalert-border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --stemline-arcalert-color: #664d03;
  --stemline-arcalert-bg: #fff3cd;
  --stemline-arcalert-border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --stemline-arcalert-color: #842029;
  --stemline-arcalert-bg: #f8d7da;
  --stemline-arcalert-border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --stemline-arcalert-color: #636464;
  --stemline-arcalert-bg: #fefefe;
  --stemline-arcalert-border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --stemline-arcalert-color: #141619;
  --stemline-arcalert-bg: #d3d3d4;
  --stemline-arcalert-border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --stemline-arcprogress-height: 1rem;
  --stemline-arcprogress-font-size: 0.75rem;
  --stemline-arcprogress-bg: #e9ecef;
  --stemline-arcprogress-border-radius: 0.375rem;
  --stemline-arcprogress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --stemline-arcprogress-bar-color: #fff;
  --stemline-arcprogress-bar-bg: #0d6efd;
  --stemline-arcprogress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--stemline-arcprogress-height);
  overflow: hidden;
  font-size: var(--stemline-arcprogress-font-size);
  background-color: var(--stemline-arcprogress-bg);
  border-radius: var(--stemline-arcprogress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--stemline-arcprogress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--stemline-arcprogress-bar-bg);
  transition: var(--stemline-arcprogress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--stemline-arcprogress-height) var(--stemline-arcprogress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --stemline-arclist-group-color: #212529;
  --stemline-arclist-group-bg: #fff;
  --stemline-arclist-group-border-color: rgba(0, 0, 0, 0.125);
  --stemline-arclist-group-border-width: 1px;
  --stemline-arclist-group-border-radius: 0.375rem;
  --stemline-arclist-group-item-padding-x: 1rem;
  --stemline-arclist-group-item-padding-y: 0.5rem;
  --stemline-arclist-group-action-color: #495057;
  --stemline-arclist-group-action-hover-color: #495057;
  --stemline-arclist-group-action-hover-bg: #f8f9fa;
  --stemline-arclist-group-action-active-color: #212529;
  --stemline-arclist-group-action-active-bg: #e9ecef;
  --stemline-arclist-group-disabled-color: #6c757d;
  --stemline-arclist-group-disabled-bg: #fff;
  --stemline-arclist-group-active-color: #fff;
  --stemline-arclist-group-active-bg: #0d6efd;
  --stemline-arclist-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--stemline-arclist-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--stemline-arclist-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--stemline-arclist-group-action-hover-color);
  text-decoration: none;
  background-color: var(--stemline-arclist-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--stemline-arclist-group-action-active-color);
  background-color: var(--stemline-arclist-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--stemline-arclist-group-item-padding-y) var(--stemline-arclist-group-item-padding-x);
  color: var(--stemline-arclist-group-color);
  text-decoration: none;
  background-color: var(--stemline-arclist-group-bg);
  border: var(--stemline-arclist-group-border-width) solid var(--stemline-arclist-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--stemline-arclist-group-disabled-color);
  pointer-events: none;
  background-color: var(--stemline-arclist-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--stemline-arclist-group-active-color);
  background-color: var(--stemline-arclist-group-active-bg);
  border-color: var(--stemline-arclist-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--stemline-arclist-group-border-width));
  border-top-width: var(--stemline-arclist-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--stemline-arclist-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--stemline-arclist-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--stemline-arclist-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--stemline-arclist-group-border-width));
  border-left-width: var(--stemline-arclist-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--stemline-arclist-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--stemline-arclist-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--stemline-arclist-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--stemline-arclist-group-border-width));
    border-left-width: var(--stemline-arclist-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--stemline-arclist-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--stemline-arclist-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--stemline-arclist-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--stemline-arclist-group-border-width));
    border-left-width: var(--stemline-arclist-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--stemline-arclist-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--stemline-arclist-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--stemline-arclist-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--stemline-arclist-group-border-width));
    border-left-width: var(--stemline-arclist-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--stemline-arclist-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--stemline-arclist-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--stemline-arclist-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--stemline-arclist-group-border-width));
    border-left-width: var(--stemline-arclist-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--stemline-arclist-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--stemline-arclist-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--stemline-arclist-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--stemline-arclist-group-border-width));
    border-left-width: var(--stemline-arclist-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--stemline-arclist-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --stemline-arctoast-zindex: 1090;
  --stemline-arctoast-padding-x: 0.75rem;
  --stemline-arctoast-padding-y: 0.5rem;
  --stemline-arctoast-spacing: 1.5rem;
  --stemline-arctoast-max-width: 350px;
  --stemline-arctoast-font-size: 0.875rem;
  --stemline-arctoast-color: ;
  --stemline-arctoast-bg: rgba(255, 255, 255, 0.85);
  --stemline-arctoast-border-width: 1px;
  --stemline-arctoast-border-color: var(--stemline-arcborder-color-translucent);
  --stemline-arctoast-border-radius: 0.375rem;
  --stemline-arctoast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --stemline-arctoast-header-color: #6c757d;
  --stemline-arctoast-header-bg: rgba(255, 255, 255, 0.85);
  --stemline-arctoast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--stemline-arctoast-max-width);
  max-width: 100%;
  font-size: var(--stemline-arctoast-font-size);
  color: var(--stemline-arctoast-color);
  pointer-events: auto;
  background-color: var(--stemline-arctoast-bg);
  background-clip: padding-box;
  border: var(--stemline-arctoast-border-width) solid var(--stemline-arctoast-border-color);
  box-shadow: var(--stemline-arctoast-box-shadow);
  border-radius: var(--stemline-arctoast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --stemline-arctoast-zindex: 1090;
  position: absolute;
  z-index: var(--stemline-arctoast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--stemline-arctoast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--stemline-arctoast-padding-y) var(--stemline-arctoast-padding-x);
  color: var(--stemline-arctoast-header-color);
  background-color: var(--stemline-arctoast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--stemline-arctoast-border-width) solid var(--stemline-arctoast-header-border-color);
  border-top-left-radius: calc(var(--stemline-arctoast-border-radius) - var(--stemline-arctoast-border-width));
  border-top-right-radius: calc(var(--stemline-arctoast-border-radius) - var(--stemline-arctoast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--stemline-arctoast-padding-x));
  margin-left: var(--stemline-arctoast-padding-x);
}

.toast-body {
  padding: var(--stemline-arctoast-padding-x);
  word-wrap: break-word;
}

.modal {
  --stemline-arcmodal-zindex: 1055;
  --stemline-arcmodal-width: 500px;
  --stemline-arcmodal-padding: 1rem;
  --stemline-arcmodal-margin: 0.5rem;
  --stemline-arcmodal-color: ;
  --stemline-arcmodal-bg: #fff;
  --stemline-arcmodal-border-color: var(--stemline-arcborder-color-translucent);
  --stemline-arcmodal-border-width: 1px;
  --stemline-arcmodal-border-radius: 0.5rem;
  --stemline-arcmodal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --stemline-arcmodal-inner-border-radius: calc(0.5rem - 1px);
  --stemline-arcmodal-header-padding-x: 1rem;
  --stemline-arcmodal-header-padding-y: 1rem;
  --stemline-arcmodal-header-padding: 1rem 1rem;
  --stemline-arcmodal-header-border-color: var(--stemline-arcborder-color);
  --stemline-arcmodal-header-border-width: 1px;
  --stemline-arcmodal-title-line-height: 1.5;
  --stemline-arcmodal-footer-gap: 0.5rem;
  --stemline-arcmodal-footer-bg: ;
  --stemline-arcmodal-footer-border-color: var(--stemline-arcborder-color);
  --stemline-arcmodal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--stemline-arcmodal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--stemline-arcmodal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--stemline-arcmodal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--stemline-arcmodal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--stemline-arcmodal-color);
  pointer-events: auto;
  background-color: var(--stemline-arcmodal-bg);
  background-clip: padding-box;
  border: var(--stemline-arcmodal-border-width) solid var(--stemline-arcmodal-border-color);
  border-radius: var(--stemline-arcmodal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --stemline-arcbackdrop-zindex: 1050;
  --stemline-arcbackdrop-bg: #000;
  --stemline-arcbackdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--stemline-arcbackdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--stemline-arcbackdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--stemline-arcbackdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--stemline-arcmodal-header-padding);
  border-bottom: var(--stemline-arcmodal-header-border-width) solid var(--stemline-arcmodal-header-border-color);
  border-top-left-radius: var(--stemline-arcmodal-inner-border-radius);
  border-top-right-radius: var(--stemline-arcmodal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--stemline-arcmodal-header-padding-y) * .5) calc(var(--stemline-arcmodal-header-padding-x) * .5);
  margin: calc(-.5 * var(--stemline-arcmodal-header-padding-y)) calc(-.5 * var(--stemline-arcmodal-header-padding-x)) calc(-.5 * var(--stemline-arcmodal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--stemline-arcmodal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--stemline-arcmodal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--stemline-arcmodal-padding) - var(--stemline-arcmodal-footer-gap) * .5);
  background-color: var(--stemline-arcmodal-footer-bg);
  border-top: var(--stemline-arcmodal-footer-border-width) solid var(--stemline-arcmodal-footer-border-color);
  border-bottom-right-radius: var(--stemline-arcmodal-inner-border-radius);
  border-bottom-left-radius: var(--stemline-arcmodal-inner-border-radius);
}

.modal-footer > * {
  margin: calc(var(--stemline-arcmodal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --stemline-arcmodal-margin: 1.75rem;
    --stemline-arcmodal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--stemline-arcmodal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --stemline-arcmodal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --stemline-arcmodal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --stemline-arcmodal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --stemline-arctooltip-zindex: 1080;
  --stemline-arctooltip-max-width: 200px;
  --stemline-arctooltip-padding-x: 0.5rem;
  --stemline-arctooltip-padding-y: 0.25rem;
  --stemline-arctooltip-margin: ;
  --stemline-arctooltip-font-size: 0.875rem;
  --stemline-arctooltip-color: #fff;
  --stemline-arctooltip-bg: #000;
  --stemline-arctooltip-border-radius: 0.375rem;
  --stemline-arctooltip-opacity: 0.9;
  --stemline-arctooltip-arrow-width: 0.8rem;
  --stemline-arctooltip-arrow-height: 0.4rem;
  z-index: var(--stemline-arctooltip-zindex);
  display: block;
  padding: var(--stemline-arctooltip-arrow-height);
  margin: var(--stemline-arctooltip-margin);
  font-family: var(--stemline-arcfont-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--stemline-arctooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--stemline-arctooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--stemline-arctooltip-arrow-width);
  height: var(--stemline-arctooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--stemline-arctooltip-arrow-height) calc(var(--stemline-arctooltip-arrow-width) * .5) 0;
  border-top-color: var(--stemline-arctooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--stemline-arctooltip-arrow-height);
  height: var(--stemline-arctooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--stemline-arctooltip-arrow-width) * .5) var(--stemline-arctooltip-arrow-height) calc(var(--stemline-arctooltip-arrow-width) * .5) 0;
  border-right-color: var(--stemline-arctooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--stemline-arctooltip-arrow-width) * .5) var(--stemline-arctooltip-arrow-height);
  border-bottom-color: var(--stemline-arctooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--stemline-arctooltip-arrow-height);
  height: var(--stemline-arctooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--stemline-arctooltip-arrow-width) * .5) 0 calc(var(--stemline-arctooltip-arrow-width) * .5) var(--stemline-arctooltip-arrow-height);
  border-left-color: var(--stemline-arctooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--stemline-arctooltip-max-width);
  padding: var(--stemline-arctooltip-padding-y) var(--stemline-arctooltip-padding-x);
  color: var(--stemline-arctooltip-color);
  text-align: center;
  background-color: var(--stemline-arctooltip-bg);
  border-radius: var(--stemline-arctooltip-border-radius);
}

.popover {
  --stemline-arcpopover-zindex: 1070;
  --stemline-arcpopover-max-width: 276px;
  --stemline-arcpopover-font-size: 0.875rem;
  --stemline-arcpopover-bg: #fff;
  --stemline-arcpopover-border-width: 1px;
  --stemline-arcpopover-border-color: var(--stemline-arcborder-color-translucent);
  --stemline-arcpopover-border-radius: 0.5rem;
  --stemline-arcpopover-inner-border-radius: calc(0.5rem - 1px);
  --stemline-arcpopover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --stemline-arcpopover-header-padding-x: 1rem;
  --stemline-arcpopover-header-padding-y: 0.5rem;
  --stemline-arcpopover-header-font-size: 1rem;
  --stemline-arcpopover-header-color: ;
  --stemline-arcpopover-header-bg: #f0f0f0;
  --stemline-arcpopover-body-padding-x: 1rem;
  --stemline-arcpopover-body-padding-y: 1rem;
  --stemline-arcpopover-body-color: #212529;
  --stemline-arcpopover-arrow-width: 1rem;
  --stemline-arcpopover-arrow-height: 0.5rem;
  --stemline-arcpopover-arrow-border: var(--stemline-arcpopover-border-color);
  z-index: var(--stemline-arcpopover-zindex);
  display: block;
  max-width: var(--stemline-arcpopover-max-width);
  font-family: var(--stemline-arcfont-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--stemline-arcpopover-font-size);
  word-wrap: break-word;
  background-color: var(--stemline-arcpopover-bg);
  background-clip: padding-box;
  border: var(--stemline-arcpopover-border-width) solid var(--stemline-arcpopover-border-color);
  border-radius: var(--stemline-arcpopover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--stemline-arcpopover-arrow-width);
  height: var(--stemline-arcpopover-arrow-height);
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--stemline-arcpopover-arrow-height)) - var(--stemline-arcpopover-border-width));
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--stemline-arcpopover-arrow-height) calc(var(--stemline-arcpopover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--stemline-arcpopover-arrow-border);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--stemline-arcpopover-border-width);
  border-top-color: var(--stemline-arcpopover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--stemline-arcpopover-arrow-height)) - var(--stemline-arcpopover-border-width));
  width: var(--stemline-arcpopover-arrow-height);
  height: var(--stemline-arcpopover-arrow-width);
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--stemline-arcpopover-arrow-width) * .5) var(--stemline-arcpopover-arrow-height) calc(var(--stemline-arcpopover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--stemline-arcpopover-arrow-border);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--stemline-arcpopover-border-width);
  border-right-color: var(--stemline-arcpopover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--stemline-arcpopover-arrow-height)) - var(--stemline-arcpopover-border-width));
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--stemline-arcpopover-arrow-width) * .5) var(--stemline-arcpopover-arrow-height);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--stemline-arcpopover-arrow-border);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--stemline-arcpopover-border-width);
  border-bottom-color: var(--stemline-arcpopover-bg);
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--stemline-arcpopover-arrow-width);
  margin-left: calc(-.5 * var(--stemline-arcpopover-arrow-width));
  content: "";
  border-bottom: var(--stemline-arcpopover-border-width) solid var(--stemline-arcpopover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--stemline-arcpopover-arrow-height)) - var(--stemline-arcpopover-border-width));
  width: var(--stemline-arcpopover-arrow-height);
  height: var(--stemline-arcpopover-arrow-width);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--stemline-arcpopover-arrow-width) * .5) 0 calc(var(--stemline-arcpopover-arrow-width) * .5) var(--stemline-arcpopover-arrow-height);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--stemline-arcpopover-arrow-border);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--stemline-arcpopover-border-width);
  border-left-color: var(--stemline-arcpopover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--stemline-arcpopover-header-padding-y) var(--stemline-arcpopover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--stemline-arcpopover-header-font-size);
  color: var(--stemline-arcpopover-header-color);
  background-color: var(--stemline-arcpopover-header-bg);
  border-bottom: var(--stemline-arcpopover-border-width) solid var(--stemline-arcpopover-border-color);
  border-top-left-radius: var(--stemline-arcpopover-inner-border-radius);
  border-top-right-radius: var(--stemline-arcpopover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--stemline-arcpopover-body-padding-y) var(--stemline-arcpopover-body-padding-x);
  color: var(--stemline-arcpopover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--stemline-arcspinner-width);
  height: var(--stemline-arcspinner-height);
  vertical-align: var(--stemline-arcspinner-vertical-align);
  border-radius: 50%;
  animation: var(--stemline-arcspinner-animation-speed) linear infinite var(--stemline-arcspinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  --stemline-arcspinner-width: 2rem;
  --stemline-arcspinner-height: 2rem;
  --stemline-arcspinner-vertical-align: -0.125em;
  --stemline-arcspinner-border-width: 0.25em;
  --stemline-arcspinner-animation-speed: 0.75s;
  --stemline-arcspinner-animation-name: spinner-border;
  border: var(--stemline-arcspinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --stemline-arcspinner-width: 1rem;
  --stemline-arcspinner-height: 1rem;
  --stemline-arcspinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --stemline-arcspinner-width: 2rem;
  --stemline-arcspinner-height: 2rem;
  --stemline-arcspinner-vertical-align: -0.125em;
  --stemline-arcspinner-animation-speed: 0.75s;
  --stemline-arcspinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --stemline-arcspinner-width: 1rem;
  --stemline-arcspinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --stemline-arcspinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --stemline-arcoffcanvas-zindex: 1045;
  --stemline-arcoffcanvas-width: 400px;
  --stemline-arcoffcanvas-height: 30vh;
  --stemline-arcoffcanvas-padding-x: 1rem;
  --stemline-arcoffcanvas-padding-y: 1rem;
  --stemline-arcoffcanvas-color: ;
  --stemline-arcoffcanvas-bg: #fff;
  --stemline-arcoffcanvas-border-width: 1px;
  --stemline-arcoffcanvas-border-color: var(--stemline-arcborder-color-translucent);
  --stemline-arcoffcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--stemline-arcoffcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--stemline-arcoffcanvas-color);
    visibility: hidden;
    background-color: var(--stemline-arcoffcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-right: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-left: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-bottom: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-top: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --stemline-arcoffcanvas-height: auto;
    --stemline-arcoffcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--stemline-arcoffcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--stemline-arcoffcanvas-color);
    visibility: hidden;
    background-color: var(--stemline-arcoffcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-right: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-left: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-bottom: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-top: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --stemline-arcoffcanvas-height: auto;
    --stemline-arcoffcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--stemline-arcoffcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--stemline-arcoffcanvas-color);
    visibility: hidden;
    background-color: var(--stemline-arcoffcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-right: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-left: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-bottom: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-top: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --stemline-arcoffcanvas-height: auto;
    --stemline-arcoffcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--stemline-arcoffcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--stemline-arcoffcanvas-color);
    visibility: hidden;
    background-color: var(--stemline-arcoffcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-right: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-left: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-bottom: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-top: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --stemline-arcoffcanvas-height: auto;
    --stemline-arcoffcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--stemline-arcoffcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--stemline-arcoffcanvas-color);
    visibility: hidden;
    background-color: var(--stemline-arcoffcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-right: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--stemline-arcoffcanvas-width);
    border-left: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-bottom: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--stemline-arcoffcanvas-height);
    max-height: 100%;
    border-top: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --stemline-arcoffcanvas-height: auto;
    --stemline-arcoffcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--stemline-arcoffcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--stemline-arcoffcanvas-color);
  visibility: hidden;
  background-color: var(--stemline-arcoffcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--stemline-arcoffcanvas-width);
  border-right: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--stemline-arcoffcanvas-width);
  border-left: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--stemline-arcoffcanvas-height);
  max-height: 100%;
  border-bottom: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--stemline-arcoffcanvas-height);
  max-height: 100%;
  border-top: var(--stemline-arcoffcanvas-border-width) solid var(--stemline-arcoffcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--stemline-arcoffcanvas-padding-y) var(--stemline-arcoffcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--stemline-arcoffcanvas-padding-y) * .5) calc(var(--stemline-arcoffcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--stemline-arcoffcanvas-padding-y));
  margin-right: calc(-.5 * var(--stemline-arcoffcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--stemline-arcoffcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--stemline-arcoffcanvas-padding-y) var(--stemline-arcoffcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--stemline-arcbg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--stemline-arcbg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--stemline-arcbg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--stemline-arcbg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--stemline-arcbg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--stemline-arcbg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--stemline-arcbg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--stemline-arcbg-opacity, 1)) !important;
}

.link-primary {
  color: #0d6efd !important;
}

.link-primary:hover, .link-primary:focus {
  color: #0a58ca !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}

.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--stemline-arcaspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --stemline-arcaspect-ratio: 100%;
}

.ratio-4x3 {
  --stemline-arcaspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --stemline-arcaspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --stemline-arcaspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--stemline-arcborder-width) var(--stemline-arcborder-style) var(--stemline-arcborder-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--stemline-arcborder-width) var(--stemline-arcborder-style) var(--stemline-arcborder-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--stemline-arcborder-width) var(--stemline-arcborder-style) var(--stemline-arcborder-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--stemline-arcborder-width) var(--stemline-arcborder-style) var(--stemline-arcborder-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--stemline-arcborder-width) var(--stemline-arcborder-style) var(--stemline-arcborder-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arcprimary-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-secondary {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arcsecondary-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-success {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arcsuccess-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-info {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arcinfo-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-warning {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arcwarning-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-danger {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arcdanger-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-light {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arclight-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-dark {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arcdark-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-white {
  --stemline-arcborder-opacity: 1;
  border-color: rgba(var(--stemline-arcwhite-rgb), var(--stemline-arcborder-opacity)) !important;
}

.border-1 {
  --stemline-arcborder-width: 1px;
}

.border-2 {
  --stemline-arcborder-width: 2px;
}

.border-3 {
  --stemline-arcborder-width: 3px;
}

.border-4 {
  --stemline-arcborder-width: 4px;
}

.border-5 {
  --stemline-arcborder-width: 5px;
}

.border-opacity-10 {
  --stemline-arcborder-opacity: 0.1;
}

.border-opacity-25 {
  --stemline-arcborder-opacity: 0.25;
}

.border-opacity-50 {
  --stemline-arcborder-opacity: 0.5;
}

.border-opacity-75 {
  --stemline-arcborder-opacity: 0.75;
}

.border-opacity-100 {
  --stemline-arcborder-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--stemline-arcfont-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcprimary-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-secondary {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcsecondary-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-success {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcsuccess-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-info {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcinfo-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-warning {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcwarning-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-danger {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcdanger-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-light {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arclight-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-dark {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcdark-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-black {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcblack-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-white {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcwhite-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-body {
  --stemline-arctext-opacity: 1;
  color: rgba(var(--stemline-arcbody-color-rgb), var(--stemline-arctext-opacity)) !important;
}

.text-muted {
  --stemline-arctext-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --stemline-arctext-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --stemline-arctext-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --stemline-arctext-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --stemline-arctext-opacity: 0.25;
}

.text-opacity-50 {
  --stemline-arctext-opacity: 0.5;
}

.text-opacity-75 {
  --stemline-arctext-opacity: 0.75;
}

.text-opacity-100 {
  --stemline-arctext-opacity: 1;
}

.bg-primary {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcprimary-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-secondary {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcsecondary-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-success {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcsuccess-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-info {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcinfo-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-warning {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcwarning-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-danger {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcdanger-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-light {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arclight-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-dark {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcdark-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-black {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcblack-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-white {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcwhite-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-body {
  --stemline-arcbg-opacity: 1;
  background-color: rgba(var(--stemline-arcbody-bg-rgb), var(--stemline-arcbg-opacity)) !important;
}

.bg-transparent {
  --stemline-arcbg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --stemline-arcbg-opacity: 0.1;
}

.bg-opacity-25 {
  --stemline-arcbg-opacity: 0.25;
}

.bg-opacity-50 {
  --stemline-arcbg-opacity: 0.5;
}

.bg-opacity-75 {
  --stemline-arcbg-opacity: 0.75;
}

.bg-opacity-100 {
  --stemline-arcbg-opacity: 1;
}

.bg-gradient {
  background-image: var(--stemline-arcgradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--stemline-arcborder-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--stemline-arcborder-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--stemline-arcborder-radius) !important;
}

.rounded-3 {
  border-radius: var(--stemline-arcborder-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--stemline-arcborder-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--stemline-arcborder-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--stemline-arcborder-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--stemline-arcborder-radius) !important;
  border-top-right-radius: var(--stemline-arcborder-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--stemline-arcborder-radius) !important;
  border-bottom-right-radius: var(--stemline-arcborder-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--stemline-arcborder-radius) !important;
  border-bottom-left-radius: var(--stemline-arcborder-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--stemline-arcborder-radius) !important;
  border-top-left-radius: var(--stemline-arcborder-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

.no-scroll {
  overflow-y: hidden;
}

@media (min-width: 1240px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1110px;
  }
}

.svg-icon {
  pointer-events: none;
  vertical-align: middle;
}

svg {
  display: block;
  height: 100%;
  width: 100%;
}

svg path {
  fill: currentColor;
}

sup {
  top: -0.7em;
  font-size: 0.5em;
}

h1, .h1, .h1 {
  font-family: Raleway;
  font-size: 2.5rem;
  line-height: 2.75rem;
  letter-spacing: 0;
}

h2, .h2, .h2 {
  font-family: Raleway;
  font-size: 2.25rem;
  line-height: auto;
  letter-spacing: 0;
}

h3, .h3, .h3 {
  font-family: Raleway;
  font-size: 1.25rem;
  line-height: auto;
  letter-spacing: 0;
}

h4, .h4, .h4 {
  font-family: Raleway;
  font-size: 1.25rem;
  line-height: auto;
  letter-spacing: 0;
}

.body-1 {
  font-family: Raleway;
  font-size: 1.25rem;
  line-height: auto;
  letter-spacing: 0;
}

.body-2 {
  font-family: Raleway;
  font-size: 1.125rem;
  line-height: auto;
  letter-spacing: 0;
}

.body-3 {
  font-family: Raleway;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0;
}

.body-4 {
  font-family: Raleway;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
}

.body-5 {
  font-family: Raleway;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0;
}

.nowrap {
  white-space: nowrap;
}

@media (min-width: 375px) {
  h1, .h1, .h1 {
    font-family: Raleway;
    font-size: 3.5rem;
    line-height: 3.75rem;
    letter-spacing: 0;
  }
  h2, .h2, .h2 {
    font-family: Raleway;
    font-size: 2rem;
    line-height: auto;
    letter-spacing: 0;
  }
  h3, .h3, .h3 {
    font-family: Raleway;
    font-size: 2.25rem;
    line-height: auto;
    letter-spacing: 0;
  }
  h4, .h4, .h4 {
    font-family: Raleway;
    font-size: 1.25rem;
    line-height: auto;
    letter-spacing: 0;
  }
  .body-1 {
    font-family: Raleway;
    font-size: 1.375rem;
    line-height: auto;
    letter-spacing: 0;
  }
  .body-2 {
    font-family: Raleway;
    font-size: 1.25rem;
    line-height: auto;
    letter-spacing: 0;
  }
  .body-3 {
    font-family: Raleway;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
  }
  .body-4 {
    font-family: Raleway;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0;
  }
  .body-5 {
    font-family: Raleway;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0;
  }
}

.stemline-arc-c-accordion .accordion-button {
  box-shadow: none;
  color: #537175;
  font-family: "Inter";
  font-weight: bold;
}

@media (max-width: 767px) {
  .stemline-arc-c-accordion .accordion-item {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .stemline-arc-c-accordion .accordion-header {
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: .5rem;
    text-align: left;
  }
  .stemline-arc-c-accordion .accordion-body {
    padding: 0;
  }
  .stemline-arc-c-accordion .accordion-button {
    font-size: 1.125rem;
  }
  .stemline-arc-c-accordion .accordion-button.collapsed::after {
    background-image: url("/images/svg/icon-plus.svg");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .stemline-arc-c-accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: #3274b5;
  }
  .stemline-arc-c-accordion .accordion-button:not(.collapsed)::after {
    background-image: url("/images/svg/icon-minus.svg");
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
  }
  .stemline-arc-c-accordion .accordion-button:focus {
    box-shadow: none;
  }
}

.stemline-arc-c-tab {
  border: 0;
}

.stemline-arc-c-tab .nav-item {
  width: 50%;
}

.stemline-arc-c-tab .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: 0;
  color: #537175;
  font-family: "Inter";
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.1875rem;
  text-align: center;
  width: 100%;
}

.stemline-arc-c-tab .nav-link.active {
  color: #3274b5;
}

.stemline-arc-c-tab .nav-link:not(.active) {
  background: #f1f6f3;
  box-shadow: inset 0.25rem -0.25rem 0.875rem rgba(0, 0, 0, 0.1);
}

.stemline-arc-c-isi-body-content {
  color: #606060;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: 400;
}

.stemline-arc-c-isi-body-content .astral {
  color: #3274b5;
}

.stemline-arc-c-isi-body-content .sublist {
  padding-left: 5rem;
}

.stemline-arc-c-isi-body-content .ms-2rem {
  margin-left: 2rem;
}

.stemline-arc-c-isi-body-content .level2 {
  padding-left: 8rem;
}

.stemline-arc-c-isi-body-content ul {
  padding-left: 1.5rem;
}

.stemline-arc-c-isi-body-content ul li {
  line-height: 1.5rem;
}

.stemline-arc-c-isi-body-content ul li b {
  line-height: 1.5rem;
}

.stemline-arc-c-isi-body-content a {
  color: inherit;
}

.stemline-arc-c-isi-body-content a.phone {
  text-decoration: none;
}

@media (min-width: 376px) {
  .stemline-arc-c-isi-body-content .footnote {
    font-weight: bold;
    margin: 2.5rem 0 2.75rem;
  }
}

.stemline-arc-c-modal .modal-header {
  border-bottom: none;
}

.stemline-arc-c-modal__container {
  border-radius: 1.25rem;
  display: flex;
  min-height: 24.8125rem;
  min-width: 17rem;
}

.stemline-arc-c-modal__title {
  color: #3274b5;
  display: flex;
  font-family: "Raleway";
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  left: 0.125rem;
  line-height: 1.75rem;
  margin-top: 1rem;
  position: relative;
}

.stemline-arc-c-modal__btns-content__first-btn {
  background-color: #96bd9f;
  border-radius: 1.0625rem 1.0625rem 1.3125rem 1.3125rem/2.5rem 2.5rem 2.875rem 2.875rem;
  color: #000;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  left: 1.125rem;
  line-height: 1.3125rem;
  margin-bottom: 0.931875rem;
  margin-top: -0.25rem;
  min-height: 5.630625rem;
  min-width: 14.5rem;
  padding-bottom: 0.625rem;
  padding-top: 0.75rem;
  position: relative;
  text-align: left;
}

.stemline-arc-c-modal__btns-content__first-btn span {
  left: 0.8125rem;
  position: relative;
}

.stemline-arc-c-modal__btns-content__first-btn img {
  display: flex;
  left: 87%;
  position: absolute;
  top: 40%;
}

.stemline-arc-c-modal__btns-content__second-btn {
  background-color: #fff;
  border-color: #3274b5;
  border-radius: 17px 17px 21px 21px / 40px 40px 46px 46px;
  color: #3274b5;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  left: 1.1875rem;
  line-height: 1.3125rem;
  margin-bottom: 2.431875rem;
  min-height: 5.630625rem;
  min-width: 14.5rem;
  padding-bottom: 0.625rem;
  padding-top: 0.75rem;
  position: relative;
  text-align: left;
}

.stemline-arc-c-modal__btns-content__second-btn span {
  left: 0.6875rem;
  position: relative;
}

.stemline-arc-c-modal__btns-content__second-btn img {
  display: flex;
  left: 86.4%;
  position: absolute;
  top: 40%;
}

@media (min-width: 376px) and (max-width: 1023px) {
  .stemline-arc-c-modal__container {
    min-height: 22.3125rem;
    width: 95%;
  }
  .stemline-arc-c-modal__btns-content {
    display: flex;
    position: relative;
  }
  .stemline-arc-c-modal__btns-content__first-btn {
    left: 1.125rem;
    margin-bottom: 0.931875rem;
    margin-top: 0.5rem;
    min-height: 3.125rem;
    min-width: 20rem;
    padding-bottom: 0.9375rem;
    padding-top: 0.75rem;
  }
  .stemline-arc-c-modal__btns-content__first-btn span {
    left: 0.625rem;
    position: relative;
  }
  .stemline-arc-c-modal__btns-content__first-btn img {
    display: flex;
    left: 92%;
    position: absolute;
    top: 33%;
  }
  .stemline-arc-c-modal__btns-content__second-btn {
    left: 1.1875rem;
    margin-bottom: 0.625rem;
    min-height: 3.125rem;
    min-width: 20rem;
    padding-bottom: 0.9375rem;
    padding-top: 0.875rem;
  }
  .stemline-arc-c-modal__btns-content__second-btn span {
    left: 0.625rem;
    position: relative;
  }
  .stemline-arc-c-modal__btns-content__second-btn img {
    display: flex;
    left: 92%;
    position: absolute;
    top: 33%;
  }
}

@media (min-width: 769px) {
  .stemline-arc-c-modal__container {
    max-width: 45.625rem;
    min-height: 22.3125rem;
    min-width: 37.5rem;
  }
  .stemline-arc-c-modal__title {
    font-size: 2.25rem;
    left: 6%;
    line-height: 2.625rem;
    margin-top: 2.8125rem;
  }
  .stemline-arc-c-modal__btns-content {
    display: flex;
    left: 4.8%;
    position: relative;
  }
  .stemline-arc-c-modal__btns-content__first-btn {
    left: 1.125rem;
    margin-bottom: 0.931875rem;
    margin-top: 0.5rem;
    max-height: 3.125rem;
    max-width: 27.85125rem;
    min-height: 3.125rem;
    min-width: 27.6875rem;
    padding-bottom: 0.9375rem;
    padding-top: 0.75rem;
  }
  .stemline-arc-c-modal__btns-content__first-btn span {
    left: 0.625rem;
    position: relative;
  }
  .stemline-arc-c-modal__btns-content__first-btn img {
    display: flex;
    left: 92%;
    position: absolute;
    top: 33%;
  }
  .stemline-arc-c-modal__btns-content__second-btn {
    left: 1.1875rem;
    margin-bottom: 3.9375rem;
    min-height: 3.125rem;
    min-width: 27.6875rem;
    padding-bottom: 0.9375rem;
  }
  .stemline-arc-c-modal__btns-content__second-btn span {
    left: 0.625rem;
    position: relative;
  }
  .stemline-arc-c-modal__btns-content__second-btn img {
    display: flex;
    left: 92%;
    position: absolute;
    top: 33%;
  }
}

.stemline-arc-m-homepage-hero {
  background: #537175;
  position: relative;
}

.stemline-arc-m-homepage-hero__desktop-banner {
  display: none;
}

.stemline-arc-m-homepage-hero__mobile-banner {
  width: 100%;
}

.stemline-arc-m-homepage-hero__text-container {
  padding: 2.5rem 0.5rem 2.25rem;
}

.stemline-arc-m-homepage-hero__headline {
  color: #fff;
  font-family: "Raleway";
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  margin-bottom: 1.25rem;
}

.stemline-arc-m-homepage-hero__headline span {
  color: #cddb6f;
}

.stemline-arc-m-homepage-hero__text {
  color: #fff;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.875rem;
}

.stemline-arc-m-homepage-hero__action-text {
  color: #cddb6f;
  font-family: "Raleway";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.stemline-arc-m-homepage-hero__arrow {
  margin-left: 0.9375rem;
  width: 1.9375rem;
}

@media (min-width: 769px) {
  .stemline-arc-m-homepage-hero {
    background-image: url(/images/homepage/hero-section.png);
    background-position: center;
    background-size: cover;
  }
  .stemline-arc-m-homepage-hero__desktop-banner {
    display: block;
    width: 100%;
  }
  .stemline-arc-m-homepage-hero__mobile-banner {
    display: none;
  }
  .stemline-arc-m-homepage-hero__text-container {
    padding-top: 2.25rem;
  }
  .stemline-arc-m-homepage-hero__headline {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 3.75rem;
    width: 39.375rem;
  }
  .stemline-arc-m-homepage-hero__text {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.03125rem;
    line-height: 1.625rem;
    margin-bottom: 3.125rem;
    width: 29.0625rem;
  }
  .stemline-arc-m-homepage-hero__action-text {
    font-size: 1.375rem;
    letter-spacing: 0.03125rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1281px) {
  .stemline-arc-m-homepage-hero__text-container {
    padding: 5rem 0.5rem 5rem;
  }
}

.stemline-arc-m-internal-hero {
  margin-bottom: 6.25rem;
  position: relative;
}

.stemline-arc-m-internal-hero__blue {
  background-color: #3274b5;
}

.stemline-arc-m-internal-hero__green {
  background-color: #537175;
}

.stemline-arc-m-internal-hero__image {
  bottom: -6.5625rem;
  max-width: 17.5625rem;
  position: absolute;
  right: 0;
}

.stemline-arc-m-internal-hero__image-small {
  width: 12.75rem;
}

.stemline-arc-m-internal-hero__text-container {
  padding: 1.8125rem 0 7.3125rem;
}

.stemline-arc-m-internal-hero__headline {
  color: #fff;
  font-family: "Raleway";
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  margin-bottom: 1.125rem;
}

.stemline-arc-m-internal-hero__text {
  color: #fff;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.stemline-arc-m-internal-hero__text-2 {
  color: #fff;
  font-family: "Raleway";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4375rem;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .stemline-arc-m-internal-hero .stemline-arc-m-button {
    height: 4.375rem;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .stemline-arc-m-internal-hero {
    margin-bottom: 3.5rem;
  }
  .stemline-arc-m-internal-hero__image {
    bottom: -3.5rem;
    position: absolute;
    right: 0;
  }
  .stemline-arc-m-internal-hero__text-container {
    padding-bottom: 3.5625rem;
    padding-top: 3.5rem;
  }
  .stemline-arc-m-internal-hero__text-container .stemline-arc-m-button {
    max-width: 20.1875rem;
  }
  .stemline-arc-m-internal-hero__headline {
    font-size: 3.5rem;
    line-height: 3.75rem;
    margin-bottom: 1.1875rem;
    width: 39.375rem;
  }
  .stemline-arc-m-internal-hero__text {
    font-size: 1.25rem;
    letter-spacing: -0.00625rem;
    line-height: 1.625rem;
    margin-bottom: 1.5625rem;
    max-width: 30rem;
    width: 100%;
  }
  .stemline-arc-m-internal-hero__text-2 {
    font-size: 1.375rem;
    font-weight: 800;
    line-height: 1.625rem;
    margin-bottom: 1.0625rem;
    width: 31.25rem;
  }
  .stemline-arc-m-internal-hero__image-small {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .stemline-arc-m-internal-hero__image {
    max-width: 33.6875rem;
  }
  .stemline-arc-m-internal-hero__text {
    max-width: 34.375rem;
  }
  .stemline-arc-m-internal-hero__image-small {
    max-width: 33.6875rem;
  }
}

.stemline-arc-m-simple-hero {
  background-color: #e6f1f8;
}

.stemline-arc-m-simple-hero__green {
  background-color: #f1f6f3;
}

.stemline-arc-m-simple-hero__headline {
  color: #3274b5;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  margin-bottom: 1.25rem;
}

.stemline-arc-m-simple-hero__text {
  color: #606060;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

@media (max-width: 767px) {
  .stemline-arc-m-simple-hero {
    padding: 1.8125rem 0 2.5625rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .stemline-arc-m-simple-hero__text-container {
    letter-spacing: 0.00625rem;
    padding-bottom: 3rem;
    padding-top: 3.4375rem;
  }
}

@media (min-width: 769px) {
  .stemline-arc-m-simple-hero__text-container {
    letter-spacing: 0.00625rem;
    padding-bottom: 3rem;
    padding-top: 3.4375rem;
  }
  .stemline-arc-m-simple-hero__headline {
    font-size: 3.5rem;
    line-height: 3.75rem;
    margin-bottom: 1.3125rem;
  }
  .stemline-arc-m-simple-hero__text {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.625rem;
  }
}

@media (min-width: 1025px) {
  .stemline-arc-m-simple-hero__text {
    width: 63.75rem;
  }
}

.stemline-arc-m-button {
  align-items: center;
  border-radius: 17px 17px 21px 21px / 40px 40px 46px 46px;
  border: 0;
  display: flex;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 700;
  justify-content: space-between;
  line-height: 1.3125rem;
  min-width: 10.0625rem;
  padding: 0.875rem 1.5625rem;
  text-align: left;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.stemline-arc-m-button__green {
  background-color: #96bd9f;
  color: #000;
}

.stemline-arc-m-button__green:hover {
  background-color: #6B9A76;
  color: #000;
}

.stemline-arc-m-button__green:focus {
  border: double 1px #96bd9f;
}

.stemline-arc-m-button__blue {
  background-color: #3274b5;
  color: #fff;
}

.stemline-arc-m-button__blue:hover {
  background-color: #204D7A;
  color: #fff;
}

.stemline-arc-m-button__blue:focus {
  border: double 1px #3274b5;
}

.stemline-arc-m-button__blue-outline {
  background-color: #fff;
  border: 1px solid #3274b5;
  color: #3274b5;
}

.stemline-arc-m-button__blue-outline:hover {
  color: #3274b5;
}

.stemline-arc-m-button__blue-outline:focus {
  border: solid 2px #3274b5;
}

.stemline-arc-m-button__arrow {
  height: 1.5rem;
  margin-left: 0.625rem;
  object-fit: contain;
  width: 1.5rem;
}

@media (min-width: 769px) {
  .stemline-arc-m-button {
    display: block;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.3125rem;
    padding: 0.8125rem 1.5625rem;
    position: relative;
  }
  .stemline-arc-m-button br {
    display: none;
  }
}

.stemline-arc-m-foot-notes {
  margin-bottom: 3rem;
}

.stemline-arc-m-foot-notes p {
  color: #606060;
  font-family: "Raleway";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 0.9375rem;
  margin-bottom: 0.3125rem;
}

.stemline-arc-m-foot-notes p sup {
  font-size: 0.625rem;
  line-height: 0.4375rem;
  margin-left: -0.375rem;
  vertical-align: bottom;
}

.stemline-arc-m-foot-notes p span {
  margin-left: -0.375rem;
}

.stemline-arc-m-foot-notes p strong {
  display: contents;
}

.stemline-arc-m-foot-notes p a {
  color: inherit;
}

@media (min-width: 769px) {
  .stemline-arc-m-foot-notes {
    margin-bottom: 5rem;
  }
}

.stemline-arc-m-images-text__text-content {
  padding-bottom: 2.5rem;
  padding-right: 0.75rem;
  padding-top: 2.5rem;
}

.stemline-arc-m-images-text__headline {
  color: #3274b5;
  font-family: "Raleway";
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0.9375rem;
}

.stemline-arc-m-images-text__items__item img {
  margin-bottom: 1.25rem;
}

.stemline-arc-m-images-text__item__content__text {
  color: #606060;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
}

.stemline-arc-m-images-text__item__content__points {
  margin-bottom: 1.875rem;
  padding-left: 1.25rem;
}

.stemline-arc-m-images-text__item__content__points li {
  color: #606060;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
}

.stemline-arc-m-images-text__item__content__points li a {
  color: #606060;
}

@media (min-width: 769px) {
  .stemline-arc-m-images-text__headline {
    font-size: 2.25rem;
  }
  .stemline-arc-m-images-text__items__item {
    align-items: center;
    display: flex;
    margin-bottom: 1.25rem;
  }
  .stemline-arc-m-images-text__items__item img {
    margin-bottom: 0;
    margin-right: 1.25rem;
  }
}

.stemline-arc-m-simple-layout {
  background-color: #f1f6f3;
  padding: 3.5rem 0 3.125rem;
}

.stemline-arc-m-simple-layout__title {
  color: #3274b5;
  display: inline-block;
  font-family: "Raleway";
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 2.75rem;
  padding-bottom: 1.6875rem;
  width: 100%;
}

.stemline-arc-m-simple-layout__column h2, .stemline-arc-m-simple-layout__column .h2 {
  color: #606060;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0.875rem;
}

.stemline-arc-m-simple-layout__column h2 + ul, .stemline-arc-m-simple-layout__column .h2 + ul {
  margin-bottom: -3px;
}

.stemline-arc-m-simple-layout__column ul {
  list-style-type: disc;
  padding-left: 23px;
}

.stemline-arc-m-simple-layout__column ul li {
  line-height: 1.4375rem;
}

.stemline-arc-m-simple-layout__column ul li:not(:first-child) {
  margin-left: 25px;
}

.stemline-arc-m-simple-layout__column ul a {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.stemline-arc-m-simple-layout__column p {
  color: #606060;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.stemline-arc-m-simple-layout__column a,
.stemline-arc-m-simple-layout__column a:visited,
.stemline-arc-m-simple-layout__column a:hover {
  color: #606060;
  font-family: "Raleway";
}

@media (max-width: 767px) {
  .stemline-arc-m-simple-layout {
    padding: 3.5rem 0 4.125rem;
  }
  .stemline-arc-m-simple-layout__column:first-child {
    margin-bottom: 3.1875rem;
  }
}

@media (min-width: 768px) {
  .stemline-arc-m-simple-layout {
    padding: 3.5rem 0 4.5rem;
  }
  .stemline-arc-m-simple-layout__title {
    font-size: 3.5rem;
    line-height: 3.75rem;
    padding-bottom: 0.75rem;
  }
  .stemline-arc-m-simple-layout__column h2, .stemline-arc-m-simple-layout__column .h2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.625rem;
    margin-bottom: 0.6875rem;
  }
  .stemline-arc-m-simple-layout__column ul a {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  .stemline-arc-m-simple-layout__column p {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}

.stemline-arc-m-simple-text-1__text-content {
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}

.stemline-arc-m-simple-text-1__headline {
  color: #3274b5;
  font-family: "Raleway";
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0.9375rem;
}

.stemline-arc-m-simple-text-1__steps {
  list-style: none;
  margin-bottom: 5rem;
  padding-left: 0;
}

.stemline-arc-m-simple-text-1__steps li {
  align-items: flex-start;
  color: #606060;
  display: flex;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
}

.stemline-arc-m-simple-text-1__steps li strong {
  font-weight: bold;
}

.stemline-arc-m-simple-text-1__steps li a {
  color: #606060;
  text-decoration: none;
}

.stemline-arc-m-simple-text-1__steps li a span {
  text-decoration: underline;
}

.stemline-arc-m-simple-text-1__box-text {
  background-color: #f1f6f3;
  border-radius: 1.25rem;
  margin-left: auto;
  max-width: 21.875rem;
  padding: 3.625rem 2.25rem 1.5rem 1.6875rem;
  position: relative;
}

.stemline-arc-m-simple-text-1__box-text__icon {
  left: 20px;
  position: absolute;
  top: -40px;
}

.stemline-arc-m-simple-text-1__box-text__text {
  color: #606060;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375rem;
  margin-bottom: 0;
}

.stemline-arc-m-simple-text-1__box-text__text strong {
  font-weight: bold;
}

@media (min-width: 769px) {
  .stemline-arc-m-simple-text-1__headline {
    font-size: 2.25rem;
  }
  .stemline-arc-m-simple-text-1__text-content {
    padding-right: 0.75rem;
  }
  .stemline-arc-m-simple-text-1__steps {
    margin-bottom: 0;
  }
  .stemline-arc-m-simple-text-1__steps li {
    align-items: center;
  }
}

.stemline-arc-m-simple-text-2__text-content {
  padding-right: 0.75rem;
  padding-top: 2.5rem;
}

.stemline-arc-m-simple-text-2__headline {
  color: #3274b5;
  font-family: "Raleway";
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0.9375rem;
}

.stemline-arc-m-simple-text-2__content {
  margin-bottom: 2.5rem;
}

.stemline-arc-m-simple-text-2__content__headline {
  color: #606060;
  font-family: "Raleway";
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
}

.stemline-arc-m-simple-text-2__content__points {
  margin-bottom: 0;
  padding-left: 1.25rem;
}

.stemline-arc-m-simple-text-2__content__points li {
  color: #606060;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
}

.stemline-arc-m-simple-text-2__content__points li strong {
  font-weight: bold;
}

.stemline-arc-m-simple-text-2__resources {
  background-color: #f1f6f3;
  border-radius: 1.25rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  max-width: 21.875rem;
  padding: 1.5rem 2rem 1.5rem 1.6875rem;
}

.stemline-arc-m-simple-text-2__resources__headline {
  color: #3274b5;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-bottom: 0.9375rem;
}

.stemline-arc-m-simple-text-2__resources__points {
  list-style: none;
  padding-left: 0;
}

.stemline-arc-m-simple-text-2__resources__points li {
  margin-bottom: 0.625rem;
}

.stemline-arc-m-simple-text-2__resources__points li a {
  color: #606060;
  display: flex;
  font-family: "Raleway";
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
  text-decoration: none;
}

.stemline-arc-m-simple-text-2__resources__points li a img {
  margin-right: 0.5rem;
}

.stemline-arc-m-simple-text-2__bottom {
  margin-bottom: 2.8125rem;
}

.stemline-arc-m-simple-text-2__bottom-text {
  color: #606060;
  font-family: "Raleway";
  font-size: 0.8125rem;
  font-weight: 300;
  margin-bottom: 0.3125rem;
}

.stemline-arc-m-simple-text-2__bottom-text strong {
  font-weight: bold;
}

@media (min-width: 769px) {
  .stemline-arc-m-simple-text-2__headline {
    font-size: 2.25rem;
  }
  .stemline-arc-m-simple-text-2__resources {
    margin-bottom: 0;
  }
}

.stemline-arc-m-simple-text-3 {
  font-family: "Raleway";
}

.stemline-arc-m-simple-text-3__text-content {
  padding-right: 0.75rem;
  padding-top: 2.25rem;
}

.stemline-arc-m-simple-text-3__headline {
  color: #3274b5;
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0.9375rem;
}

.stemline-arc-m-simple-text-3__text {
  color: #606060;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin-bottom: 2.5rem;
}

.stemline-arc-m-simple-text-3__bottom-text {
  color: #606060;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375rem;
  margin-bottom: 1.25rem;
  margin-top: 0.6875rem;
}

.stemline-arc-m-simple-text-3__bottom-text strong {
  font-weight: bold;
}

.stemline-arc-m-simple-text-3__bottom-text a {
  color: #606060;
}

.stemline-arc-m-simple-text-3__item {
  margin-bottom: 2.625rem;
}

.stemline-arc-m-simple-text-3__item__image {
  margin-bottom: 0.75rem;
}

.stemline-arc-m-simple-text-3__item__content__headline {
  color: #3274b5;
  font-family: "Raleway";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
}

.stemline-arc-m-simple-text-3__item__content__text {
  color: #606060;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375rem;
  margin-bottom: 1.25rem;
}

.stemline-arc-m-simple-text-3__item__content__text strong {
  font-weight: bold;
}

.stemline-arc-m-simple-text-3__item__content__text-link {
  color: #606060;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375rem;
}

.stemline-arc-m-simple-text-3__item__content__text-link strong {
  font-weight: bold;
}

.stemline-arc-m-simple-text-3__item__content__text-link a {
  color: #606060;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .stemline-arc-m-simple-text-3__text-content {
    padding-right: 0;
  }
  .stemline-arc-m-simple-text-3__headline {
    font-size: 2.25rem;
    margin-bottom: 1.125rem;
    margin-top: 0.375rem;
  }
  .stemline-arc-m-simple-text-3__item__image {
    align-self: flex-start;
    margin-right: 0.9375rem;
  }
  .stemline-arc-m-simple-text-3__item__content__headline {
    margin-top: 0.375rem;
  }
  .stemline-arc-m-simple-text-3__bottom-text {
    margin-bottom: 3.1875rem;
  }
}

.stemline-arc-m-simple-text-4__text-content {
  padding-bottom: 2.5rem;
  padding-right: 0.75rem;
  padding-top: 1.875rem;
}

.stemline-arc-m-simple-text-4__headline {
  color: #3274b5;
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1.25rem;
}

.stemline-arc-m-simple-text-4__text {
  color: #606060;
  font-family: "Raleway";
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.8125rem;
  line-height: 2.0625rem;
}

.stemline-arc-m-simple-text-4__steps-container {
  display: flex;
}

.stemline-arc-m-simple-text-4__steps-container img {
  align-self: flex-start;
}

.stemline-arc-m-simple-text-4__steps-text {
  color: #3274b5;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stemline-arc-m-simple-text-4__steps {
  padding-left: 1.25rem;
}

.stemline-arc-m-simple-text-4__steps li {
  color: #606060;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
}

.stemline-arc-m-simple-text-4__bottom {
  margin-bottom: 4.375rem;
}

.stemline-arc-m-simple-text-4__bottom-text {
  color: #606060;
  font-family: "Raleway";
  font-size: 0.8125rem;
  font-weight: 300;
  margin-bottom: 0;
}

.stemline-arc-m-simple-text-4__bottom-text sup,
.stemline-arc-m-simple-text-4__bottom-text span {
  margin-left: -0.1875rem;
}

@media (min-width: 769px) {
  .stemline-arc-m-simple-text-4__headline {
    font-size: 2.25rem;
    margin-bottom: 1.25rem;
  }
  .stemline-arc-m-simple-text-4__text {
    font-size: 1.75rem;
    margin-bottom: 1.25rem;
  }
  .stemline-arc-m-simple-text-4__steps-container {
    padding-right: 2.0625rem;
  }
  .stemline-arc-m-simple-text-4__steps {
    margin-bottom: 0;
  }
  .stemline-arc-m-simple-text-4__bottom {
    margin-bottom: 3.75rem;
  }
}

.stemline-arc-m-footer {
  display: grid;
  font-family: "Raleway";
}

.stemline-arc-m-footer__text {
  color: #606060;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.880625rem;
  margin-bottom: 1.625rem;
  text-align: left;
}

.stemline-arc-m-footer__bg-saltpan {
  background: #f1f6f3;
}

.stemline-arc-m-footer__bg-link-water {
  background: #e6f1f8;
}

.stemline-arc-m-footer__links {
  text-align: left;
}

.stemline-arc-m-footer__links a {
  color: #3274b5;
}

.stemline-arc-m-footer img {
  margin-bottom: 2.25rem;
  margin-top: 3rem;
}

.stemline-arc-m-footer ul {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0275rem;
  list-style: none;
  margin-bottom: 1.25rem;
}

.stemline-arc-m-footer ul li {
  white-space: nowrap;
}

.stemline-arc-m-footer ul li a {
  text-decoration: none;
}

.stemline-arc-m-footer ul li span {
  color: #606060;
  margin-left: 0.5625rem;
  margin-right: 0.5625rem;
}

.stemline-arc-m-footer ul :hover a {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .stemline-arc-m-footer__text {
    margin-bottom: 2.125rem;
    text-align: right;
  }
  .stemline-arc-m-footer__links {
    text-align: right;
  }
  .stemline-arc-m-footer img {
    margin-top: 3rem;
  }
  .stemline-arc-m-footer ul {
    display: block;
    margin-top: 3rem;
  }
  .stemline-arc-m-footer ul li {
    display: inline;
  }
  .stemline-arc-m-footer ul li a {
    text-decoration: none;
  }
}

@media (min-width: 376px) and (max-width: 1023px) {
  .stemline-arc-m-footer ul {
    display: block;
    margin-top: 3rem;
  }
  .stemline-arc-m-footer ul li {
    display: inline;
  }
  .stemline-arc-m-footer ul li span {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.stemline-arc-m-header {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
}

.stemline-arc-m-header--minimal {
  padding: 2.0625rem 0 1.5rem;
  background-color: #fff;
}

.stemline-arc-m-header--minimal__logo {
  display: block;
  width: 12.4375rem;
  height: 3.8125rem;
}

.stemline-arc-m-header--minimal__logo img {
  width: 12.4375rem;
  height: 3.8125rem;
  object-fit: contain;
}

.stemline-arc-m-header--minimal.centered a {
  text-align: center;
}

.stemline-arc-m-header--push {
  width: 100%;
  padding-bottom: 4.625rem;
}

.stemline-arc-m-header--push--minimal {
  padding-bottom: 7.375rem;
}

@media (min-width: 1024px) {
  .stemline-arc-m-header--minimal.centered a {
    text-align: left;
  }
  .stemline-arc-m-header--push {
    padding-bottom: 11.8125rem;
  }
}

.stemline-arc-m-nav {
  background-color: #fff;
  height: 4.625rem;
  position: relative;
  z-index: 1000;
}

.stemline-arc-m-nav__container {
  padding-inline: 1.375rem;
}

.stemline-arc-m-nav__container > div {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.stemline-arc-m-nav__logo {
  display: block;
  height: 2.5625rem;
  margin: 0;
  padding-inline: 0 0.625rem;
  width: 6.875rem;
}

.stemline-arc-m-nav__logo:first-of-type {
  border-right: 1px solid #EDEDED;
}

.stemline-arc-m-nav__logo:last-of-type {
  padding-inline: 0.625rem 0;
}

.stemline-arc-m-nav__logo img {
  display: block;
  height: 2.5625rem;
  width: 6.25rem;
}

.stemline-arc-m-nav__subnav_buttons {
  bottom: 10.3125rem;
  left: 0;
  padding-inline: 1.5rem;
  position: absolute;
  width: 100%;
}

.stemline-arc-m-nav__subnav_buttons.patient {
  bottom: 7.8125rem;
}

.stemline-arc-m-nav__subnav_buttons__button {
  display: inline-block;
  font-family: "Raleway";
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
  width: 100%;
}

.stemline-arc-m-nav__subnav_buttons__button--blue {
  color: #3274b5;
  text-decoration: none;
}

.stemline-arc-m-nav__subnav_buttons__button--blue:visited {
  color: #3274b5;
  text-decoration: none;
}

.stemline-arc-m-nav__subnav_buttons__button--blue:hover {
  color: #3274b5;
  text-decoration: none;
}

.stemline-arc-m-nav__subnav_buttons__button--green {
  color: #537175;
  text-decoration: none;
}

.stemline-arc-m-nav__subnav_buttons__button--green:visited {
  color: #537175;
  text-decoration: none;
}

.stemline-arc-m-nav__subnav_buttons__button--green:hover {
  color: #537175;
  text-decoration: none;
}

.stemline-arc-m-nav__subnav_buttons__text {
  color: #606060;
  display: inline-block;
  font-family: "Raleway";
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
  width: 100%;
}

.stemline-arc-m-nav__subnav_links {
  background-color: #E6E6E6;
  bottom: 2.5625rem;
  left: 0;
  padding: 1.0625rem 1.5rem;
  position: absolute;
  width: 100%;
}

.stemline-arc-m-nav__subnav_links.patient {
  bottom: 0;
}

.stemline-arc-m-nav__subnav_links a {
  color: #606060;
  display: block;
  font-family: "Raleway";
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
  position: relative;
  text-decoration: none;
  width: max-content;
}

.stemline-arc-m-nav__subnav_links a:visited {
  text-decoration: none;
  color: #606060;
}

.stemline-arc-m-nav__subnav_links a:hover {
  text-decoration: none;
  color: #606060;
}

.stemline-arc-m-nav__subnav_links a:focus {
  text-decoration: none;
  color: #606060;
}

.stemline-arc-m-nav__subnav_links a:last-child {
  font-weight: 700;
  margin-bottom: 0;
}

.stemline-arc-m-nav__subnav_links a:last-child::after {
  background-image: url("/images/global/svgs/link-square.svg");
  background-position: center;
  background-size: contain;
  content: '';
  display: block;
  height: 0.6875rem;
  position: absolute;
  right: -1.125rem;
  top: 0.25rem;
  width: 0.6875rem;
}

.stemline-arc-m-nav .navbar-toggler {
  border: 0;
  box-shadow: inherit;
}

.stemline-arc-m-nav .navbar-toggler:focus {
  border: 0;
  box-shadow: inherit;
}

.stemline-arc-m-nav .navbar-toggler .icon-bar {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  background-color: #3274b5;
  border-radius: 0.0625rem;
  display: block;
  height: 0.125rem;
  margin-top: 0.25rem;
  width: 1.375rem;
}

.stemline-arc-m-nav .navbar-toggler .icon-bar:nth-of-type(1) {
  -ms-transform-origin: 10% 10%;
  -ms-transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  -webkit-transform: rotate(45deg);
  transform-origin: 10% 10%;
  transform: rotate(45deg);
}

.stemline-arc-m-nav .navbar-toggler .icon-bar:nth-of-type(2) {
  filter: alpha(opacity=0);
  opacity: 0;
}

.stemline-arc-m-nav .navbar-toggler .icon-bar:nth-of-type(3) {
  -ms-transform-origin: 10% 90%;
  -ms-transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  -webkit-transform: rotate(-45deg);
  transform-origin: 10% 90%;
  transform: rotate(-45deg);
}

.stemline-arc-m-nav .navbar-toggler.collapsed .icon-bar:nth-of-type(1) {
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.stemline-arc-m-nav .navbar-toggler.collapsed .icon-bar:nth-of-type(2) {
  filter: alpha(opacity=100);
  opacity: 1;
}

.stemline-arc-m-nav .navbar-toggler.collapsed .icon-bar:nth-of-type(3) {
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.stemline-arc-m-nav .navbar-collapse {
  background-color: #F5F5F5;
  height: calc(100vh - 74px);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 4.625rem;
  transition: all 400ms 0ms;
  width: 100vw;
}

.stemline-arc-m-nav .navbar-collapse > div {
  height: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  position: relative;
  width: 100%;
}

.stemline-arc-m-nav .navbar-collapse.show {
  opacity: 1;
}

.stemline-arc-m-nav .navbar-collapse .nav-link {
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 700;
  height: max-content;
  padding: 0 0 1.25rem 0;
}

.stemline-arc-m-nav .navbar-collapse .nav-link.active-link {
  color: #3274b5;
}

.stemline-arc-m-nav .navbar-collapse .nav-link:hover {
  color: #3274b5;
}

.stemline-arc-m-nav--sel_wrap {
  bottom: 0;
  height: 2.5rem;
  left: 0;
  position: absolute;
  width: 100vw;
}

.stemline-arc-m-nav__sel_btn {
  background-color: #fff;
  border: 0;
  box-sizing: border-box;
  color: #3274b5;
  font-family: "Raleway";
  font-size: 0.875rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  height: 2.3125rem;
  letter-spacing: normal;
  line-height: normal;
  padding: 0 1.25rem;
  position: relative;
  text-align: left;
  width: 100%;
}

.stemline-arc-m-nav__sel_btn::after, .stemline-arc-m-nav__sel_btn::before {
  background-color: #3274b5;
  content: "";
  display: block;
  height: 0.9375rem;
  position: absolute;
  right: 1.25rem;
  top: 0rem;
  transform-origin: bottom left;
  transform: rotate(135deg) translateY(-1px);
  transition: all 400ms 0ms;
  width: 0.125rem;
}

.stemline-arc-m-nav__sel_btn::after {
  right: 1.3125rem;
  transform-origin: bottom right;
  transform: rotate(-135deg) translateY(-2px);
}

.stemline-arc-m-nav__sel_btn.active::after, .stemline-arc-m-nav__sel_btn.active::before {
  top: 0.625rem;
}

.stemline-arc-m-nav__sel_btn.active::after {
  transform: rotate(-45deg) translateY(0);
}

.stemline-arc-m-nav__sel_btn.active::before {
  transform: rotate(45deg) translateY(0);
}

.stemline-arc-m-nav__sel_lst {
  left: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all 400ms linear;
  width: 100vw;
}

.stemline-arc-m-nav__sel_lst ul {
  background: #fff;
  border-radius: 0 0 0.375rem 0.375rem;
  box-sizing: border-box;
  display: block;
  filter: drop-shadow(0 0.25rem 0.4375rem rgba(0, 0, 0, 0.05));
  height: max-content;
  list-style: none;
  padding: 0;
  width: 100%;
}

.stemline-arc-m-nav__sel_lst ul li a {
  color: #606060;
  display: block;
  font-family: "Raleway";
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.4375rem 0 0.4375rem 1rem;
  text-decoration: none;
}

.stemline-arc-m-nav__sel_lst ul li a:active, .stemline-arc-m-nav__sel_lst ul li a.active {
  background-color: #3274b5;
  color: #fff;
  text-decoration: none;
}

.stemline-arc-m-nav__sel_lst ul li a:hover {
  background-color: #3274b5;
  color: #fff;
  text-decoration: none;
}

.stemline-arc-m-nav__sel_lst.open {
  max-height: 18.75rem;
  opacity: 1;
  top: -4.5rem;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .stemline-arc-m-nav__logo {
    width: 10rem;
  }
  .stemline-arc-m-nav__logo img {
    width: 10rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .stemline-arc-m-nav__subnav_buttons {
    bottom: 11.25rem;
  }
}

@media (min-width: 769px) {
  .stemline-arc-m-nav {
    height: 7.0625rem;
  }
  .stemline-arc-m-nav__container {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .stemline-arc-m-nav__logo {
    height: 3.8125rem;
    padding-inline: 0 1.25rem;
    width: 13.6875rem;
  }
  .stemline-arc-m-nav__logo:last-of-type {
    padding-inline: 1.25rem 0;
  }
  .stemline-arc-m-nav__logo img {
    display: block;
    height: 3.8125rem;
    width: 12.4375rem;
  }
  .stemline-arc-m-nav .navbar-collapse {
    background-color: transparent;
    height: max-content;
    left: inherit;
    opacity: 1;
    position: relative;
    top: inherit;
    width: max-content;
  }
  .stemline-arc-m-nav .navbar-collapse > div {
    padding: 4.0625rem 0 0 7.3125rem;
  }
  .stemline-arc-m-nav .navbar-collapse .navbar-nav {
    align-items: flex-end;
    gap: 18px;
    justify-content: space-between;
    text-align: center;
  }
  .stemline-arc-m-nav .navbar-collapse .navbar-nav--patient {
    gap: 2.5rem;
    justify-content: flex-end;
  }
  .stemline-arc-m-nav .navbar-collapse .nav-link.active-link {
    position: relative;
  }
  .stemline-arc-m-nav .navbar-collapse .nav-link.active-link::before {
    background-color: #96bd9f;
    border-radius: 50%;
    content: "";
    height: 1.0625rem;
    left: -0.5rem;
    opacity: 0.3;
    position: absolute;
    top: 0.125rem;
    width: 1.0625rem;
    z-index: -1;
  }
}

.stemline-arc-m-subnav {
  height: 100%;
}

.stemline-arc-m-subnav__container {
  background-color: #E6E6E6;
  height: 4.75rem;
}

.stemline-arc-m-subnav__container .row {
  height: 100%;
}

.stemline-arc-m-subnav__container .row > div {
  height: 100%;
  padding: 0;
}

.stemline-arc-m-subnav__container__content {
  height: 100%;
}

.stemline-arc-m-subnav__container__content > div {
  height: 100%;
}

.stemline-arc-m-subnav ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 17.5rem;
}

.stemline-arc-m-subnav ul.no-dropdown {
  width: 70%;
  padding-left: 0;
  justify-content: center;
  align-items: center;
}

.stemline-arc-m-subnav ul li {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: block;
  margin: 0 1%;
  width: auto;
  text-align: left;
  position: relative;
}

.stemline-arc-m-subnav ul li:last-child {
  margin-right: 0;
  display: block;
}

.stemline-arc-m-subnav ul li:last-child a {
  font-weight: 700;
}

.stemline-arc-m-subnav ul li:last-child a::after {
  display: none;
}

.stemline-arc-m-subnav ul li a {
  color: #606060;
  text-decoration: none;
  line-height: 2.3125rem;
  font-size: 0.875rem;
  font-family: "Raleway";
  display: inline;
  position: relative;
}

.stemline-arc-m-subnav ul li a::after {
  content: '|';
  right: -0.6875rem;
  top: -0.6875rem;
  position: absolute;
}

.stemline-arc-m-subnav ul li a:visited {
  color: #606060;
  text-decoration: none;
}

.stemline-arc-m-subnav ul li a:hover {
  text-decoration: underline;
}

.stemline-arc-m-subnav__button {
  width: max-content;
  height: 2.5rem;
  padding-inline: 0.9375rem 1.4375rem;
  position: absolute;
  border-radius: 0 0 0.375rem 0.375rem;
  font-size: 0.875rem;
  font-family: "Raleway";
  font-weight: 600;
  line-height: 2.5rem;
  text-decoration: none;
  top: 0;
  right: 0;
  display: block;
}

.stemline-arc-m-subnav__button img {
  width: 0.375rem;
  height: 0.6875rem;
  object-fit: contain;
  margin-left: 0.625rem;
}

.stemline-arc-m-subnav__button--blue {
  background-color: #3274b5;
  color: #fff;
  text-decoration: none;
}

.stemline-arc-m-subnav__button--blue:visited {
  background-color: #3274b5;
  color: #fff;
  text-decoration: none;
}

.stemline-arc-m-subnav__button--blue:hover, .stemline-arc-m-subnav__button--blue.active {
  background-color: #3274b5;
  color: #fff;
  text-decoration: none;
}

.stemline-arc-m-subnav__button--green {
  background-color: #537175;
  color: #fff;
  text-decoration: none;
}

.stemline-arc-m-subnav__button--green:visited {
  background-color: #537175;
  color: #fff;
  text-decoration: none;
}

.stemline-arc-m-subnav__button--green:hover, .stemline-arc-m-subnav__button--green.active {
  background-color: #537175;
  color: #fff;
  text-decoration: none;
}

.stemline-arc-m-subnav__text {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 0.75rem;
  display: block;
  position: absolute;
  color: #606060;
  bottom: 0.8125rem;
  right: 0;
}

.stemline-arc-m-subnav--sel_wrap {
  position: absolute;
  width: 16.125rem;
  height: 2.25rem;
  margin: 0 0 0 0.9375rem;
  left: 0;
  z-index: 50000;
}

.stemline-arc-m-subnav__sel_btn {
  position: relative;
  width: 100%;
  height: 2.3125rem;
  padding: 0 1.25rem;
  background-color: #fff;
  font-family: "Raleway";
  font-size: 0.875rem;
  color: #3274b5;
  text-align: left;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-sizing: border-box;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 6px 6px;
  border: 0;
}

.stemline-arc-m-subnav__sel_btn::after, .stemline-arc-m-subnav__sel_btn::before {
  background-color: #3274b5;
  content: "";
  display: block;
  height: 0.9375rem;
  right: 1.25rem;
  position: absolute;
  top: 0.625rem;
  transform: rotate(45deg);
  transform-origin: bottom left;
  transition: all 400ms 0ms;
  width: 0.125rem;
}

.stemline-arc-m-subnav__sel_btn::after {
  transform: rotate(-45deg);
  transform-origin: bottom right;
  right: 1.3125rem;
}

.stemline-arc-m-subnav__sel_btn.active::after, .stemline-arc-m-subnav__sel_btn.active::before {
  top: 0;
}

.stemline-arc-m-subnav__sel_btn.active::after {
  transform: rotate(-135deg) translateY(-2px);
}

.stemline-arc-m-subnav__sel_btn.active::before {
  transform: rotate(135deg) translateY(-1px);
}

.stemline-arc-m-subnav__sel_lst {
  overflow: hidden;
  position: absolute;
  top: 2.25rem;
  left: 0;
  width: 19.75rem;
  max-height: 0;
  transition: all 200ms linear;
}

.stemline-arc-m-subnav__sel_lst ul {
  display: block;
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  list-style: none;
  background: #fff;
  padding: 0;
  filter: drop-shadow(0 0.25rem 0.4375rem rgba(0, 0, 0, 0.05));
  border-radius: 0 0 0.375rem 0.375rem;
}

.stemline-arc-m-subnav__sel_lst ul li a {
  display: block;
  padding: 0.4375rem 0 0.4375rem 1rem;
  font-family: "Raleway";
  font-size: 0.875rem;
  font-weight: 500;
  color: #606060;
  text-decoration: none;
}

.stemline-arc-m-subnav__sel_lst ul li a:active, .stemline-arc-m-subnav__sel_lst ul li a.active {
  color: #fff;
  text-decoration: none;
  background-color: #3274b5;
}

.stemline-arc-m-subnav__sel_lst ul li a:hover {
  text-decoration: none;
  color: #fff;
  background-color: #3274b5;
}

.stemline-arc-m-subnav__sel_lst.open {
  top: 2.25rem;
  max-height: 18.75rem;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .stemline-arc-m-subnav ul.no-dropdown {
    width: 74%;
  }
}

@media (min-width: 1280px) {
  .stemline-arc-m-subnav ul.no-dropdown {
    width: 80%;
  }
}

.stemline-arc-m-isi {
  bottom: 0;
  display: block;
  left: 0;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: fixed;
  right: 0;
  top: auto;
  transition: opacity .5s ease-in-out, max-height .2s ease-in-out;
  width: 100%;
  z-index: 35;
}

.stemline-arc-m-isi--hide {
  opacity: 0;
  pointer-events: none;
}

.stemline-arc-m-isi--expanded {
  max-height: 85%;
  z-index: 1040;
}

.stemline-arc-m-isi--expanded .accordion-collapse {
  height: auto;
  max-height: 56vh;
  overflow: auto;
}

.stemline-arc-m-isi--blue h4, .stemline-arc-m-isi--blue .h4 {
  background-color: #3274b5;
}

.stemline-arc-m-isi--blue .stemline-arc-m-isi__tip {
  background-color: #e6f1f8;
}

.stemline-arc-m-isi--blue.stemline-arc-m-isi--expanded .stemline-arc-c-isi-body-content {
  height: auto;
  max-height: 76vh;
}

.stemline-arc-m-isi--green h4, .stemline-arc-m-isi--green .h4 {
  background-color: #537175;
}

.stemline-arc-m-isi--green .stemline-arc-m-isi__tip {
  background-color: #96bd9f;
}

.stemline-arc-m-isi--green.stemline-arc-m-isi--expanded .stemline-arc-c-isi-body-content {
  height: auto;
  max-height: 76vh;
  overflow: auto;
}

.stemline-arc-m-isi--persistent-bottom {
  max-height: none !important;
  position: static;
}

.stemline-arc-m-isi__wrapper {
  background-color: #fff;
}

.stemline-arc-m-isi__tip {
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  color: #000;
  font-family: "Raleway";
  font-size: 1.125rem;
  font-weight: 700;
  height: 2.1875rem;
  line-height: 1.5rem;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  text-decoration: none;
  width: 9.6875rem;
}

.stemline-arc-m-isi__tip:hover {
  color: inherit;
}

.stemline-arc-m-isi__tip::after {
  background-image: url("/images/svg/icon-plus-circle.svg");
  background-repeat: no-repeat;
  background-size: 1.375rem 1.375rem;
  color: inherit;
  content: '';
  height: 1.375rem;
  margin-left: 0.5rem;
  width: 1.375rem;
}

.stemline-arc-m-isi__tip--opened::after {
  background-image: url("/images/svg/icon-minus-circle.svg");
}

.stemline-arc-m-isi h4, .stemline-arc-m-isi .h4 {
  color: #fff;
  font-family: "Inter";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1875rem;
  padding: 0.75rem;
  text-align: center;
}

@media (max-width: 1023px) {
  .stemline-arc-m-isi:not(.stemline-arc-m-isi--expanded) {
    max-height: 13.5625rem;
  }
  .stemline-arc-m-isi--blue .stemline-arc-c-isi-body-content {
    padding: 1.25rem 0 3.125rem;
  }
  .stemline-arc-m-isi--green .stemline-arc-c-isi-body-content {
    padding: 1.25rem 0 3.125rem;
  }
}

@media (min-width: 1024px) {
  .stemline-arc-m-isi:not(.stemline-arc-m-isi--expanded) {
    max-height: 18.0625rem;
  }
}

@media (min-width: 376px) {
  .stemline-arc-m-isi--persistent-bottom .stemline-arc-c-tab .nav-link {
    font-size: 1.25rem;
    height: 4.6875rem;
  }
  .stemline-arc-m-isi--persistent-bottom .stemline-arc-c-tab .nav-link:not(.active) {
    box-shadow: inset 0 -0.6875rem 0.625rem rgba(0, 0, 0, 0.1);
  }
  .stemline-arc-m-isi--persistent-bottom .stemline-arc-c-isi-body-content {
    padding: 3.125rem 0 0;
  }
  .stemline-arc-m-isi--tray .stemline-arc-c-isi-body-content {
    padding: 1.1875rem 0 3.125rem;
  }
}

.stemline-arc-m-questions {
  align-items: center;
  background-image: var(--mob);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  text-align: center;
}

.stemline-arc-m-questions__img {
  background-image: var(--mob);
  background-size: 10.875rem;
  background-repeat: no-repeat;
  height: 11.5rem;
  margin: 2.625rem auto 0;
  position: relative;
  text-align: center;
  width: 12.5rem;
}

.stemline-arc-m-questions__content {
  color: #606060;
  font-family: "Raleway";
  margin-bottom: 2.3125rem;
  position: relative;
  text-align: left;
}

.stemline-arc-m-questions__content--title {
  color: #3274b5;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 2.64125rem;
  margin-bottom: 0.3125rem;
  margin-top: 0.3125rem;
}

.stemline-arc-m-questions__content--contact-text {
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-top: -0.25rem;
}

.stemline-arc-m-questions__content--contact-text a[href^='tel:'] {
  color: #606060;
  text-decoration: none;
}

@media (min-width: 376px) and (max-width: 1023px) {
  .stemline-arc-m-questions {
    padding-bottom: 2.9375rem;
  }
  .stemline-arc-m-questions__content {
    margin-bottom: 0.3125rem;
    margin-top: 6.875rem;
    position: relative;
  }
  .stemline-arc-m-questions__content--contact-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    margin-bottom: 0;
    max-width: max-content;
    width: 20.9375rem;
  }
  .stemline-arc-m-questions__bg {
    display: flex;
    justify-content: center;
  }
  .stemline-arc-m-questions__img {
    background-image: var(--mob);
    display: flex;
    margin-top: 6.4375rem;
    position: static;
    right: 13%;
  }
}

@media (max-width: 767px) {
  .stemline-arc-m-questions__content {
    margin-top: 0.375rem;
  }
  .stemline-arc-m-questions__img {
    background-position: center;
    margin-top: 2.25rem;
  }
}

@media (min-width: 769px) {
  .stemline-arc-m-questions {
    align-items: center;
    background-image: var(--desk);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 1.5rem;
  }
  .stemline-arc-m-questions__content {
    left: -3%;
    margin-bottom: 0.3125rem;
    margin-top: 8.1875rem;
    position: relative;
  }
  .stemline-arc-m-questions__content--title {
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 800;
    line-height: 3.081875rem;
    margin-bottom: 0.5625rem;
    margin-top: 0.1875rem;
  }
  .stemline-arc-m-questions__content--contact-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
  }
  .stemline-arc-m-questions__content--contact-text a[href^='tel:'] {
    color: #606060;
    pointer-events: none;
    text-decoration: none;
  }
  .stemline-arc-m-questions__img {
    background-image: var(--desk);
    background-size: 14.5rem;
    display: flex;
    height: 16.875rem;
    left: 23%;
    margin-top: 6.3125rem;
    width: 16.875rem;
  }
}

.stemline-arc-m-simple-questions {
  background-color: #e6f1f8;
  display: flex;
  padding-bottom: 2.125rem;
  padding-top: 2.0625rem;
}

.stemline-arc-m-simple-questions__green {
  background-color: #f1f6f3;
}

.stemline-arc-m-simple-questions > div {
  display: flex;
  flex-direction: column;
}

.stemline-arc-m-simple-questions__title {
  display: flex;
  flex-direction: column;
}

.stemline-arc-m-simple-questions__title h2, .stemline-arc-m-simple-questions__title .h2 {
  color: #3274b5;
  font-family: "Raleway";
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: 2.625rem;
  margin-bottom: 0;
}

.stemline-arc-m-simple-questions__text {
  color: #606060;
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  max-width: 17.0625rem;
  position: relative;
  text-align: left;
  word-wrap: break-word;
}

.stemline-arc-m-simple-questions__text a[href^='tel:'] {
  color: #606060;
  text-decoration: none;
}

@media (min-width: 768px) {
  .stemline-arc-m-simple-questions {
    padding-bottom: 0.8125rem;
    padding-top: 1.9375rem;
  }
  .stemline-arc-m-simple-questions > div {
    flex-direction: row;
    gap: 2rem;
  }
  .stemline-arc-m-simple-questions__title {
    justify-content: center;
    margin-left: 0;
  }
  .stemline-arc-m-simple-questions__title h2, .stemline-arc-m-simple-questions__title .h2 {
    display: block;
    font-size: 2.625rem;
    line-height: 3.0625rem;
    margin-bottom: 0.5rem;
  }
  .stemline-arc-m-simple-questions__text {
    font-size: 1rem;
    font-weight: 400;
    justify-content: center;
    line-height: 1.375rem;
    max-width: max-content;
    width: max-content;
    word-break: break-all;
  }
  .stemline-arc-m-simple-questions__text a[href^='tel:'] {
    color: #606060;
    pointer-events: none;
    text-decoration: none;
  }
}

@media (min-width: 1025px) {
  .stemline-arc-m-simple-questions__title {
    margin-left: 14.0625rem;
  }
}

.stemline-arc-m-product-select__wrapper__brand {
  text-align: center;
}

.stemline-arc-m-product-select__wrapper__brand__anchor {
  color: #606060;
  font-family: Raleway;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.125rem;
}

.stemline-arc-m-product-select__wrapper__brand__anchor:hover {
  color: inherit;
}

.stemline-arc-m-product-select__wrapper__brand__img {
  width: 14.5625rem;
  object-fit: contain;
}

.stemline-arc-m-product-select__wrapper__brand .stemline-arc-m-button {
  margin: 0 auto;
}

@media (max-width: 767px) {
  .stemline-arc-m-product-select {
    padding: 39px 0 26px;
  }
  .stemline-arc-m-product-select__wrapper__brand {
    margin-bottom: 3.0625rem;
  }
  .stemline-arc-m-product-select__wrapper__brand__img {
    margin-bottom: 23px;
  }
  .stemline-arc-m-product-select__wrapper__brand .stemline-arc-m-button__green {
    margin-bottom: 0.5rem;
  }
  .stemline-arc-m-product-select__wrapper__brand .stemline-arc-m-button__blue {
    margin-bottom: 0.75rem;
  }
  .stemline-arc-m-product-select__wrapper__brand .stemline-arc-m-button__arrow {
    height: 0.9375rem;
    width: 0.5625rem;
  }
}

@media (min-width: 376px) {
  .stemline-arc-m-product-select {
    padding: 56px 0 26px;
  }
  .stemline-arc-m-product-select__wrapper {
    max-width: 47.5rem;
  }
  .stemline-arc-m-product-select__wrapper__brand:last-child .stemline-arc-m-product-select__wrapper__brand__img {
    margin-top: -1rem;
  }
  .stemline-arc-m-product-select__wrapper__brand__img {
    margin-bottom: 1.5rem;
  }
  .stemline-arc-m-product-select__wrapper__brand:first-child .stemline-arc-m-product-select__wrapper__brand__img {
    margin-bottom: 1.75rem;
  }
  .stemline-arc-m-product-select__wrapper__brand .stemline-arc-m-button {
    height: 3.125rem;
    width: 95%;
  }
  .stemline-arc-m-product-select__wrapper__brand .stemline-arc-m-button__green {
    margin-bottom: 0.5rem;
  }
  .stemline-arc-m-product-select__wrapper__brand .stemline-arc-m-button__blue {
    margin-bottom: 0.75rem;
  }
  .stemline-arc-m-product-select__wrapper__brand .stemline-arc-m-button__arrow {
    height: 0.9375rem;
    width: 1.0625rem;
  }
}

@media (min-width: 1024px) {
  .stemline-arc-m-product-select .stemline-arc-m-button {
    width: 22.5625rem;
  }
  .stemline-arc-m-product-select .stemline-arc-m-button__arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.stemline-arc-m-box-content {
  font-family: "Raleway";
}

.stemline-arc-m-box-content__header {
  margin-bottom: 2.25rem;
}

.stemline-arc-m-box-content__header h3, .stemline-arc-m-box-content__header .h3 {
  color: #3274b5;
  font-weight: 800;
}

.stemline-arc-m-box-content__header h3 sup, .stemline-arc-m-box-content__header .h3 sup {
  vertical-align: bottom;
}

.stemline-arc-m-box-content__header b {
  color: #606060;
  font-weight: 700;
}

.stemline-arc-m-box-content__header__img {
  max-width: 100%;
}

.stemline-arc-m-box-content__body ul {
  list-style: none;
}

.stemline-arc-m-box-content__body ul li {
  align-items: center;
  display: flex;
}

.stemline-arc-m-box-content__body__details {
  margin-bottom: 1.625rem;
}

.stemline-arc-m-box-content__body__specialty {
  background-color: #f1f6f3;
  border-bottom: 0.0625rem solid #3274b5;
  color: #3274b5;
  font-size: 1.25rem;
  font-weight: 700;
  height: 2.9375rem;
  letter-spacing: 0;
  line-height: 1.4375rem;
}

.stemline-arc-m-box-content__body__title {
  color: #606060;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.375rem;
}

.stemline-arc-m-box-content__body__subtitle {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.375rem;
}

.stemline-arc-m-box-content__body__contact-info::before {
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  margin-right: 0.3125rem;
  width: 1.3125rem;
}

.stemline-arc-m-box-content__body__contact-info a {
  color: #606060;
  word-break: break-word;
}

.stemline-arc-m-box-content__body__contact-info:not(.stemline-arc-m-box-content__body__contact-info--laptop):not(.stemline-arc-m-box-content__body__contact-info--envelope) a {
  text-decoration: none;
}

.stemline-arc-m-box-content__body__contact-info--phone::before {
  background-image: url("/images/svg/icon-phone.svg");
  height: 1rem;
}

.stemline-arc-m-box-content__body__contact-info--printer::before {
  background-image: url("/images/svg/icon-printer.svg");
  height: 0.875rem;
}

.stemline-arc-m-box-content__body__contact-info--envelope::before {
  background-image: url("/images/svg/icon-envelope.svg");
  height: 0.75rem;
}

.stemline-arc-m-box-content__body__contact-info--laptop::before {
  background-image: url("/images/svg/icon-laptop.svg");
  height: 0.75rem;
}

@media (max-width: 1023px) {
  .stemline-arc-m-box-content--no-img .stemline-arc-m-box-content__header b {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
  .stemline-arc-m-box-content__header h3, .stemline-arc-m-box-content__header .h3 {
    font-size: 2rem;
    line-height: 2.375rem;
    margin-bottom: 1.25rem;
  }
  .stemline-arc-m-box-content__header h3 sup, .stemline-arc-m-box-content__header .h3 sup {
    font-size: 2rem;
  }
  .stemline-arc-m-box-content__header b {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  .stemline-arc-m-box-content__header__img {
    margin-bottom: 1.125rem;
  }
  .stemline-arc-m-box-content__body__contact-list {
    padding: 1.3125rem 0 0.75rem;
  }
}

@media (min-width: 376px) {
  .stemline-arc-m-box-content__header h3, .stemline-arc-m-box-content__header .h3 {
    font-size: 2.25rem;
    line-height: 2.625rem;
    margin-bottom: 1.125rem;
  }
  .stemline-arc-m-box-content__header h3 sup, .stemline-arc-m-box-content__header .h3 sup {
    font-size: 2.5rem;
  }
  .stemline-arc-m-box-content__header b {
    font-size: 1.25rem;
    letter-spacing: 0.01875rem;
    line-height: 1.625rem;
  }
  .stemline-arc-m-box-content__body__contact-list {
    padding: 1.3125rem 1.125rem 0.75rem;
  }
  .stemline-arc-m-box-content__body__subtitle {
    margin-top: 0.3125rem;
  }
}

@media (max-width: 768px) {
  .stemline-arc-m-box-content__body__contact-info span {
    padding-top: 5px;
  }
}

.stemline-arc-c-content-divider {
  color: #606060;
  max-width: 69.375rem;
}

.stemline-arc-m-callout-cards {
  padding-bottom: 4.375rem;
  padding-top: 5.5rem;
}

.stemline-arc-m-callout-cards > div {
  display: flex;
  flex-direction: column;
  gap: 4.8125rem;
}

.stemline-arc-m-callout-cards__item {
  background: #f1f6f3;
  border-radius: 1.25rem;
  position: relative;
}

.stemline-arc-m-callout-cards__item__icon {
  height: 5.3125rem;
  left: 1.3125rem;
  object-fit: contain;
  position: absolute;
  top: -3rem;
  width: 5.4375rem;
}

.stemline-arc-m-callout-cards__item__title {
  color: #3274b5;
  font-family: "Raleway";
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4375rem;
}

.stemline-arc-m-callout-cards__item__content {
  color: #606060;
  font-family: "Raleway";
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 0.1875rem;
}

.stemline-arc-m-callout-cards__item .stemline-arc-m-button {
  font-family: "Raleway";
  font-size: 1rem;
  padding: 0.625rem 0.9375rem 0.5625rem 1.625rem;
}

.stemline-arc-m-callout-cards__item .stemline-arc-m-button__arrow {
  height: 0.9375rem;
}

@media (max-width: 767px) {
  .stemline-arc-m-callout-cards__box:last-of-type .stemline-arc-m-callout-cards__item {
    margin-bottom: 0;
  }
  .stemline-arc-m-callout-cards__item {
    margin-bottom: 5rem;
    padding: 3.625rem 1.875rem 2.5rem;
  }
  .stemline-arc-m-callout-cards__item .stemline-arc-m-button {
    max-width: 10.1875rem;
  }
}

@media (min-width: 768px) {
  .stemline-arc-m-callout-cards {
    padding-bottom: 6.25rem;
    padding-top: 5.625rem;
  }
  .stemline-arc-m-callout-cards > div {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.875rem;
    justify-content: center;
    padding: 0;
  }
  .stemline-arc-m-callout-cards__item {
    padding: 3.625rem 1.875rem 4.875rem;
  }
  .stemline-arc-m-callout-cards .stemline-arc-m-button {
    bottom: 2.5rem;
    left: 1.875rem;
    position: absolute;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .stemline-arc-m-callout-cards__item {
    min-height: 28.375rem;
  }
}

@media (min-width: 1024px) {
  .stemline-arc-m-callout-cards__item {
    min-height: 21.375rem;
  }
}

.stemline-arc-m-resources > div {
  display: flex;
  flex-direction: column;
}

.stemline-arc-m-resources__left, .stemline-arc-m-resources__right {
  display: flex;
  flex-direction: column;
  padding-bottom: 4.375rem;
}

.stemline-arc-m-resources__left {
  padding-top: 2.5rem;
}

.stemline-arc-m-resources__left > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.5rem;
}

.stemline-arc-m-resources__left h2, .stemline-arc-m-resources__left .h2 {
  display: inline-block;
  width: 100%;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.375rem;
  color: #3274b5;
  padding-bottom: 1.6875rem;
}

.stemline-arc-m-resources__item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.stemline-arc-m-resources__item__thumb {
  width: 4.9375rem;
  height: 6.4375rem;
}

.stemline-arc-m-resources__item__content {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.stemline-arc-m-resources__item__content__title {
  width: 100%;
  display: block;
  padding-bottom: 0.5rem;
  font-family: "Raleway";
  color: #606060;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.1875rem;
}

.stemline-arc-m-resources__item__content__link {
  width: 100%;
  display: block;
  font-family: "Raleway";
  color: #606060;
  text-decoration: none;
  outline: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
}

.stemline-arc-m-resources__item__content__link img {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5625rem;
  object-fit: contain;
}

.stemline-arc-m-resources__item__content__link:visited, .stemline-arc-m-resources__item__content__link:hover, .stemline-arc-m-resources__item__content__link:focus {
  color: #606060;
  text-decoration: none;
  outline: 0;
}

.stemline-arc-m-resources__box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 1.25rem;
  padding: 1.5rem 2.6875rem 1.6875rem 1.6875rem;
}

.stemline-arc-m-resources__box--link-water {
  background-color: #e6f1f8;
}

.stemline-arc-m-resources__box--saltpan {
  background-color: #f1f6f3;
}

.stemline-arc-m-resources__box__title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #3274b5;
}

.stemline-arc-m-resources__box__link {
  width: 100%;
  display: inline-block;
  font-family: "Raleway";
  color: #606060;
  text-decoration: underline;
  outline: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
}

.stemline-arc-m-resources__box__link img {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  object-fit: contain;
}

.stemline-arc-m-resources__box__link:visited, .stemline-arc-m-resources__box__link:hover, .stemline-arc-m-resources__box__link:focus {
  color: #606060;
  text-decoration: underline;
  outline: 0;
}

@media (min-width: 768px) {
  .stemline-arc-m-resources > div {
    display: flex;
    flex-direction: row;
    padding-top: 3.1875rem;
    padding-inline: 0;
  }
  .stemline-arc-m-resources__left, .stemline-arc-m-resources__right {
    padding-bottom: 6.5rem;
  }
  .stemline-arc-m-resources__left h2, .stemline-arc-m-resources__left .h2 {
    font-size: 2.25rem;
  }
  .stemline-arc-m-resources__left {
    padding-top: 0;
    flex-direction: column;
  }
  .stemline-arc-m-resources__left > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1.875rem;
    flex-wrap: wrap;
  }
  .stemline-arc-m-resources__item {
    max-width: 18.4375rem;
  }
  .stemline-arc-m-resources__item--big {
    max-width: 15.9375rem;
  }
  .stemline-arc-m-resources__item__content {
    gap: 0.625rem .stemline-arc-m-resources__item__content __title;
    gap-padding: 0;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: none;
}

body.isi-expanded {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

@media (min-width: 376px) {
  .isi-expanded .stemline-arc-c-isi-body-content {
    height: 75vh;
    overflow: auto;
    position: static;
  }
  .narrowed-content {
    margin: 0 auto;
    max-width: 58.75rem;
  }
}

.ot-sdk-show-settings {
  display: none;
}

.stemline-arc-p-hcp-elzonris-assistance-ordering .stemline-arc-c-content-divider + .stemline-arc-m-box-content {
  margin-top: 2.5625rem;
}

.stemline-arc-p-hcp-elzonris-assistance-ordering .stemline-arc-m-foot-notes + .stemline-arc-m-simple-questions {
  margin-top: 4.375rem;
}

@media (min-width: 376px) {
  .stemline-arc-p-hcp-orserdu-assistance-ordering .stemline-arc-m-simple-text-3__text {
    margin-bottom: 1.875rem;
  }
  .stemline-arc-p-hcp-orserdu-assistance-ordering .stemline-arc-m-simple-text-3__item {
    margin-bottom: 1.25rem;
  }
}

.stemline-arc-p-hcp-orserdu-assistance-ordering .stemline-arc-c-content-divider + .stemline-arc-m-box-content {
  margin-top: 2.5625rem;
}

.stemline-arc-p-hcp-orserdu-assistance-ordering .stemline-arc-m-foot-notes + .stemline-arc-m-simple-questions {
  margin-top: 4.375rem;
}
