* {
  // sass-lint:disable-all
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // sass-lint:enable-all
}

body {
  background:  none;

  &.isi-expanded {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

@include media('>phone') {
  &.isi-expanded {
    .#{$prefix}-c-isi-body-content {
      height: 75vh;
      overflow: auto;
      position: static;
    }
  }

  .narrowed-content {
    margin: 0 auto;
    max-width: rem(940);
  }
}

.ot-sdk-show-settings {
  display: none;
}
