.#{$prefix}-m-internal-hero {
  margin-bottom: rem(100);
  position: relative;

  &__blue {
    background-color: $color-astral;
  }

  &__green {
    background-color: $color-cutty-sark;
  }

  &__image {
    bottom: rem(-105);
    max-width: rem(281);
    position: absolute;
    right: 0;
  }

  &__image-small {
    width: rem(204);
  }

  &__text-container {
    padding: rem(29) 0 rem(117);
  }

  &__headline {
    color: $color-white;
    font-family: $font-raleway;
    font-size: rem(40);
    font-weight: 700;
    line-height: rem(44);
    margin-bottom: rem(18);
  }

  &__text {
    color: $color-white;
    font-family: $font-raleway;
    font-size: rem(18);
    font-weight: 400;
    line-height: rem(24);
    margin-bottom: rem(24);
  }

  &__text-2 {
    color: $color-white;
    font-family: $font-raleway;
    font-size: rem(20);
    font-weight: 700;
    line-height: rem(23);
    margin-bottom: rem(16);
  }

  @include media('<tablet') {
    .#{$prefix}-m-button {
      height: rem(70);
      width: 100%;
    }
  }

  @include media('>=tablet') {
    margin-bottom: rem(56);

    &__image {
      bottom: rem(-56);
      position: absolute;
      right: 0;
    }

    &__text-container {
      padding-bottom: rem(57);
      padding-top: rem(56);

      .#{$prefix}-m-button {
        max-width: rem(323);
      }
    }

    &__headline {
      font-size: rem(56);
      line-height: rem(60);
      margin-bottom: rem(19);
      width: rem(630);
    }

    &__text {
      font-size: rem(20);
      letter-spacing: rem(-.1);
      line-height: rem(26);
      margin-bottom: rem(25);
      max-width: rem(480);
      width: 100%;
    }

    &__text-2 {
      font-size: rem(22);
      font-weight: 800;
      line-height: rem(26);
      margin-bottom: rem(17);
      width: rem(500);
    }

    &__image-small {
      width: 100%;
    }
  }

  @include media('>desktop') {
    &__image {
      max-width: rem(539);
    }
    &__text {
      max-width: rem(550);
    }

    &__image-small {
      max-width: rem(539);
    }
  }
}

