.#{$prefix}-p-hcp-orserdu-assistance-ordering {

  @include media('>phone') {
    .#{$prefix}-m-simple-text-3 {
      &__text {
        margin-bottom: rem(30);
      }

      &__item {
        margin-bottom: rem(20);
      }
    }
  }

  .#{$prefix}-c-content-divider + .#{$prefix}-m-box-content {
    margin-top: rem(41);
  }

  .#{$prefix}-m-foot-notes + .#{$prefix}-m-simple-questions {
    margin-top: rem(70);
  }
}
