.#{$prefix}-m-resources {
  &>div {
    display: flex;
    flex-direction: column;
    
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    // width: 100%;
    padding-bottom: rem(70);    
  }

  &__left {
    padding-top: rem(40);
    
    &>div {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: rem(40);
    }

    h2 {
      display: inline-block;
      width: 100%;
      font-family: $font-raleway;
      font-style: normal;
      font-weight: 800;
      font-size: rem(32);
      line-height: rem(38);
      color: $color-astral;
      padding-bottom: rem(27);
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    gap: rem(16);

    &__thumb {
      width: rem(79);
      height: rem(103);
    }

    &__content {
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      &__title {
        width: 100%;
        display: block;
        padding-bottom: rem(8);
        font-family: $font-raleway;
        color: $color-scorpion;
        font-style: normal;
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(19);
      }

      &__link {
        width: 100%;
        display: block;
        font-family: $font-raleway;
        color: $color-scorpion;
        text-decoration: none;
        outline: 0;
        font-style: normal;
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(22);

        img {
          width: rem(20);
          height: rem(20);
          margin-right: rem(9);
          object-fit: contain;
        }

        &:visited,
        &:hover,
        &:focus {
          color: $color-scorpion;
          text-decoration: none;
          outline: 0;
        }
      }
    }
  }

  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:rem(10);
    
    border-radius: rem(20);
    padding: rem(24) rem(43) rem(27) rem(27);

    &--link-water {
      background-color: $color-link-water;
    }

    &--saltpan {
      background-color: $color-saltpan;
    }
    &__title {
      font-family: $font-raleway;
      font-style: normal;
      font-weight: 700;
      font-size: rem(18);
      line-height: rem(22);
      color: $color-astral;
    }

    &__link {
        width: 100%;
        display: inline-block;
        font-family: $font-raleway;
        color: $color-scorpion;
        text-decoration: underline;
        outline: 0;
        font-style: normal;
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(18);
      img {
        width: rem(20);
        height: rem(20);
        margin-right: rem(10);
        object-fit: contain;
      }

      &:visited,
        &:hover,
        &:focus {
          color: $color-scorpion;
          text-decoration: underline;
          outline: 0;
        }
    }
  }

  

  @include media('>=tablet') {

    &>div {
      display: flex;
      flex-direction: row;
      padding-top: rem(51);
      // padding-bottom: rem(118)
      padding-inline: 0;
      
    }

    &__left,
    &__right {
      padding-bottom: rem(104);
      // width: 25%;
      
    }

    &__left {
      // min-width: rem(255);
      h2 {
        font-size: rem(36);
      }
      &--big {
        // width: 75%;
      }

    }

    &__right {
      &--big {
        // width: 67%;
      }
    }

    &__left {
      padding-top: 0;
      flex-direction: column;

      &>div {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: rem(30);
        flex-wrap: wrap;
      }
    }

    &__item {
      max-width: rem(295);

      &--big {
        max-width: rem(255);
      }

      &__content{
        gap: rem(10)
        &__title{
          padding: 0;
        }
        
      }
      
    }

  }

}