.#{$prefix}-m-subnav {
  height: 100%;

  &__container {

    background-color: $color-mercury;
    height: rem(76);

    .row {
      height: 100%;

      &>div {
        height: 100%;
        padding: 0;
      }
    }

    &__content {

      height: 100%;

      >div {
        height: 100%;
      }
    }
  }

  ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: rem(280);

    &.no-dropdown {
      width: 70%;
      padding-left: 0;
      justify-content: center;
      align-items: center;
    }

    li {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: block;
      margin: 0 1%;
      width: auto;
      text-align: left;
      position: relative;

      &:last-child {
        margin-right: 0;
        display: block;

        a {
          font-weight: 700;

          &::after {
            display: none;
          }
        }
      }

      a {
        color: $color-scorpion;
        text-decoration: none;
        line-height: rem(37);
        font-size: rem(14);
        font-family: $font-raleway;
        display: inline;
        position: relative;

        &::after {
          content: '|';
          right: rem(-11);
          top: rem(-11);
          position: absolute;
        }

        &:visited {
          color: $color-scorpion;
          text-decoration: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__button {

    width: max-content;
    height: rem(40);
    padding-inline: rem(15) rem(23);
    position: absolute;
    border-radius: 0 0 rem(6) rem(6);
    font-size: rem(14);
    font-family: $font-raleway;
    font-weight: 600;
    line-height: rem(40);
    text-decoration: none;
    top: 0;
    right: 0;
    display: block;

    img {
      width: rem(6);
      height: rem(11);
      object-fit: contain;
      margin-left: rem(10);
    }

    &--blue {
      background-color: $color-astral;
      color: $color-white;
      text-decoration: none;

      &:visited {
        background-color: $color-astral;
        color: $color-white;
        text-decoration: none;
      }

      &:hover,
      &.active {
        background-color: $color-astral;
        color: $color-white;
        text-decoration: none;
      }
    }

    &--green {
      background-color: $color-cutty-sark;
      color: $color-white;
      text-decoration: none;

      &:visited {
        background-color: $color-cutty-sark;
        color: $color-white;
        text-decoration: none;
      }

      &:hover,
      &.active{
        background-color: $color-cutty-sark;
        color: $color-white;
        text-decoration: none;
      }
    }
  }

  &__text {
    font-family: $font-raleway;
    font-weight: 500;
    font-size: rem(12);
    display: block;
    position: absolute;
    color: $color-scorpion;
    bottom: rem(13);
    right: 0;
  }

  &--sel_wrap {
    position: absolute;
    width: rem(258);
    height: rem(36);
    margin: 0 0 0 rem(15);
    left: 0;
    z-index: 50000;
  }

  &__sel_btn {
    position: relative;
    width: 100%;
    height: rem(37);
    padding: 0 rem(20);
    background-color: $color-white;
    font-family: $font-raleway;
    font-size: rem(14);
    color: $color-astral;
    text-align: left;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    box-sizing: border-box;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 6px 6px;
    border: 0;

    &::after,
    &::before {
      background-color: $color-astral;
      content: "";
      display: block;
      height: rem(15);
      right: rem(20);
      position: absolute;
      top: rem(10);
      transform: rotate(45deg);
      transform-origin: bottom left;
      transition: all 400ms 0ms;
      width: rem(2);
    }

    &::after {
      transform: rotate(-45deg);
      transform-origin: bottom right;
      right: rem(21);
    }

    &.active {

      &::after,
      &::before {
        top: 0;
      }

      &::after {
        transform: rotate(-135deg) translateY(-2px);
      }

      &::before {
        transform: rotate(135deg) translateY(-1px);
      }
    }
  }

  &__sel_lst {
    overflow: hidden;
    position: absolute;
    top: rem(36);
    left: 0;
    width: rem(316);
    max-height: 0;
    transition: all 200ms linear;

    ul {
      display: block;
      width: 100%;
      height: max-content;
      // border: 1px solid $color-st-tropaz;
      box-sizing: border-box;
      list-style: none;
      background: $color-white;
      padding: 0;
      filter: drop-shadow(0 rem(4) rem(7) rgba(0, 0, 0, 0.05));
      border-radius: 0 0 rem(6) rem(6);

      li {
        a {
          display: block;
          padding: rem(7) 0 rem(7) rem(16);
          font-family: $font-raleway;
          font-size: rem(14);
          font-weight: 500;

          color: $color-scorpion;
          text-decoration: none;

          &:active, &.active {
            color: $color-white;
            text-decoration: none;
            background-color: $color-astral;
          }

          &:hover {
            text-decoration: none;
            color: $color-white;
            background-color: $color-astral;
          }
        }
      }
    }

    &.open {
      top: rem(36);
      max-height: rem(300);
    }
  }

  @include media('>=desktop', '<desktop-lg') {
    ul {
      &.no-dropdown {
        width: 74%;
      }
    }

  }

  @include media('>=desktop-lg') {
    ul {
      &.no-dropdown {
        width: 80%;
      }
    }

  }
}