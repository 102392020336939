.#{$prefix}-m-homepage-hero {
  background: $color-cutty-sark;
  position: relative;

  &__desktop-banner {
    display: none;
  }

  &__mobile-banner {
    width: 100%;
  }

  &__text-container {
    padding: rem(40) rem(8) rem(36);
  }

  &__headline {
    color: $color-white;
    font-family: $font-raleway;
    font-size: rem(40);
    font-weight: 700;
    line-height: rem(44);
    margin-bottom: rem(20);

    span {
      color: $color-yellow-green;
    }
  }

  &__text {
    color: $color-white;
    font-family: $font-raleway;
    font-size: rem(18);
    font-weight: 400;
    line-height: rem(24);
    margin-bottom: rem(30);
  }

  &__action-text {
    color: $color-yellow-green;
    font-family: $font-raleway;
    font-size: rem(20);
    font-weight: 700;
    line-height: rem(24);
    margin-bottom: 0;
  }

  &__arrow {
    margin-left: rem(15);
    width: rem(31);
  }

  @include media('>tablet') {
    background-image: url(/images/homepage/hero-section.png);
    background-position: center;
    background-size: cover;

    &__desktop-banner {
      display: block;
      width: 100%;
    }

    &__mobile-banner {
      display: none;
    }

    &__text-container {
      padding-top: rem(36);

    }

    &__headline {
      font-size: rem(56);
      font-weight: 700;
      line-height: rem(60);
      width: rem(630);
    }

    &__text {
      font-size: rem(20);
      font-weight: 400;
      letter-spacing: rem(.5);
      line-height: rem(26);
      margin-bottom: rem(50);
      width: rem(465);
    }

    &__action-text {
      font-size: rem(22);
      letter-spacing: rem(.5);
      line-height: rem(24);
    }
  }
  @include media('>desktop-lg') {
    &__text-container {
      padding: rem(80) rem(8) rem(80);
    }
  }
}
