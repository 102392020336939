.#{$prefix}-m-callout-cards {
  padding-bottom: rem(70);
  padding-top: rem(88);

  > div {
    display: flex;
    flex-direction: column;
    gap: rem(77);
  }

  &__item {
    background: $color-saltpan;
    border-radius: rem(20);
    position: relative;

    &__icon {
      height: rem(85);
      left: rem(21);
      object-fit: contain;
      position: absolute;
      top: rem(-48);
      width: rem(87);
    }

    &__title {
      color: $color-astral;
      font-family: $font-raleway;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: rem(20);
      font-style: normal;
      font-weight: 700;
      line-height: rem(23);
    }

    &__content {
      color: $color-scorpion;
      font-family: $font-raleway;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(22);
      padding-bottom: rem(3);
    }

    .#{$prefix}-m-button {
      font-family: $font-raleway;
      font-size: rem(16);
      padding: rem(10) rem(15) rem(9) rem(26);

      &__arrow {
        height: rem(15);
      }
    }
  }

  @include media('<tablet') {
    &__box {
      &:last-of-type {
        .#{$prefix}-m-callout-cards__item {
          margin-bottom: 0;
        }
      }
    }

    &__item {
      margin-bottom: rem(80);
      padding: rem(58) rem(30) rem(40);

      .#{$prefix}-m-button {
        max-width: rem(163);
      }
    }
  }

  @include media('>=tablet') {
    padding-bottom: rem(100);
    padding-top: rem(90);

    > div {
      align-items: stretch;
      flex-direction: row;
      flex-wrap: wrap;
      gap: rem(30);
      justify-content: center;
      padding: 0;
    }

    &__item {
      padding: rem(58) rem(30) rem(78);
    }

    .#{$prefix}-m-button {
      bottom: rem(40);
      left: rem(30);
      position: absolute;
    }
  }

  @include media('>=tablet', '<desktop') {
    &__item {
      min-height: rem(454);
    }
  }

  @include media('>=desktop') {
    &__item {
      min-height: rem(342);
    }
  }
}
