.#{$prefix}-c-tab {
  border: 0;

  .nav-item {
    width: 50%;
  }

  .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-width: 0;
    color: $color-cutty-sark;
    font-family: $font-inter;
    font-size: rem(18);
    font-weight: 700;
    line-height: rem(19);
    text-align: center;
    width: 100%;

    &.active {
      color: $color-astral;
    }

    &:not(.active) {
      background: $color-saltpan;
      box-shadow: inset rem(4) rem(-4) rem(14) $color-black-10;
    }
  }
}
