//
// Utils
// --------------------------------------------------
.no-scroll {
	overflow-y: hidden;
}


@media (min-width: 1240px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1110px;
	}
}
