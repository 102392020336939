.#{$prefix}-m-simple-questions {
  background-color: $color-link-water;
  display: flex;
  padding-bottom: rem(34);
  padding-top: rem(33);

  &__green {
    background-color: $color-saltpan;
  }

  &>div {
    display: flex;
    flex-direction: column;
  }

  &__title {
    display: flex;
    flex-direction: column;

    h2 {
      color: $color-astral;
      font-family: $font-raleway;
      font-size: rem(36);
      font-style: normal;
      font-weight: 800;
      line-height: rem(42);
      margin-bottom: 0;
    }
  }

  &__text {
    color: $color-scorpion;
    display: flex;
    flex-direction: column;
    font-family: $font-raleway;
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: rem(22);
    max-width: rem(273);
    position: relative;
    text-align: left;
    word-wrap: break-word;

    a[href^='tel:'] {
      color: $color-scorpion;
      text-decoration: none;
    }
  }

  @include media('>=tablet') {
    padding-bottom: rem(13);
    padding-top: rem(31);

    &>div {
      flex-direction: row;
      gap: rem(32);
    }

    &__title {
      justify-content: center;
      margin-left: 0;

      h2 {
        display: block;
        font-size: rem(42);
        line-height: rem(49);
        margin-bottom: rem(8);
      }
    }

    &__text {
      font-size: rem(16);
      font-weight: 400;
      justify-content: center;
      line-height: rem(22);
      max-width: max-content;
      width: max-content;
      word-break: break-all;

      a[href^='tel:'] {
        color: $color-scorpion;
        pointer-events: none;
        text-decoration: none;
      }
    }
  }

  @include media('>desktop') {
    &__title {
      margin-left: rem(225);
    }
  }
 }
