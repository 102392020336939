//
// Global variables
// --------------------------------------------------

$prefix: 'stemline-arc';
// .#{$prefix}-m = module
// .#{$prefix}-p = page
// .#{$prefix}-h = helper
// .#{$prefix}-is = state

$assetsMaxWidth: 1240;

// Fonts
$font-raleway: 'Raleway';
$font-inter: 'Inter';
$browser-context: 16;

// Media Queries
$breakpoints: (
  'phone': 375px,
  'tablet': 768px,
  'desktop': 1024px,
  'desktop-lg': 1280px
);

// Colors
// Please use http://chir.ag/projects/name-that-color/#6195ED as reference for the colors name
$color-black: #000;
$color-black-10: rgba(0, 0, 0, .1);
$color-white: #fff;
$color-summer-green: #96bd9f;
$color-cutty-sark: #537175;
$color-saltpan: #f1f6f3;
$color-astral: #3274b5;
$color-yellow-green: #cddb6f;
$color-link-water: #e6f1f8;
$color-pumice: #c6cac6;
$color-scorpion: #606060;
$color-wild-sand: #F5F5F5;
$color-mercury: #E6E6E6;
$color-cutty-sark: #537175;
$color-gallery: #EDEDED;
$color-oxley: #6B9A76;
$color-dark-blue: #204D7A;
