.#{$prefix}-m-simple-text-3 {
  font-family: $font-raleway;

  &__text-content {
    padding-right: rem(12);
    padding-top: rem(36);
  }

  &__headline {
    color: $color-astral;
    font-size: rem(32);
    font-weight: 800;
    margin-bottom: rem(15);
  }

  &__text {
    color: $color-scorpion;
    font-size: rem(20);
    font-weight: 700;
    line-height: rem(26);
    margin-bottom: rem(40);
  }

  &__bottom-text {
    color: $color-scorpion;
    font-family: $font-raleway;
    font-size: rem(16);
    font-weight: 300;
    line-height: rem(22);
    margin-bottom: rem(20);
    margin-top: rem(11);

    strong {
      font-weight: bold;
    }

    a {
      color: $color-scorpion;
    }
  }

  &__item {
    margin-bottom: rem(42);

    &__image {
      margin-bottom: rem(12);
    }

    &__content {
      &__headline {
        color: $color-astral;
        font-family: $font-raleway;
        font-size: rem(20);
        font-weight: 700;
        line-height: rem(26);
        margin-bottom: rem(8);
      }

      &__text {
        color: $color-scorpion;
        font-size: rem(16);
        font-weight: 300;
        line-height: rem(22);
        margin-bottom: rem(20);

        strong {
          font-weight: bold;
        }
      }

      &__text-link {
        color: $color-scorpion;
        font-size: rem(16);
        font-weight: 300;
        line-height: rem(22);

        strong {
          font-weight: bold;
        }

        a {
          color: $color-scorpion;
          text-decoration: underline;
        }
      }
    }
  }

  @include media('>=tablet') {
    &__text-content {
      padding-right: 0;
    }

    &__headline {
      font-size: rem(36);
      margin-bottom: rem(18);
      margin-top: rem(6);
    }

    &__item {
      &__image {
        align-self: flex-start;
        margin-right: rem(15);
      }

      &__content {
        &__headline {
          margin-top: rem(6);
        }
      }
    }

    &__bottom-text {
      margin-bottom: rem(51);
    }
  }
}
