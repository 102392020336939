.#{$prefix}-m-simple-text-1 {
  &__text-content {
    padding-bottom: rem(40);
    padding-top: rem(40);
  }

  &__headline {
    color: $color-astral;
    font-family: $font-raleway;
    font-size: rem(32);
    font-weight: 800;
    margin-bottom: rem(15);
  }

  &__steps {
    list-style: none;
    margin-bottom: rem(80);
    padding-left: 0;

    li {
      align-items: flex-start;
      color: $color-scorpion;
      display: flex;
      font-family: $font-raleway;
      font-size: rem(16);
      font-weight: 300;
      line-height: rem(22);
      margin-bottom: rem(8);

      strong{
        font-weight: bold;
      }

      a {
        color: $color-scorpion;
        text-decoration: none;
        span{
          text-decoration: underline;
        }
      }
    }
  }

  &__box-text {
    background-color: $color-saltpan;
    border-radius: rem(20);
    margin-left: auto;
    max-width: rem(350);
    padding: rem(58) rem(36) rem(24) rem(27);
    position: relative;

    &__icon {
      left: 20px;
      position: absolute;
      top: -40px;
    }

    &__text {
      color: $color-scorpion;
      font-family: $font-raleway;
      font-size: rem(16);
      font-weight: 300;
      line-height: rem(22);
      margin-bottom: 0;

      strong{
        font-weight: bold;
      }
    }
  }

  @include media('>tablet') {
    &__headline {
      font-size: rem(36);
    }

    &__text-content{
      padding-right: rem(12);
    }

    &__steps {
      margin-bottom: 0;

      li{
        align-items: center;
      }
    }
  }
}
