// Typo
// -------------------------

sup {
  top: -0.7em;
  font-size: 0.5em;
}

h1, .h1 {
  font-family: Raleway;
  font-size: rem(40);
  line-height: rem(44);
  letter-spacing: 0;
}

h2, .h2 {
  font-family: Raleway;
  font-size: rem(36);
  line-height: auto;
  letter-spacing: 0;
}

h3, .h3 {
  font-family: Raleway;
  font-size: rem(20);
  line-height: auto;
  letter-spacing: 0;
}

h4, .h4 {
  font-family: Raleway;
  font-size: rem(20);
  line-height: auto;
  letter-spacing: 0;
}

.body-1 {
  font-family: Raleway;
  font-size: rem(20);
  line-height: auto;
  letter-spacing: 0;
}

.body-2 {
  font-family: Raleway;
  font-size: rem(18);
  line-height: auto;
  letter-spacing: 0;
}

.body-3 {
  font-family: Raleway;
  font-size: rem(16);
  line-height: rem(22);
  letter-spacing: 0;
}

.body-4 {
  font-family: Raleway;
  font-size: rem(14);
  line-height: rem(20);
  letter-spacing: 0;
}
.body-5 {
  font-family: Raleway;
  font-size: rem(12);
  line-height: rem(16);
  letter-spacing: 0;
}

.nowrap {
  white-space: nowrap;
}

@include media('>=phone') {
  h1, .h1 {
    font-family: Raleway;
    font-size: rem(56);
    line-height: rem(60);
    letter-spacing: 0;
  }

  h2, .h2 {
    font-family: Raleway;
    font-size: rem(32);
    line-height: auto;
    letter-spacing: 0;
  }

  h3, .h3 {
    font-family: Raleway;
    font-size: rem(36);
    line-height: auto;
    letter-spacing: 0;
  }

  h4, .h4 {
    font-family: Raleway;
    font-size: rem(20);
    line-height: auto;
    letter-spacing: 0;
  }

  .body-1 {
    font-family: Raleway;
    font-size: rem(22);
    line-height: auto;
    letter-spacing: 0;
  }

  .body-2 {
    font-family: Raleway;
    font-size: rem(20);
    line-height: auto;
    letter-spacing: 0;
  }

  .body-3 {
    font-family: Raleway;
    font-size: rem(18);
    line-height: rem(24);
    letter-spacing: 0;
  }

  .body-4 {
    font-family: Raleway;
    font-size: rem(14);
    line-height: rem(20);
    letter-spacing: 0;
  }

  .body-5 {
    font-family: Raleway;
    font-size: rem(12);
    line-height: rem(16);
    letter-spacing: 0;
  }

  
}
