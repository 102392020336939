.#{$prefix}-m-foot-notes {
  margin-bottom: rem(48);

  p {
    color: $color-scorpion;
    font-family: $font-raleway;
    font-size: rem(13);
    font-weight: 400;
    line-height: rem(15);
    margin-bottom: rem(5);

    sup {
      font-size: rem(10);
      line-height: rem(7);
      margin-left: rem(-6);
      vertical-align: bottom;
    }

    span {
      margin-left: rem(-6);
    }

    strong {
      display: contents;
    }

    a {
      color: inherit;
    }
  }

  @include media('>tablet') {
    margin-bottom: rem(80);
  }
}
