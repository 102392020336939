.svg-icon {
  pointer-events: none;
  vertical-align: middle;
}

svg {
  display: block;
  height: 100%;
  width: 100%;

  path {
    fill: currentColor;
  }
}
