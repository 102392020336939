.#{$prefix}-m-product-select {

  &__wrapper {
    &__brand {
      text-align: center;

      &__anchor {
        color: $color-scorpion;
        font-family: Raleway;
        font-size: rem(14);
        font-weight: 500;
        letter-spacing: 0;
        line-height: rem(18);

        &:hover {
          color: inherit;
        }
      }

      &__img {
        width: rem(233);
        object-fit: contain;
      }

      .#{$prefix}-m-button {
        margin: 0 auto;
      }
    }
  }

  @include media('<tablet') {
    padding: 39px 0 26px;

    &__wrapper {
      &__brand {
        margin-bottom: rem(49);

        &__img {
          margin-bottom: 23px;
        }

        .#{$prefix}-m-button__green {
          margin-bottom: rem(8);
        }

        .#{$prefix}-m-button__blue {
          margin-bottom: rem(12);
        }

        .#{$prefix}-m-button__arrow {
          height: rem(15);
          width: rem(9);
        }
      }
    }
  }

  @include media('>phone') {
    padding: 56px 0 26px;

    &__wrapper {
      max-width: rem(760);

      &__brand {
        &:last-child {
          .#{$prefix}-m-product-select__wrapper__brand__img {
            margin-top: rem(-16);
          }
        }

        &__img {
          margin-bottom: rem(24);
        }

        &:first-child {
          .#{$prefix}-m-product-select__wrapper__brand__img {
            margin-bottom: rem(28);
          }
        }

        .#{$prefix}-m-button {
          height: rem(50);
          width: 95%;
        }

        .#{$prefix}-m-button__green {
          margin-bottom: rem(8);
        }

        .#{$prefix}-m-button__blue {
          margin-bottom: rem(12);
        }

        .#{$prefix}-m-button__arrow {
          height: rem(15);
          width: rem(17);
        }
      }
    }
  }

  @include media('>=desktop') {
    .#{$prefix}-m-button {
      width: rem(361);

      &__arrow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
