.#{$prefix}-c-modal {
  .modal-header {
    border-bottom: none;
  }

  &__container {
    border-radius: rem(20);
    display: flex;
    min-height: rem(397);
    min-width: rem(272);
  }

  &__title {
    color: $color-astral;
    display: flex;
    font-family: $font-raleway;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: rem(24);
    font-style: normal;
    font-weight: 800;
    left: rem(2);
    line-height: rem(28);
    margin-top: rem(16);
    position: relative;
  }

  &__btns-content {
    &__first-btn {
      background-color: $color-summer-green;
      border-radius: rem(17) rem(17) rem(21) rem(21) / rem(40) rem(40) rem(46) rem(46);
      color: $color-black;
      font-family: $font-raleway;
      font-size: rem(18);
      font-style: normal;
      font-weight: 700;
      left: rem(18);
      line-height: rem(21);
      margin-bottom: rem(14.91);
      margin-top: rem(-4);
      min-height: rem(90.09);
      min-width: rem(232);
      padding-bottom: rem(10);
      padding-top: rem(12);
      position: relative;
      text-align: left;

      span {
        left: rem(13);
        position: relative;
      }

      img {
        display: flex;
        left: 87%;
        position: absolute;
        top: 40%;
      }
    }

    &__second-btn {
      background-color: $color-white;
      border-color: $color-astral;
      border-radius: 17px 17px 21px 21px / 40px 40px 46px 46px;
      color: $color-astral;
      font-family: $font-raleway;
      font-size: rem(18);
      font-style: normal;
      font-weight: 700;
      left: rem(19);
      line-height: rem(21);
      margin-bottom: rem(38.91);
      min-height: rem(90.09);
      min-width: rem(232);
      padding-bottom: rem(10);
      padding-top: rem(12);;
      position: relative;
      text-align: left;

      span {
        left: rem(11);
        position: relative;
      }

      img {
        display: flex;
        left: 86.4%;
        position: absolute;
        top: 40%;
      }
    }
  }

  @include media('>phone', '<desktop') {
    &__container {
      min-height: rem(357);
      width: 95%;
    }

    &__btns-content {
      display: flex;
      position: relative;

      &__first-btn {
        left: rem(18);
        margin-bottom: rem(14.91);
        margin-top: rem(8);
        min-height: rem(50);
        min-width: rem(320);
        padding-bottom: rem(15);
        padding-top: rem(12);

        span {
          left: rem(10);
          position: relative;
        }

        img {
          display: flex;
          left: 92%;
          position: absolute;
          top: 33%;
        }
      }

      &__second-btn {
        left: rem(19);
        margin-bottom: rem(10);
        min-height: rem(50);
        min-width: rem(320);
        padding-bottom: rem(15);
        padding-top: rem(14);;

        span {
          left: rem(10);
          position: relative;
        }

        img {
          display: flex;
          left: 92%;
          position: absolute;
          top: 33%;
        }
      }
    }
  }

  @include media('>tablet') {

    &__container {
      max-width: rem(730);
      min-height: rem(357);
      min-width: rem(600);
    }

    &__title {
      font-size: rem(36);
      left: 6%;
      line-height: rem(42);
      margin-top: rem(45);
    }

    &__btns-content {
      display: flex;
      left: 4.8%;
      position: relative;

      &__first-btn {
        left: rem(18);
        margin-bottom: rem(14.91);
        margin-top: rem(8);
        max-height: rem(50);
        max-width: rem(445.62);
        min-height: rem(50);
        min-width: rem(443);
        padding-bottom: rem(15);
        padding-top: rem(12);

        span {
          left: rem(10);
          position: relative;
        }

        img {
          display: flex;
          left: 92%;
          position: absolute;
          top: 33%;
        }
      }

      &__second-btn {
        left: rem(19);
        margin-bottom: rem(63);
        min-height: rem(50);
        min-width: rem(443);
        padding-bottom: rem(15);


        span {
          left: rem(10);
          position: relative;
        }

        img {
          display: flex;
          left: 92%;
          position: absolute;
          top: 33%;
        }
      }
    }
  }
}
