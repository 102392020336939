.#{$prefix}-c-isi-body-content {
  color: $color-scorpion;
  font-family: $font-raleway;
  font-size: rem(16);
  font-weight: 400;

  .astral {
    color: $color-astral;
  }

  .sublist {
    padding-left: 5rem;
  }

  .ms-2rem {
    margin-left: 2rem;
  }

  .level2 {
    padding-left: 8rem;
  }

  ul {
    padding-left: rem(24);

    li {
      line-height: rem(24);

      b {
        line-height: rem(24);
      }
    }
  }

  a {
    color: inherit;

    &.phone {
      text-decoration: none;
    }
  }

  @include media('>phone') {
    .footnote {
      font-weight: bold;
      margin: rem(40) 0 rem(44);
    }
  }
}
