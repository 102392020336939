.#{$prefix}-m-questions {
  align-items: center;
  background-image: var(--mob);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  text-align: center;

  &__img {
    background-image: var(--mob);
    background-size: rem(174);
    background-repeat: no-repeat;
    height: rem(184);
    margin: rem(42) auto 0;
    position: relative;
    text-align: center;
    width: rem(200);
  }

  &__content {
    color: $color-scorpion;
    font-family: $font-raleway;
    margin-bottom: rem(37);
    position: relative;
    text-align: left;

    &--title {
      color: $color-astral;
      font-size: rem(36);
      font-weight: 800;
      line-height: rem(42.26);
      margin-bottom: rem(5);
      margin-top: rem(5);
    }

    &--contact-text {
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: rem(16);
      font-weight: 700;
      line-height: rem(22);
      margin-top: rem(-4);
      // max-width: rem(273);

      a[href^='tel:'] {
        color: $color-scorpion;
        text-decoration: none;
      }
    }
  }

  @include media('>phone', '<desktop') {
    padding-bottom: rem(47);

    &__content {
      margin-bottom: rem(5);
      margin-top: rem(110);
      position: relative;

      &--contact-text {
        font-size: rem(16);
        font-weight: 400;
        line-height: rem(22);
        margin-bottom: 0;
        max-width: max-content;
        width: rem(335);
      }
    }

    &__bg {
      display: flex;
      justify-content: center;
    }

    &__img {
      background-image: var(--mob);
      display: flex;
      margin-top: rem(103);
      position: static;
      right: 13%;
    }
  }

  @include media('<tablet') {
    &__content {
      margin-top: rem(6);
    }

    &__img {
      background-position: center;
      margin-top: rem(36);
    }
  }

  @include media('>tablet') {
    align-items: center;
    background-image: var(--desk);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: rem(24);

    &__content {
      left: -3%;
      margin-bottom: rem(5);
      margin-top: rem(131);
      position: relative;

      &--title {
        font-size: rem(42);
        font-style: normal;
        font-weight: 800;
        line-height: rem(49.31);
        margin-bottom: rem(9);
        margin-top: rem(3);
      }

      &--contact-text {
        font-size: rem(16);
        font-weight: 400;
        line-height: rem(22);

        a[href^='tel:'] {
          color: $color-scorpion;
          pointer-events: none;
          text-decoration: none;
        }
      }
    }

    &__img {
      background-image: var(--desk);
      background-size: rem(232);
      display: flex;
      height: rem(270);
      left: 23%;
      margin-top: rem(101);
      width: rem(270);
    }
  }
}
