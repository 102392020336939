.#{$prefix}-m-simple-text-4 {
  &__text-content {
    padding-bottom: rem(40);
    padding-right: rem(12);
    padding-top: rem(30);
  }

  &__headline {
    color: $color-astral;
    font-size: rem(32);
    font-weight: 800;
    margin-bottom: rem(20);
  }

  &__text {
    color: $color-scorpion;
    font-family: $font-raleway;
    font-size: rem(24);
    font-weight: bold;
    margin-bottom: rem(29);
    line-height: rem(33);
  }

  &__steps-container {
    display: flex;

    img {
      align-self: flex-start;
    }
  }

  &__steps-text {
    color: $color-astral;
    font-size: rem(20);
    font-weight: bold;
    margin-bottom: rem(8);
  }

  &__steps {
    padding-left: rem(20);

    li {
      color: $color-scorpion;
      font-family: $font-raleway;
      font-size: rem(16);
      font-weight: 300;
      line-height: rem(22);
      margin-bottom: rem(8);
    }
  }

  &__bottom {
    margin-bottom: rem(70);
  }

  &__bottom-text {
    color: $color-scorpion;
    font-family: $font-raleway;
    font-size: rem(13);
    font-weight: 300;
    margin-bottom: 0;

    sup,
    span {
      margin-left: rem(-3);
    }
  }

  @include media('>tablet') {
    &__headline {
      font-size: rem(36);
      margin-bottom: rem(20);
    }

    &__text {
      font-size: rem(28);
      margin-bottom: rem(20);
    }

    &__steps-container {
      padding-right: rem(33);
    }

    &__steps {
      margin-bottom: 0;
    }

    &__bottom {
      margin-bottom: rem(60);
    }
  }
}
