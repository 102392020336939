.#{$prefix}-m-simple-hero {
  background-color: $color-link-water;

  &__green {
    background-color: $color-saltpan;
  }

  &__headline {
    color: $color-astral;
    font-size: rem(40);
    font-weight: 700;
    line-height: rem(44);
    margin-bottom: rem(20);
  }

  &__text {
    color: $color-scorpion;
    font-family: $font-raleway;
    font-size: rem(18);
    font-weight: 500;
    line-height: rem(24);
  }

  @include media('<tablet') {
    padding: rem(29) 0 rem(41);
  }

  @include media('>=tablet', '<desktop') {
    &__text-container {
      letter-spacing: rem(.1);
      padding-bottom: rem(48);
      padding-top: rem(55);
    }
  }

  @include media('>tablet') {
    &__text-container {
      letter-spacing: rem(.1);
      padding-bottom: rem(48);
      padding-top: rem(55);
    }

    &__headline {
      font-size: rem(56);
      line-height: rem(60);
      margin-bottom: rem(21);
    }

    &__text {
      font-size: rem(20);
      font-weight: 400;
      line-height: rem(26);

    }
  }

  @include media('>desktop') {
    &__text {
      width: rem(1020);
    }
  }
}
