.#{$prefix}-m-images-text {
  &__text-content {
    padding-bottom: rem(40);
    padding-right: rem(12);
    padding-top: rem(40);
  }

  &__headline {
    color: $color-astral;
    font-family: $font-raleway;
    font-size: rem(32);
    font-weight: 800;
    margin-bottom: rem(15);
  }

  &__items {
    &__item {
      img {
        margin-bottom: rem(20);
      }
    }
  }

  &__item {
    &__content {
      &__text {
        color: $color-scorpion;
        font-family: $font-raleway;
        font-size: rem(16);
        font-weight: 700;
        line-height: rem(22);
        margin-bottom: rem(10);
      }

      &__points {
        margin-bottom: rem(30);
        padding-left: rem(20);

        li {
          color: $color-scorpion;
          font-family: $font-raleway;
          font-size: rem(16);
          font-weight: 400;
          line-height: rem(22);
          margin-bottom: rem(8);

          a {
            color: $color-scorpion;
          }
        }
      }
    }
  }

  @include media('>tablet') {
    &__headline {
      font-size: rem(36);
    }

    &__items {
      &__item {
        align-items: center;
        display: flex;
        margin-bottom: rem(20);

        img {
          margin-bottom: 0;
          margin-right: rem(20);
        }
      }
    }
  }
}
