.#{$prefix}-p-hcp-elzonris-assistance-ordering {

  .#{$prefix}-c-content-divider + .#{$prefix}-m-box-content {
    margin-top: rem(41);
  }

  .#{$prefix}-m-foot-notes + .#{$prefix}-m-simple-questions {
    margin-top: rem(70);
  }
}
