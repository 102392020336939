.#{$prefix}-m-box-content {
  font-family: $font-raleway;

  &__header {
    margin-bottom: rem(36);

    h3 {
      color: $color-astral;
      font-weight: 800;

      sup {
        vertical-align: bottom;
      }
    }

    b {
      color: $color-scorpion;
      font-weight: 700;
    }

    &__img {
      max-width: 100%;
    }
  }

  &__body {
    ul {
      list-style: none;

      li {
        align-items: center;
        display: flex;
      }
    }

    &__details {
      margin-bottom: rem(26);
    }

    &__specialty {
      background-color: $color-saltpan;
      border-bottom: rem(1) solid $color-astral;
      color: $color-astral;
      font-size: rem(20);
      font-weight: 700;
      height: rem(47);
      letter-spacing: 0;
      line-height: rem(23);
    }

    &__title {
      color: $color-scorpion;
      font-size: rem(16);
      font-weight: 700;
      letter-spacing: 0;
      line-height: rem(22);
    }

    &__subtitle {
      font-size: rem(16);
      font-weight: 400;
      letter-spacing: 0;
      line-height: rem(22);
    }

    &__contact-info {
      &::before {
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        margin-right: rem(5);
        width: rem(21);
      }

      a {
        color: $color-scorpion;
        word-break: break-word;
      }

      &:not(.#{$prefix}-m-box-content__body__contact-info--laptop):not(.#{$prefix}-m-box-content__body__contact-info--envelope) {
        a {
          text-decoration: none;
        }
      }

      &--phone {
        &::before {
          background-image: url('/images/svg/icon-phone.svg');
          height: rem(16);
        }
      }

      &--printer {
        &::before {
          background-image: url('/images/svg/icon-printer.svg');
          height: rem(14);
        }
      }

      &--envelope {
        &::before {
          background-image: url('/images/svg/icon-envelope.svg');
          height: rem(12);
        }
      }

      &--laptop {
        &::before {
          background-image: url('/images/svg/icon-laptop.svg');
          height: rem(12);
        }
      }
    }
  }

  @include media('<desktop') {
    &--no-img {
      .#{$prefix}-m-box-content {
        &__header {
          b {
            font-size: rem(20);
            line-height: rem(26);
          }
        }
      }
    }

    &__header {
      h3 {
        font-size: rem(32);
        line-height: rem(38);
        margin-bottom: rem(20);

        sup {
          font-size: rem(32);
        }
      }

      b {
        font-size: rem(24);
        line-height: rem(28);
      }

      &__img {
        margin-bottom: rem(18);
      }
    }

    &__body {
      &__contact-list {
        padding: rem(21) 0 rem(12);
      }
    }
  }

  @include media('>phone') {
    &__header {
      h3 {
        font-size: rem(36);
        line-height: rem(42);
        margin-bottom: rem(18);

        sup {
          font-size: rem(40);
        }
      }

      b {
        font-size: rem(20);
        letter-spacing: rem(.3);
        line-height: rem(26);
      }
    }

    &__body {
      &__contact-list {
        padding: rem(21) rem(18) rem(12);
      }

      &__subtitle {
        margin-top: rem(5);
      }
    }
  }

  @include media('<=tablet') {
    &__body {
      &__contact-info {
        span {
          padding-top: 5px;
        }
      }
    }
  }
}
