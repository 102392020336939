.#{$prefix}-c-accordion {
  .accordion-button {
    box-shadow: none;
    color: $color-cutty-sark;
    font-family: $font-inter;
    font-weight: bold;
  }

  @include media('<tablet') {
    .accordion-item {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .accordion-header {
      font-weight: 700;
      letter-spacing: 0;
      margin-bottom: .5rem;
      text-align: left;
    }

    .accordion-body {
      padding: 0;
    }

    .accordion-button {
      font-size: rem(18);

      &.collapsed {
        &::after {
          background-image: url('/images/svg/icon-plus.svg');
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }

      &:not(.collapsed) {
        background-color: transparent;
        color: $color-astral;

        &::after {
          background-image: url('/images/svg/icon-minus.svg');
          background-repeat: no-repeat;
          background-size: rem(24) rem(24);
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

