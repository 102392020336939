.#{$prefix}-m-footer {
  display: grid;
  font-family: $font-raleway;

  &__text {
    color: $color-scorpion;
    font-size: rem(12);
    font-weight: 400;
    line-height: rem(14.09);
    margin-bottom: rem(26);
    text-align: left;
  }

  &__bg-saltpan{
    background: $color-saltpan;
  }

  &__bg-link-water{
    background: $color-link-water;
  }

  &__links {
    text-align: left;

    a {
      color: $color-astral;
    }
  }

  img {
    margin-bottom: rem(36);
    margin-top: rem(48);
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(16.44);
    list-style: none;
    margin-bottom: rem(20);

    li {
      white-space: nowrap;

      a {
        text-decoration: none;
      }

      span {
        color: $color-scorpion;
        margin-left: rem(9);
        margin-right: rem(9);
      }

    }

    :hover{
      a{
        text-decoration: underline;
      }
    }
  }

  @include media('>=tablet') {

    &__text {
      margin-bottom: rem(34);
      text-align: right;
    }

    &__links {
      text-align: right;
    }

    img {
      margin-top: rem(48);

    }

    ul {
      display: block;
      margin-top: rem(48);


      li {
        display: inline;

        a {
          text-decoration: none;
        }

      }
    }
  }
  @include media('>phone', '<desktop') {

    ul {
      display: block;
      margin-top: rem(48);


      li {
        display: inline;

        span {
          margin-left: rem(4);
          margin-right: rem(4);
        }

      }
    }
  }
}
