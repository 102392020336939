.#{$prefix}-m-button {
  align-items: center;
  border-radius: 17px 17px 21px 21px / 40px 40px 46px 46px;
  border: 0;
  display: flex;
  font-family: $font-raleway;
  font-size: rem(18);
  font-weight: 700;
  justify-content: space-between;
  line-height: rem(21);
  min-width: rem(161);
  padding: rem(14) rem(25);
  text-align: left;
  text-decoration: none;
  transition: all 300ms ease-in-out;

  &__green {
    background-color: $color-summer-green;
    color: $color-black;

    &:hover {
      background-color: $color-oxley;
      color: $color-black;
    }

    &:focus {
      border: double 1px $color-summer-green;
    }
  }

  &__blue {
    background-color: $color-astral;
    color: $color-white;

    &:hover {
      background-color: $color-dark-blue;
      color: $color-white;
    }

    &:focus {
      border: double 1px $color-astral;
    }
  }

  &__blue-outline {
    background-color: $color-white;
    border: 1px solid $color-astral;
    color: $color-astral;

    &:hover {
      color: $color-astral;
    }

    &:focus {
      border: solid 2px $color-astral;
    }
  }

  &__arrow {
    height: rem(24);
    margin-left: rem(10);
    object-fit: contain;
    width: rem(24);
  }

  @include media('>tablet') {
    display: block;
    font-size: rem(18);
    font-weight: 700;
    line-height: rem(21);
    padding: rem(13) rem(25);
    position: relative;

    br {
      display: none;
    }
  }
}
