.#{$prefix}-m-isi {
  bottom: 0;
  display: block;
  left: 0;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: fixed;
  right: 0;
  top: auto;
  transition: opacity .5s ease-in-out, max-height .2s ease-in-out;
  width: 100%;
  z-index: 35;

  &--hide {
    opacity: 0;
    pointer-events: none;
  }

  &--expanded {
    max-height: 85%;
    z-index: 1040;

    .accordion-collapse {
      height: auto;
      max-height: 56vh;
      overflow: auto;
    }
  }

  &--blue {
    h4 {
      background-color: $color-astral;
    }

    .#{$prefix}-m-isi__tip {
      background-color: $color-link-water;
    }

    &.#{$prefix}-m-isi--expanded {
      .#{$prefix}-c-isi-body-content {
        height: auto;
        max-height: 76vh;
      }
    }
  }

  &--green {
    h4 {
      background-color: $color-cutty-sark;
    }

    .#{$prefix}-m-isi__tip {
      background-color: $color-summer-green;
    }

    &.#{$prefix}-m-isi--expanded {
      .#{$prefix}-c-isi-body-content {
        height: auto;
        max-height: 76vh;
        overflow: auto;
      }
    }
  }

  &--persistent-bottom {
    max-height: none !important;
    position: static;
  }

  &__wrapper {
    background-color: $color-white;
  }

  &__tip {
    border-radius: rem(4) rem(4) rem(0) rem(0);
    color: $color-black;
    font-family: $font-raleway;
    font-size: rem(18);
    font-weight: 700;
    height: rem(35);
    line-height: rem(24);
    margin: 0 auto;
    padding: rem(8) rem(16);
    text-decoration: none;
    width: rem(155);

    &:hover {
      color: inherit;
    }

    &::after {
      background-image: url('/images/svg/icon-plus-circle.svg');
      background-repeat: no-repeat;
      background-size: rem(22) rem(22);
      color: inherit;
      content: '';
      height: rem(22);
      margin-left: rem(8);
      width: rem(22);
    }

    &--opened {
      &::after {
        background-image: url('/images/svg/icon-minus-circle.svg');
      }
    }
  }

  h4 {
    color: $color-white;
    font-family: $font-inter;
    font-size: rem(16);
    font-style: normal;
    font-weight: 700;
    line-height: rem(19);
    padding: rem(12);
    text-align: center;
  }

  @include media('<desktop') {
    &:not(.#{$prefix}-m-isi--expanded) {
      max-height: rem(217);
    }

    &--blue {
      .#{$prefix}-c-isi-body-content {
        padding: rem(20) 0 rem(50);
      }
    }

    &--green {
      .#{$prefix}-c-isi-body-content {
        padding: rem(20) 0 rem(50);
      }
    }
  }

  @include media('>=desktop') {
    &:not(.#{$prefix}-m-isi--expanded) {
      max-height: rem(289);
    }
  }

  @include media('>phone') {
    &--persistent-bottom {
      .#{$prefix}-c-tab {
        .nav-link {
          font-size: rem(20);
          height: rem(75);

          &:not(.active) {
            box-shadow: inset 0 rem(-11) rem(10) $color-black-10;
          }
        }
      }

      .#{$prefix}-c-isi-body-content {
        padding: rem(50) 0 0;
      }
    }

    &--tray {
      .#{$prefix}-c-isi-body-content {
        padding: rem(19) 0 rem(50);
      }
    }
  }
}
