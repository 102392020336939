.#{$prefix}-m-nav {
  background-color: $color-white;
  height: rem(74);
  position: relative;
  z-index: 1000;

  &__container{
    padding-inline: rem(22);

    >div {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
    }
  }

  &__logo {
    display: block;
    height: rem(41);
    margin: 0;
    padding-inline: 0 rem(10);
    width: rem(110);

    &:first-of-type {
      border-right: 1px solid $color-gallery;
    }

    &:last-of-type {
      padding-inline: rem(10) 0;
    }

    img {
      display: block;
      height: rem(41);
      width: rem(100);
    }
  }

  &__subnav_buttons {
    bottom: rem(165);
    left: 0;
    padding-inline: rem(24);
    position: absolute;
    width: 100%;

    &.patient {
      bottom: rem(125);
    }

    &__button {
      display: inline-block;
      font-family: $font-raleway;
      font-size: rem(14);
      font-weight: 600;
      margin-bottom: rem(10);
      width: 100%;

      &--blue {
        color: $color-astral;
        text-decoration: none;

        &:visited {
          color: $color-astral;
          text-decoration: none;
        }

        &:hover {
          color: $color-astral;
          text-decoration: none;
        }
      }

      &--green {
        color: $color-cutty-sark;
        text-decoration: none;

        &:visited {
          color: $color-cutty-sark;
          text-decoration: none;
        }

        &:hover {
          color: $color-cutty-sark;
          text-decoration: none;
        }
      }
    }

    &__text {
      color: $color-scorpion;
      display: inline-block;
      font-family: $font-raleway;
      font-size: rem(12);
      font-weight: 500;
      line-height: rem(14);
      width: 100%;
    }
  }

  &__subnav_links {
    background-color: $color-mercury;
    bottom: rem(41);
    left: 0;
    padding: rem(17) rem(24);
    position: absolute;
    width: 100%;

    &.patient {
      bottom: 0;
    }

    a {
      color: $color-scorpion;
      display: block;
      font-family: $font-raleway;
      font-size: rem(14);
      font-weight: 500;
      margin-bottom: rem(10);
      position: relative;
      text-decoration: none;
      width: max-content;

      &:visited {
        text-decoration: none;
        color: $color-scorpion
      }
      &:hover {
        text-decoration: none;
        color: $color-scorpion
      }

      &:focus {
        text-decoration: none;
        color: $color-scorpion
      }

      &:last-child {
        font-weight: 700;
        margin-bottom: 0;

        &::after {
          background-image: url('/images/global/svgs/link-square.svg');
          background-position: center;
          background-size: contain;
          content: '';
          display: block;
          height: rem(11);
          position: absolute;
          right: rem(-18);
          top: rem(4);
          width: rem(11);
        }

      }
    }
  }

  .navbar-toggler {
    border: 0;
    box-shadow: inherit;

    &:focus {
      border: 0;
      box-shadow: inherit;
    }
    .icon-bar {
	    transition: all 0.2s;
    	-webkit-transition: all 0.2s;
      background-color: $color-astral;
      border-radius: rem(1);
      display: block;
      height: rem(2);
      margin-top: rem(4);
      width: rem(22);

      &:nth-of-type(1) {
        -ms-transform-origin: 10% 10%;
        -ms-transform: rotate(45deg);
        -webkit-transform-origin: 10% 10%;
        -webkit-transform: rotate(45deg);
        transform-origin: 10% 10%;
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        filter: alpha(opacity=0);
        opacity: 0;
      }

      &:nth-of-type(3) {
        -ms-transform-origin: 10% 90%;
        -ms-transform: rotate(-45deg);
        -webkit-transform-origin: 10% 90%;
        -webkit-transform: rotate(-45deg);
        transform-origin: 10% 90%;
        transform: rotate(-45deg);
      }

    }

    &.collapsed {
      .icon-bar {
        &:nth-of-type(1) {
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }

        &:nth-of-type(2) {
          filter: alpha(opacity=100);
          opacity: 1;
        }

        &:nth-of-type(3) {
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
      }
    }
  }

  .navbar-collapse {
    background-color: $color-wild-sand;
    height: calc(100vh - 74px);
    left: 0;
    opacity: 0;
    position: absolute;
    top: rem(74);
    transition: all 400ms 0ms;
    width: 100vw;

    &>div {
      height: 100%;
      padding: rem(24) rem(24) 0 rem(24);
      position: relative;
      width: 100%;
    }

    &.show {
      opacity: 1;
    }

    .nav-link {
      font-family: $font-raleway;
      font-size: rem(18);
      font-weight: 700;
      height:  max-content;
      padding: 0 0 rem(20) 0;

      &.active-link {
        color: $color-astral;
      }

      &:hover {
        color: $color-astral;
      }
    }
  }

  &--sel_wrap {
    bottom: 0;
    height: rem(40);
    left: 0;
    position: absolute;
    width: 100vw;
  }

  &__sel_btn {
    background-color: $color-white;
    border: 0;
    box-sizing: border-box;
    color: $color-astral;
    font-family: $font-raleway;
    font-size: rem(14);
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    height: rem(37);
    letter-spacing: normal;
    line-height: normal;
    padding: 0 rem(20);
    position: relative;
    text-align: left;
    width: 100%;

    &::after,
    &::before {
      background-color: $color-astral;
      content: "";
      display: block;
      height: rem(15);
      position: absolute;
      right: rem(20);
      top: rem(0);
      transform-origin: bottom left;
      transform: rotate(135deg) translateY(-1px);
      transition: all 400ms 0ms;
      width: rem(2);
    }

    &::after {
      right: rem(21);
      transform-origin: bottom right;
      transform: rotate(-135deg) translateY(-2px);
    }

    &.active {
      &::after,
      &::before {
        top: rem(10);
      }

      &::after {
        transform: rotate(-45deg) translateY(0) ;
      }

      &::before {
        transform: rotate(45deg) translateY(0) ;
      }
    }
  }

  &__sel_lst {
    left: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: all 400ms linear;
    width: 100vw;

    ul{
      background: $color-white;
      border-radius: 0 0 rem(6) rem(6);
      box-sizing: border-box;
      display: block;
      filter: drop-shadow(0 rem(4) rem(7) rgba(0, 0, 0, 0.05));
      height: max-content;
      list-style: none;
      padding: 0;
      width: 100%;

      li {
        a {
          color: $color-scorpion;
          display: block;
          font-family: $font-raleway;
          font-size: rem(14);
          font-weight: 500;
          padding:  rem(7) 0 rem(7) rem(16);
          text-decoration: none;

          &:active, &.active {
            background-color: $color-astral;
            color: $color-white;
            text-decoration: none;
          }

          &:hover {
            background-color: $color-astral;
            color: $color-white;
            text-decoration: none;
          }
        }
      }
    }

    &.open {
      max-height: rem(300);
      opacity: 1;
      top: rem(-72);
    }
  }


  @include media('>=tablet', '<desktop') {
    &__logo {
      width: rem(160);
      img {
        width: rem(160);
      }
    }
  }

  @include media('>=tablet', '<desktop') {
    &__subnav_buttons {
      bottom: rem(180);
    }
  }

  @include media('>tablet') {
    height: rem(113);

    &__container {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    &__logo {
      height: rem(61);
      padding-inline: 0 rem(20);
      width: rem(219);

      &:last-of-type {
        padding-inline: rem(20) 0;
      }

      img {
        display: block;
        height: rem(61);
        width: rem(199);
      }
    }

    .navbar-collapse {
      background-color: transparent;
      height: max-content;
      left: inherit;
      opacity: 1;
      position: relative;
      top: inherit;
      width: max-content;

      &>div {
        padding: rem(65) 0 0 rem(117);
      }
      .navbar-nav {
        align-items: flex-end;
        gap: 18px;
        justify-content: space-between;
        text-align: center;

        &--patient {
          gap: rem(40);
          justify-content: flex-end;
        }
      }

      .nav-link {
        &.active-link{
          position: relative;

          &::before {
            background-color: $color-summer-green;
            border-radius: 50%;
            content: "";
            height: rem(17);
            left: rem(-8);
            opacity: 0.3;
            position: absolute;
            top: rem(2);
            width: rem(17);
            z-index: -1;
          }
        }
      }
    }
  }
}
