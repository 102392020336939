.#{$prefix}-m-simple-layout {
  background-color: $color-saltpan;
  padding: rem(56) 0 rem(50);

  &__title {
    color: $color-astral;
    display: inline-block;
    font-family: $font-raleway;
    font-size: rem(40);
    font-weight: 800;
    line-height: rem(44);
    padding-bottom: rem(27);
    width: 100%;
  }

  &__column {
    h2 {
      color: $color-scorpion;
      font-family: $font-raleway;
      font-size: rem(18);
      font-style: normal;
      font-weight: 700;
      line-height: rem(24);
      margin-bottom: rem(14);
    }

    h2 + ul {
      margin-bottom: -3px;
    }

    ul {
      list-style-type: disc;
      padding-left: 23px;

      li {
        line-height: rem(23);

        &:not(:first-child) {
          margin-left: 25px;
        }
      }

      a {
        font-size: rem(16);
        font-weight: 400;
        line-height: rem(22);
      }
    }

    p {
      color: $color-scorpion;
      font-family: $font-raleway;
      font-size: rem(18);
      font-style: normal;
      font-weight: 500;
      line-height: rem(24);
    }

    a,
    a:visited,
    a:hover {
      color: $color-scorpion;
      font-family: $font-raleway;
    }
  }

  @include media('<tablet') {
    padding: rem(56) 0 rem(66);

    &__column {
      &:first-child {
        margin-bottom: rem(51);
      }
    }
  }

  @include media('>=tablet') {
    padding: rem(56) 0 rem(72);

    &__title {
      font-size: rem(56);
      line-height: rem(60);
      padding-bottom: rem(12);
    }

    &__column {
      h2 {
        font-size: rem(20);
        font-weight: 700;
        line-height: rem(26);
        margin-bottom: rem(11);
      }

      ul {
        a {
          font-size: rem(16);
          line-height: rem(22);
        }
      }

      p {
        font-size: rem(20);
        line-height: rem(26);
      }
    }
  }
}
