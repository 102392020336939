.#{$prefix}-m-simple-text-2 {
  &__text-content {
    padding-right: rem(12);
    padding-top: rem(40);
  }

  &__headline {
    color: $color-astral;
    font-family: $font-raleway;
    font-size: rem(32);
    font-weight: 800;
    margin-bottom: rem(15);
  }

  &__content {
    margin-bottom: rem(40);

    &__headline {
      color: $color-scorpion;
      font-family: $font-raleway;
      font-size: rem(19);
      font-weight: 700;
      line-height: rem(26);
      margin-bottom: rem(8);
    }

    &__points {
      margin-bottom: 0;
      padding-left: rem(20);

      li {
        color: $color-scorpion;
        font-family: $font-raleway;
        font-size: rem(16);
        font-weight: 300;
        line-height: rem(22);
        margin-bottom: rem(8);

        strong {
          font-weight: bold;
        }
      }
    }
  }

  &__resources {
    background-color: $color-saltpan;
    border-radius: rem(20);
    margin-bottom: rem(40);
    margin-left: auto;
    max-width: rem(350);
    padding: rem(24) rem(32) rem(24) rem(27);

    &__headline {
      color: $color-astral;
      font-family: $font-raleway;
      font-size: rem(18);
      font-weight: 700;
      line-height: rem(22);
      margin-bottom: rem(15);
    }

    &__points {
      list-style: none;
      padding-left: 0;

      li {
        margin-bottom: rem(10);

        a {
          color: $color-scorpion;
          display: flex;
          font-family: $font-raleway;
          font-size: rem(14);
          font-weight: 500;
          line-height: rem(18);
          text-decoration: none;

          img {
            margin-right: rem(8);
          }
        }
      }
    }
  }

  &__bottom {
    margin-bottom: rem(45);
  }

  &__bottom-text {
    color: $color-scorpion;
    font-family: $font-raleway;
    font-size: rem(13);
    font-weight: 300;
    margin-bottom: rem(5);

    strong {
      font-weight: bold;
    }
  }

  @include media('>tablet') {
    &__headline {
      font-size: rem(36);
    }

    &__resources {
      margin-bottom: 0;
    }
  }
}
